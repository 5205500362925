import React from "react";
import {
  CheckBox,
  Flex,
  Grid,
  Logo,
  Paper,
  Paragraph,
  Signature,
  Span,
} from "../Pdf.Style";
import numeral from "numeral";
import THBText from "thai-baht-text";
import { format, setMonth } from "date-fns";
import { getBuyType, getCurrentLocalDateTime } from "../../../utils/util.main";

const Header = () => {
  return (
    <React.Fragment>
      <div className="flex justify-end">
        <div>
          <div className=" font-bold text-end -mt-2">มันนี่ ฮับ ลีสซิ่ง</div>
          <div className="font-bold text-end mb-2">MONEY HUB LEASING</div>
        </div>
        <Logo />
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        บริษัท มันนี่ ฮับ เซอร์วิสจำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น 12
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        โทร. 02-8216710 Fax. 02-5139060 E-mail: support@moneyhubservice.com
      </div>
    </React.Fragment>
  );
};

const PdfHirePurchaseContract = ({ data, id, hidden }) => {
  return (
    <React.Fragment>
      <Paper hidden={hidden} id={id} page={1} pageCount={2}>
        <Header />
        <div
          style={{
            fontSize: "9px",
          }}
          className="text-end"
        >
          ทะเบียนเลขที่ 0105563138266
        </div>
        <div className="text-center mt-4 font-bold">
          สัญญาเช่าซื้อ (บุคคลธรรมดา)
        </div>
        <div className="text-end font-bold">สัญญาเลขที่ {data?.request_id}</div>
        <div className="flex justify-between">
          <div>
            สัญญานี้ทำขึ้นเมื่อวันที่ ........................................ ณ สำนักงานของ บริษัท มันนี่ ฮับ เซอร์วิส จำกัดตั้งอยู่ที่
          </div>
        </div>
        <div>
          1010 อาคารชินวัตร 3 ชั้น 12 ถนนวิภาวดีรังสิต แขวงจตุจักร เขตจตุจักร
          กรุงเทพมหานคร 10900
        </div>
        <div>
          ระหว่าง บริษัท มันนี่ ฮับ เซอร์วิส จำกัด ผู้ให้เช่าซื้อ โดย นายชยุตม์
          ปิยะวงศ์ไพบูลย์ ผู้รับมอบอำนาจตามหนังสือมอบอำนาจ ซึ่งต่อไปนี้
        </div>
        <div>
          จะเรียกว่า “บริษัท” ฝ่ายหนึ่ง กับ {data?.article} {data?.fullname} อายุ {data?.age} ปี
          อยู่บ้านเลขที่
        </div>
        <div>
          ซึ่งต่อไปนี้จะเรียก “ผู้เช่าซื้อ” อีกฝ่ายหนึ่ง
          ทั้งสองฝ่ายได้ตกลงทำสัญญากันดังต่อไปนี้
        </div>
        <Paragraph className="text-justify">
          1. บริษัทตกลงให้เช่าซื้อ และผู้เช่าซื้อตกลงเช่าซื้อทรัพย์สิน
          (ซึ่งให้หมายความรวมถึง ส่วนควบ อุปกรณ์ อะไหล่ ส่วนที่นำมาเปลี่ยนหรือ
          ติดตั้งเพิ่มเติม สร้างขึ้นหรือนำมา
          ติดตั้งเข้ากับทรัพย์สินดังกล่าวด้วย) ตามรายละเอียดและราคาทรัพย์สิน
          รวมทั้งวิธีการคำนวณค่าเช่าซื้อและเงื่อนไข
          การชำระค่าเช่าซื้อดังต่อไปนี้ ซึ่งต่อไปจะเรียกทรัพย์สินดังกล่าวว่า{" "}
          <span className="font-bold"> “ทรัพย์ที่เช่าซื้อ” </span>
        </Paragraph>
        <div>
          <div className="text-center font-bold mt-4 mb-2">
            (ก.) รายละเอียดและราคาทรัพย์ที่เช่าซื้อ
          </div>
          <div className="grid grid-cols-4 items-center">
            <Span span={1}> ทรัพย์ที่เช่าซื้อ {getBuyType(data)}</Span>
            <Span span={1}> ยี่ห้อ......................................................................</Span>
            <Span span={1}> แบบ......................................................................</Span>
            <Span span={1} className="">
              รุ่นปี ค.ศ...............................................................
            </Span>
          </div>
          <Grid grid={5}>
            <Span span={1}>สี............................................................</Span>
            <Span span={1}>ทะเบียน.................................................</Span>
            <Span span={3} className="w-full flex">
              หมายเลขตัวรถ/ตัวถัง.......................................................................................................................................................
            </Span>
          </Grid>
          <Grid grid={3}>
            <Span span={1}>หมายเลขเครื่องยนต์......................................................................</Span>
            <Span span={1} className="">
              ระยะทางที่ใช้แล้ว...........................................................................
            </Span>
            <Span span={1} className="flex space-x-1">
              <div>กิโลเมตร สภาพ</div> <CheckBox />
              <div>ใหม่</div> <CheckBox />
              <div>ใช้แล้ว</div>
            </Span>
          </Grid>
          <div className={"flex items-center"}>
            <div>อายุการใช้งาน.........................</div>
            <div>ปี.......................................................</div> 
            <div>เดือน.....................</div>
            <div>วัน</div>{" "}
          </div>
          <Grid grid={3} gap={1}>
            <div className="flex items-center">
              <div className="">ราคาเงินสด...........................................................................</div>
              <div className="">บาท</div>
            </div>
            <div className="flex items-center">
              <div className="ml-1">ภาษีมูลค่าเพิ่ม.....................................................................</div>
              <div className=""> บาท</div>
            </div>
            <div className="flex items-center" between={true}>
              <div className="ml-1">รวม......................................................................................</div>
              <div className=""> บาท</div>
            </div>
          </Grid>
          <div className="flex items-center justify-between" between={true}>
            <div className="">
              ราคาเงินสดส่วนที่เหลือ (ราคาเงินสดหลังหักเงินจองและเงินดาวน์).....................................................................................................................................................................................................
            </div>
            <div className=""> บาท</div>
          </div>
          <Grid grid={3}>
            <div between={true} className="flex items-center col-span-1">
              <div>ภาษีมูลค่าเพิ่ม........................................................................</div>
              <div>บาท</div>
            </div>
            <div className="flex items-center col-span-1 " >
              ......................................................................................................
            </div>
            <div className="flex items-center col-span-1">
              <div className="ml-2">รวม.....................................................................................</div>
              <div> บาท</div>
            </div>
          </Grid>
          <div className="text-center font-bold mt-4 mb-2">
            (ข.) รายละเอียดวิธีการคำนวณค่าเช่าซื้อและเงื่อนไขการชำระค่าเช่าซื้อ
          </div>
          <div>(1.) รายละเอียดวิธีการคำนวณค่าเช่าซื้อ</div>
          <Flex between={true}>
            <div>
              (1.1) เงินลงทุนไม่รวมภาษีมูลค่าเพิ่ม (ราคาเงินสดส่วนที่เหลือ)
              จำนวน
            </div>
            <div> บาท</div>
          </Flex>
          <Flex between={true}>
            <div>(1.2) อัตราดอกเบี้ยที่เช่าซื้อเท่ากับอัตราร้อยละ</div>
            <div> ต่อปี</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.3) อัตราดอกเบี้ยที่แท้จริง (Effective Interest Rate)
              เท่ากับอัตราร้อยละ
            </div>
            <div>ต่อปี</div>
          </Flex>
          <Flex between={true}>
            <div>(1.4) จำนวนงวดที่ผ่อนชำระค่าเช่าซื้อ</div>
            <div> งวด</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.5) รวมจำนวนดอกเบี้ยที่ชำระไม่รวมภาษีมูลค่าเพิ่ม (ข้อ 1.1 x ข้อ
              1.3 x (ข้อ 1.4 / 12)) จำนวน{" "}
            </div>
            <div>บาท</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.6) มูลค่าเช่าซื้อไม่รวมภาษีมูลค่าเพิ่ม (ข้อ 1.1 + ข้อ 1.5)
              จำนวน{" "}
            </div>
            <div> บาท</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.7) จำนวนเงินค่าเช่าซื้องวดละไม่รวมภาษีมูลค่าเพิ่ม (ข้อ 1.6 /
              ข้อ 1.4)
            </div>
            <div> บาท</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.8) ค่าภาษีมูลค่าเพิ่ม ( 7% ) สำหรับค่าเช่าซื้อในแต่ละงวด
            </div>
            <div> บาท</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.9) จำนวนเงินค่าเช่าซื้อรวมภาษีมูลค่าเพิ่มงวดละ (ข้อ 1.7 + ข้อ
              1.8)
            </div>
            <div>บาท</div>
          </Flex>
          <Flex between={true}>
            <div>
              (1.10) จำนวนเงินค่าเช่าซื้อรวมภาษีมูลค่าเพิ่มทั้งสิ้น (ข้อ 1.4 x
              ข้อ 1.9)
            </div>
            <div> บาท</div>
          </Flex>
        </div>
      </Paper>
      <Paper hidden={hidden} id={id} page={2} pageCount={2}>
        <div className="text-start">(2.) เงื่อนไขการชำระค่าเช่าซื้อ</div>
        <Paragraph className="text-justify">
          ผู้เช่าซื้อตกลงชำระเงินตามข้อ 1. (ข.) (1.10) ข้างต้น
          ให้แก่บริษัทเป็นงวดรายเดือน รวมทั้งสิ้น .................................... งวด
          เป็นจำนวนเงินงวดละ
          ................................................... บาท
        </Paragraph>
        <div className="text-start">
          (
          ..............................................................................................
          ) โดยเริ่มชำระงวดแรกในวันที่{" "}
          ........................................................................
        </div>
        <div>
          และชำระงวดต่อๆ ไป ภายในวันที่{" "}
          ................................................. ของทุกเดือน ทั้งนี้
          ผู้เช่าซื้อจะต้องชำระหนี้ทั้งหมดให้เสร็จสิ้นภายในระยะเวลา เดือน ...................................................................
        </div>
        <div>นับแต่วันที่ทำสัญญานี้</div>
        <Paragraph>
          ทั้งนี้ “อัตราดอกเบี้ยที่เช่าซื้อ” หมายถึง
          อัตราดอกเบี้ยที่กำหนดไว้อย่างคงที่โดยไม่มีการเปลี่ยนแปลงไปตามจำนวนเงินลงทุน
          ที่เปลี่ยนแปลงหรือตามเวลาที่ผ่านไป (อัตราดอกเบี้ยคงที่ Flat Interest
          Rate) และ “อัตราดอกเบี้ยที่แท้จริง” (Effective Interest Rate) หมายถึง
          อัตราดอกเบี้ยสำหรับสินเชื่อเช่าซื้อในลักษณะของการคิดดอกเบี้ยแบบลดต้นลดดอก
          เช่นเดียวกับการคำนวณดอกเบี้ยสินเชื่อเพื่อซื้อที่อยู่อาศัย
          โดยคิดดอกเบี้ยจากเงินต้นคงเหลือในแต่ละงวด
        </Paragraph>
        <Paragraph>
          อนึ่ง ผู้เช่าซื้อตกลงยอมรับว่า ในขณะใดขณะหนึ่งก็ตาม
          หากปรากฏข้อเท็จจริงว่า
          อัตราภาษีมูลค่าเพิ่มที่ผู้เช่าซื้อจะต้องเสียให้แก่บริษัท ตามสัญญานี้
          หรือที่จะมีการแก้ไขเพิ่มเติม (ถ้ามี)
          ไม่ว่าจะมีการเปลี่ยนแปลงเพิ่มขึ้นหรือลดลง
          บริษัทมีสิทธิปรับเปลี่ยนอัตราภาษีมูลค่าเพิ่มตามที่มีการแก้ไขเพิ่มเติมนั้นรวมกับค่าเช่าซื้อข้อ
          1.(ข) (1.10) ได้ โดยเพียงแต่บริษัทแจ้งให้ผู้เช่าซื้อทราบ ทั้งนี้
          เพื่อให้การชำระหนี้เสร็จสิ้นภายในระยะเวลาที่ตกลงกันไว้ดังกล่าวข้างต้น
        </Paragraph>
        <Paragraph>
          ข้อ 2. ผู้เช่าซื้อตกลงผูกผันตามข้อกำหนดและเงื่อนไขต่างๆ
          ตามสัญญาเช่าซื้อ สัญญาต่อท้ายสัญญาเช่าซื้อ และเอกสารแนบท้ายสัญญา
          เช่าซื้อทั้งหมดทุกประการ
          และให้ถือว่าเป็นส่วนหนึ่งของสัญญาเช่าซื้อนี้ด้วย
        </Paragraph>
        <Paragraph>
          สัญญานี้ทำขึ้นเป็นสองฉบับ มีข้อความถูกต้องตรงกัน
          คู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อตกลงเงื่อนไขของสัญญานี้โดยตลอดแล้ว
          เห็นว่าตรงกับความประสงค์ จึงได้ลงลายมือชื่อพร้อมประทับตราสำคัญ (ถ้ามี)
          ไว้ต่อหน้าพยาน ณ วัน เดือน ปี ที่กล่าวข้างต้น และยึดถือไว้ฝ่ายละฉบับ
        </Paragraph>
        <Signature
          fixedNameLeft={`${data?.article} ${data?.fullname}`}
          left={`ผู้เช่าซื้อ`}
          right={`พยาน`}
        />
        <Signature left={`คู่สมรส/ผู้ปกครอง`} right={`พยาน`} />

        <div className="text-center pt-12">
          บริษัท มันนี่ ฮับ เซอร์วิส จำกัด “บริษัท”
        </div>
        <div className="text-center ">
          โดย ...................................................
          ผู้มีอำนาจกระทำการแทน
        </div>
        <div className="text-center "> ( นายชยุตม์ ปิยะวงศ์ไพบูลย์ )</div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfHirePurchaseContract;
