import classNames from "classnames";
import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfReceiveCar = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className=""></div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4">
          <div className="font-bold text-lg text-center">ใบรับรถยนต์</div>
        </div>

        {/* Content */}
        <div className="pt-10">
          <div>
            {" "}
            วันที่
            .........................................................................
            สัญญาเช่าซื้อรถยนต์เลขที่ {data?.request_id}
          </div>
          <div>
            {" "}
            ผู้เช่าซื้อในนาม&nbsp;&nbsp;{data?.article} {data?.fullname}{" "}
            สัญญารถยนต์ลงวันที่
            ...........................................................................................................................................................................
          </div>
          <div className="indent-5 text-justify">
            ข้าพเจ้า ผู้เช่าซื้อตามรายนามที่ระบุข้างต้น ขอยืนยันและรับรองว่า
            บริษัท มันนี่ ฮับ เซอร์วิส จำกัด (ผู้ให้เช่าซื้อ)
            ได้ส่งมอบรถยนต์คันที่ได้ระบุไว้ตามสัญญาเช่าซื้อรถยนต์ข้างต้นให้แก่ข้าพเจ้าในฐานะผู้เช่าซื้อ
            ในสภาพเรียบร้อย สมบูรณ์เป็นที่พอใจของข้าพเจ้าแล้วทุกประการ ณ วัน
            เดือน ปี ตามที่ระบุไว้ข้างต้น
          </div>
        </div>

        <div className="pt-20 flex items-center justify-end">
          <div className="flex flex-col items-center">
            <div>
              ลงชื่อ
              .................................................................
              ผู้เช่าซื้อ
            </div>
            <div>( {data?.article}{data?.fullname} )</div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfReceiveCar;
