import { API, Storage } from "aws-amplify";
import classNames from "classnames";
import numeral from "numeral";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonBlue } from "../../../components/button/Button";
import { WhiteCard } from "../../../components/card/CardHome";
import { Container } from "../../../components/layouts/container";
import LoadingPage from "../../../components/loading/loadingPage";
import FunctionExportPdf from "../../../components/pdf/function/FunctionExportPdf";
import PdfAddress from "../../../components/pdf/render/Pdf.Address";
import PdfCheckCondtionCar from "../../../components/pdf/render/Pdf.CheckCondtionCar";
import PdfCondtionAddress from "../../../components/pdf/render/Pdf.ConditionAddress";
import PdfConsiderLoan from "../../../components/pdf/render/Pdf.ConsiderLoan";
import PdfCredit from "../../../components/pdf/render/Pdf.Credit";
import PdfEDD from "../../../components/pdf/render/Pdf.EDD";
import PdfHireEndPurchaseContract from "../../../components/pdf/render/Pdf.HireEndPurchaseContract";
import PdfHirePurchaseContract from "../../../components/pdf/render/Pdf.HirePurchaseContract";
import PdfInstallment from "../../../components/pdf/render/Pdf.Installment";
import PdfInterestRate from "../../../components/pdf/render/Pdf.InterestRate";
import PdfLoanCar from "../../../components/pdf/render/Pdf.LoanCar";
import PdfLoanMotorcycle from "../../../components/pdf/render/Pdf.LoanMotorcycle";
import PdfOpenInfo from "../../../components/pdf/render/Pdf.OpenInfo";
import PdfProcess from "../../../components/pdf/render/Pdf.Process";
import PdfReceiveCar from "../../../components/pdf/render/Pdf.ReceiveCar";
import PdfRequestLoan from "../../../components/pdf/render/Pdf.RequestLoan";
import PdfTransferInsurance from "../../../components/pdf/render/Pdf.TransferInsurance";
import { EmitterStatus } from "../../../event/EmitterStatus";
import useAsync from "../../../hooks/useAsync";
import useQuery from "../../../hooks/useQuery";

import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_DISABLE,
  STYLE_BUTTON_LIGHTBLUE,
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
  STYLE_JOB_CODE,
  STYLE_PARENT_BUTTON_JOB_INFO,
} from "../../../utils/useStyles";
import {
  getPageWithInstallment,
  getStatusStyle,
} from "../../../utils/util.main";

const JobInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.initializeApp);

  const [percent, setPercent] = React.useState(0);

  const { data, dataOther, loading, refetch } = useQuery(
    `/leasing/request/${params?.request_id}`,
    {},
    [params],
    {
      fetchOthers: [
        {
          pathName: `/leasing/interest?name=RETURN_CAR`,
        },
        {
          pathName: `/leasing/interest?name=INTEREST_NTF_PENALTY_MOTORBIKE`,
        },
        {
          pathName: `/leasing/interest?name=INTEREST_NTF_PENALTY_CAR`,
        },
        {
          pathName: `/leasing/interest?name=INTEREST_PENALTY_DATE`,
        },
        {
          pathName: `/leasing/interest?name=ESTIMATE_CAR`,
        },
        {
          pathName: `/leasing/interest?name=STAMP`,
        },
        {
          pathName: `/leasing/interest?name=FEE_MOTORBIKE`,
        },
        {
          pathName: `/leasing/interest?name=DEMAND_FEE_NTF_ONE`,
        },
        {
          pathName: `/leasing/interest?name=DEMAND_FEE_NTF_TWO`,
        },
        {
          pathName: `/leasing/interest?name=VISIT_FEE`,
        },
        {
          pathName: `/leasing/interest?name=BREAK_FEE`,
        },
        {
          pathName: `/leasing/previewLoan/${params?.request_id}`,
        },
      ],
    }
  );

  if (loading) {
    return <LoadingPage />;
  }

  if (!data?.leasing) {
    return <div>Not found</div>;
  }

  const getCanAppoint = () => {
    if (data?.leasing?.appointBy === "-") {
      return true;
    }

    if (["นัดหมายแล้ว", "รอนัดหมาย"].includes(data?.leasing?.loan_status)) {
      return user?.username === data?.leasing?.appointBy;
    }
    return false;
  };

  const getCanWork = () => {
    return ["นัดหมายแล้ว", "รอตรวจ"].includes(data?.leasing?.loan_status);
  };

  const onClickRouteAppoint = () => {
    if (getCanAppoint()) {
      navigate(`/job/${params.request_id}/appoint`);
    }
  };

  const onClickRouteWork = () => {
    if (getCanWork()) {
      navigate(`/job/${params.request_id}/work`);
    }
  };

  return (
    <Container>
      <div className={STYLE_HEADER_PADDING}>
        <div className={STYLE_HEAD_TITLE}> รายละเอียดผู้ยื่นกู้ </div>
        <div className={classNames(STYLE_JOB_CODE, "flex items-center")}>
          {" "}
          <div>Job No.</div>
          <div>{params?.request_id} </div>
          <div className="text-gray-500"> | {data?.leasing?.purpose} | </div>
          <div
            className={classNames(getStatusStyle(data?.leasing?.loan_status))}
          >
            {" "}
            {data?.leasing?.loan_status}{" "}
          </div>
        </div>
      </div>
      <div>
        <WhiteCard refetch={refetch} row={data?.leasing} />
      </div>
      <div className={STYLE_PARENT_BUTTON_JOB_INFO}>
        <Button
          onClick={onClickRouteAppoint}
          text="นัดหมาย"
          className={classNames({
            [STYLE_BUTTON_LIGHTBLUE]: getCanAppoint(),
            [STYLE_BUTTON_DISABLE]: !getCanAppoint(),
          })}
        />
        <Button
          text="ทำงาน"
          onClick={onClickRouteWork}
          className={
            getCanWork() ? STYLE_BUTTON_LIGHTBLUE : STYLE_BUTTON_DISABLE
          }
        />
        {[
          {
            id: "PdfRequestLoan",
            count: 2,
            fileName: `คำขอสินเชื่อ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfCheckCondtionCar",
            count: 2,
            fileName: `ตรวจสภาพรถ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfConsiderLoan",
            count: 2,
            fileName: `พิจารณาสินเชื่อ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfEDD",
            count: 1,
            fileName: `EDD_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfAddress",
            count: 1,
            fileName: `แผนที่อยู่ปัจจุบันและที่ทำงาน_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          data?.leasing?.category === "TF" && {
            id: "PdfReceiveCar",
            count: 1,
            fileName: `ใบรับรถยนต์_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          data?.leasing?.category === "TF" && {
            id: "PdfHirePurchaseContract",
            count: 2,
            fileName: `สัญญาเช่าซื้อ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          data?.leasing?.category === "TF" && {
            id: "PdfHireEndPurchaseContract",
            count: 5,
            fileName: `สัญญาต่อท้ายสัญญาเช่าซื้อ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          data?.leasing?.category === "TF" && {
            id: "PdfTransferInsurance",
            count: 1,
            fileName: `หนังสือรับรองการเช่าซื้อ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          data?.leasing?.category === "TF" && {
            id: "PdfInterestRate",
            count: 1,
            fileName: `อัตราดอกเบี้ย และค่าธรรมเนียมต่างๆ สินเชื่อเช่าซื้อ (รถยนต์)`,
          },
          data?.leasing?.type === "MOTORBIKE" &&
            data.leasing.category === "NTF" && {
              id: "PdfLoanMotorcycle",
              count: 9,
              fileName: `สัญญากู้ยืมเงิน (รถจักรยานยนต์)_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
            },
          ["TRUCK", "CAR"].includes(data?.leasing?.type) &&
            data.leasing.category === "NTF" && {
              id: "PdfLoanCar",
              count: 9,
              fileName: `สัญญากู้ยืมเงิน (${
                data?.leasing?.type === "CAR" ? "รถยนต์" : "รถกระบะ"
              })_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
            },
          {
            id: "PdfProcess",
            count: 1,
            fileName: `หนังสือยินยอมในการประมวลผลข้อมูล_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfCredit",
            count: 1,
            fileName: `หนังสือให้ความยินยอมจำลองเครดิต_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfOpenInfo",
            count: 1,
            fileName: `หนังสือให้ความยินยอมในการเปิดเผยข้อมูล_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfConditionAddress",
            count: 1,
            fileName: `แบบฟอร์มตรวจสอบที่อยู่อาศัย_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
          {
            id: "PdfInstallment",
            count: getPageWithInstallment(dataOther?.installments),
            fileName: `รายละเอียดการผ่อนชำระ_${data?.leasing?.request_id}_${data?.leasing?.fullname}`,
          },
        ]
          .filter((item) => item)
          .map((item, index) => {
            return (
              <Button
                key={index}
                onClick={async () => {
                  await FunctionExportPdf({
                    export_items: [
                      {
                        id: item.id,
                        count: item.count,
                      },
                    ],
                    fileName: item.fileName,
                  });
                }}
                text={item.fileName.split("_")[0]}
                className={classNames(STYLE_BUTTON_BLUE, {
                  "cursor-not-allowed bg-gray-300": false,
                  "bg-green-500 cursor-pointer": true,
                })}
              />
            );
          })}
        <Button
          onClick={async () => {
            const linkS3 = await Storage.get(data?.leasing.pdpaUri)
            const docLink = document.createElement("a");
            docLink.href = linkS3;
            docLink.target = "_blank";
            docLink.click();
          }}
          text={"PDPA"}
          className={classNames(STYLE_BUTTON_BLUE, {
            "cursor-not-allowed bg-gray-300": false,
            "bg-green-500 cursor-pointer": true,
          })}
        />
      </div>

      <PdfInstallment
        hidden
        id="PdfInstallment"
        data={data?.leasing}
        others={dataOther}
      />

      <PdfAddress
        hidden
        id="PdfAddress"
        data={data?.leasing}
        others={dataOther}
      />

      <PdfEDD hidden id="PdfEDD" data={data?.leasing} others={dataOther} />

      <PdfConsiderLoan
        hidden
        id="PdfConsiderLoan"
        data={data?.leasing}
        others={dataOther}
      />

      <PdfCheckCondtionCar
        hidden
        id="PdfCheckCondtionCar"
        data={data?.leasing}
        others={dataOther}
      />

      <PdfRequestLoan
        hidden
        id="PdfRequestLoan"
        data={data?.leasing}
        others={dataOther}
      />

      <PdfHirePurchaseContract
        hidden
        id="PdfHirePurchaseContract"
        data={data?.leasing}
        others={dataOther}
      />
      <PdfLoanMotorcycle
        hidden
        id="PdfLoanMotorcycle"
        data={data?.leasing}
        others={dataOther}
      />
      <PdfLoanCar
        hidden
        id="PdfLoanCar"
        data={data?.leasing}
        others={dataOther}
      />
      <PdfHireEndPurchaseContract
        hidden
        id="PdfHireEndPurchaseContract"
        data={data?.leasing}
        others={dataOther}
      />
      <PdfInterestRate
        hidden
        id="PdfInterestRate"
        data={data?.leasing}
        others={dataOther}
      />
      <PdfProcess
        hidden
        id={"PdfProcess"}
        data={data?.leasing}
        others={dataOther}
      />
      <PdfCredit
        hidden
        id={"PdfCredit"}
        data={data?.leasing}
        others={dataOther}
      />
      <PdfTransferInsurance
        hidden
        id={"PdfTransferInsurance"}
        data={data?.leasing}
        others={dataOther}
      />
      <PdfOpenInfo
        hidden
        id={"PdfOpenInfo"}
        data={data?.leasing}
        others={dataOther}
      />
      <PdfCondtionAddress
        hidden
        id={"PdfConditionAddress"}
        data={data?.leasing}
        others={dataOther}
      />
      <PdfReceiveCar
        hidden
        id={"PdfReceiveCar"}
        data={data?.leasing}
        others={dataOther}
      />
    </Container>
  );
};
export default JobInfo;
