import React from "react";
import { ButtonBlue } from "../../../components/button/Button";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_TITLE_STEPPERS,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import {
  ButtonSubmitController,
  Controllers,
  FunctionGetUrlFromFile,
} from "radium-omnibus";
import UploadImage from "../../../components/uploadImage/uploadImage";
import { onScrollToTop } from "../../../utils/util.main";

function StepOne({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork = false
}) {
  return (
    <div>
      <div className={`${STYLE_WHITE_CARD} my-10  p-10  `}>
        <Controllers
          loadData={form}
          fields={[
            {
              type: "none",
              name: "title",
              label: "รูปถ่าย FCR และลูกค้า",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
            },
            {
              type: "customs",
              name: "fcr_comfirm_customer_imgs",
              classNameCore: `w-1/2`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_comfirm_customer_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              type: "none",
              name: "title",
              label: " รูปถ่ายขณะเซ็นสัญญา",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
              classNameCore: "pt-4",
            },
            {
              type: "customs",
              name: "fcr_comfirm_sign_imgs",
              classNameCore: `w-1/2`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_comfirm_sign_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepOne;
