import { format } from "date-fns";
import React from "react";
import THBText from "thai-baht-text";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";
import { th } from "date-fns/locale";

const PdfProcess = ({ data, id, hidden }) => {
  const getBirthDate = () => {
    const [date, month, year] = data?.birthdateTh?.split("/");
    return `${year}-${month}-${date}`;
  };

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1}>
        <div className="flex items-center justify-end">
          <div>หนังสือให้ความยินยอมฉบับที่ [{data?.contract_id}]</div>
        </div>
        <div className="pt-4 font-bold text-lg text-center">
          <div>หนังสือให้ความยินยอม เก็บรวบรวมใช้เปิดเผยข้อมูลส่วนบุคคล</div>
        </div>
        <div className="flex items-center justify-end">
          <div>ทำที่ .....................................................</div>
        </div>
        <div className="flex items-center justify-end">
          <div>
            วันที่ .....................................................
          </div>
        </div>
        <div className=" indent-4 text-justify">
          ด้วยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ 2562
          เป็นกฎหมายที่มีเจตนารมณ์ปกป้องการล่วงละเมิดและคุ้มครองสิทธิความเป็นส่วนตัวของข้อมูลส่วนบุคคล
          ได้กำหนดหลักเกณฑ์ให้การเก็บรวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคลจะต้องขอความยินยอมพร้อมทั้งแจ้งวัตถุประสงค์ให้เจ้าของข้อมูลส่วนบุคคลทราบโดยชัดแจ้ง
          จะทำเป็นหนังสือหรือทำผ่านระบบอิเล็กทรอนิกส์ก็ได้
        </div>
        <div className="indent-4 text-justify">
          โดยหนังสือฉบับนี้ บริษัท มันนี่ ฮับ เซอร์วิส จำกัด
          ในฐานะผู้ประกอบธุรกิจสินเชื่อส่วนบุคคลภายใต้การกำกับที่มิใช่สถาบันการเงิน
          หรือ
          ผู้ประกอบธุรกิจสินเชื่อรายย่อยเพื่อประกอบอาชีพภายใต้การกำกับที่มิใช่สถาบันการเงิน
          (แล้วแต่กรณี) (ซึ่งต่อไปในหนังสือให้ความยินยอมฉบับนี้ เรียกว่า
          "บริษัทฯ") มีความประสงค์ขอความยินยอมในการเก็บรวบรวม
          ใช้หรือเปิดเผยข้อมูลส่วนบุคคล โดยมีรายละเอียดดังนี้
        </div>
        <div>
          ข้าพเจ้า {data?.article}
          {data?.fullname} วัน/เดือน/ปี พ.ศ. เกิด{" "}
          {format(new Date(getBirthDate()), "dd MMMM yyyy", {
            locale: th,
          })}
        </div>
        <div className="pt-1"></div>
        <div className="border border-gray-700 relative pb-4 px-3">
          <div>
            ข้าพเจ้าขอให้ความยินยอมให้บริษัทฯ และบริษัทในเครือธุรกิจ* เก็บรวบรวม
            ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
            (ซึ่งต่อไปในหนังสือให้ความยินยอมฉบับนี้ เรียกว่า
            “เจ้าของข้อมูลส่วนบุคคล”) เพื่อวัตถุประสงค์ดังต่อไปนี้
          </div>
          <div className="mt-1 flex items-center">
            <div className="">
              ✓ เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลทางชีวภาพ
              ข้อมูลภายในโทรศัพท์เคลื่อนที่ (เช่น ข้อมูลจำลองลายนิ้วมือ
              ข้อมูลจำลองใบหน้า ข้อมูลสมุดโทรศัพท์ เป็นต้น)
              เพื่อวัตถุประสงค์ในการจัดทำรายงานสินทรัพย์รวม
              การดำเนินการใดๆเกี่ยวกับกระบวนการสินเชื่อการวิเคราะห์ข้อมูล
              การบริหารกิจการของบริษัทฯ
            </div>
          </div>
          <div className="mt-1 flex items-center">
            <div className="">
              ✓ เก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลที่ละเอียดอ่อนในเอกสารราชการ (เช่น
              ข้อมูลศาสนาบนบัตรประจำตัวประชาชน เป็นต้น)
              เพื่อตรวจสอบและยืนยันตัวบุคคล
            </div>
          </div>
          <div className="mt-1 flex items-center">
            <div className="">
              ทั้งนี้
              หากเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ข้อมูลส่วนบุคคลหรือไม่ให้ความยินยอมที่บริษัทฯร้องขอข้างต้น
              บริษัทฯ จะไม่สามารถพิจารณาคำขอสินเชื่อได้
            </div>
          </div>
        </div>

        <div className="border border-gray-700 relative pb-4 px-3">
          <div>
            การให้หรือไม่ให้ความยินยอมในการเปิดเผยข้อมูลให้แก้บุคคลอื่นหรือบุคคลภายนอกบริษัทฯตามที่บริษัทฯร้องขอในส่วนนี้
            ไม่มีผลต่อการพิจารณาคำขอใช้สินเชื่อ
          </div>
          <div className="mt-1 flex items-center">
            <div className="">
              ✓ ข้าพเจ้าตกลงและยินยอมให้บริษัทฯ และบริษัทในเครือธุรกิจ*
              เก็บรวบรวม ใช้ และเปิดเผย ข้อมูลส่วนบุคคลข้อมูลสินเชื่อหรือบริการ
              ประวัติ สถานะข้อมูลทางอิเล็กทรอนิกส์
              รวมทั้งข้อมูลส่วนบุคคลอื่นๆของข้าพเจ้าเพื่อวัตถุประสงค์ทางการตลาด
              และ ส่งเสริมการตลาดการเสนอสิทธิประโยชน์
              และการเสนอขายสินค้า/บริการของบริษัทฯ และบริษัทในเครือธุรกิจ เสนอ
              ให้ใช้และ/หรือ ปรับปรุงบริการ หรือ ผลิตภัณฑ์ใดๆ ของ บริษัทฯ
              ของบุคคลที่บริษัทฯเป็นผู้จัดจำหน่าย และ/หรือ
              เป็นตัวแทนหรือมีความเกี่ยวข้องกับบริษัทฯ
            </div>
          </div>
        </div>
        <div className="mt-1">
          โปรดพิจารณารายละเอียดการเก็บ ใช้
          และเปิดเผยข้อมูลส่วนบุคคลของท่านตามที่ปรากฎในนโยบายความเป็นส่วนตัว
          [www.moneyhubservice.com/นโยบายความเป็นส่วนตัว] ที่เกี่ยวข้อง
        </div>
        <div>
          ท่านสามารถปรับเปลี่ยนและถอนความยินยอมของท่านเมื่อใดก็ได้ผ่านทาง
        </div>
        <div className="">
          [ไลน์ไอดี : @Moneyhub/อีเมล : support@moneyhubservice]
        </div>
        <div className="flex items-end justify-end flex-col">
          <div>
            ข้าพเจ้า {data?.article} {data?.fullname}
          </div>
          <div>(ผู้กู้/ผู้รับผลประโยชน์)</div>
          <div>เบอร์โทร {data?.phone_number}</div>
          <div>
            ยืนยันด้วยระบบอิเล็กทรอนิกส์ว่าข้อมูลของข้าพเจ้าถูกต้องและเป็นความจริงทุกประการ
          </div>
          <div>
            วันที่ .....................................................
          </div>
        </div>
        <div>*หมายเหตุ : บริษัทในเครือธุรกิจได้แก่</div>
        <div>
          บริษัท ไทย ฟู้ดส์ เฟรซ มาร์เก็ต จำกัด, บริษัท เจเนพูติก ไบโอจำกัด
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfProcess;
