import { format } from "date-fns";
import React from "react";
import THBText from "thai-baht-text";
import { getAfterFee, getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";

const Header = ({ first, number }) => {
  return (
    <Flex between={true}>
      {first ? (
        <div className="border border-gray-700 w-40 px-2">
          เลขที่สัญญา {number}
        </div>
      ) : (
        <div></div>
      )}
      <Flex between={true}>
        <Grid grid={1}>
          <Span className="text-end font-bold -mt-2" span={1}>
            มันนี่ ฮับ ลีสซิ่ง
          </Span>
          <Span className="font-bold mb-2" span={1}>
            MONEY HUB LEASING
          </Span>
        </Grid>
        <Logo />
      </Flex>
    </Flex>
  );
};

const PdfLoanMotorcycle = ({ data, others, hidden, id }) => {
  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} page={1} pageCount={9} paid={true}>
        <Header first={true} number={data?.request_id} />
        <div className="text-center pt-2 font-bold">
          สัญญากู้ยืมเงิน (สินเชื่อทะเบียนรถจักรยานยนต์)
        </div>
        <div className="text-center pt-2 font-bold">
          มันนี่ ฮับ ลีสซิ่ง (Money Hub Leasing)
        </div>
        <Paragraph>
          ข้าพเจ้าผู้สมัคร{" "}
          <div className="font-bold inline text-justify">“ผู้กู้” </div>
          ที่มีชื่อปรากฏและลงลายมือชื่อในคำขอสินเชื่อมันนี่ฮับลีสซิ่ง
          (ประเภททะเบียนรถจักรยานยนต์) (“คำขอสินเชื่อ”) ตกลงแก่ บริษัท มันนี่
          ฮับ เซอร์วิส จำกัด “ผู้ให้กู้”
          ว่าจะปฏิบัติตามสัญญาฉบับนี้และตารางแสดงภาระหนี้ที่ผู้กู้ได้รับจากผู้ให้กู้ดังนี้
        </Paragraph>
        <Title>ข้อ 1. จำนวนเงินกู้และวิธีขอรับเงินกู้</Title>
        <Paragraph>
          1.1 ผู้กู้ตกลงกู้ยืมเงินตามจำนวนที่ผู้ให้กู้อนุมัติ เป็นจำนวนเงิน
          __________________ บาท ( _____________________________ )
          และแจ้งให้ผู้กู้ทราบ ตามตารางแสดงภาระหนี้สัญญากู้ยืมเงิน
          (สินเชื่อทะเบียนรถ) (“ตารางแสดงภาระหนี้”)
          ตามซึ่งถือเป็นส่วนหนึ่งของสัญญาฉบับนี้
          โดยมีวัตถุประสงค์เพื่อนำไปใช้ในการอุปโภค บริโภคส่วนบุคคล{" "}
        </Paragraph>
        <Paragraph>
          1.2 ผู้กู้จะขอรับเงินกู้ได้ต่อเมื่อส่งมอบเอกสารตามที่ผู้ให้กู้ต้องการ
          และลงนามในเอกสารประกอบการทำสัญญา
          ดังต่อไปนี้ให้แก่ผู้ให้กู้เรียบร้อยแล้ว (ก)
          สมุดคู่มือจดทะเบียนรถตามที่ระบุในคำขอสินเชื่อ
          และดำเนินการลงนามชุดโอนกรรมสิทธิ์รถ ตามแบบของกรมการขนส่งทางบก
          พร้อมส่งมอบเอกสารที่จำเป็นสำหรับการดำเนินการโอนกรรมสิทธิ์รถกับกรมการขนส่งทางบก
          (ข) เอกสารประกอบการทำสัญญากู้ยืมเงินตามที่ผู้ให้กู้กำหนด
        </Paragraph>
        <Paragraph>
          1.3 ผู้ให้กู้จ่ายเงินกู้ให้แก่ผู้กู้ภายใต้เงื่อนไข ดังนี้
        </Paragraph>
        <Paragraph>
          (ก) เมื่อผู้ให้กู้ได้ตรวจสอบหลักฐานและเอกสารต่าง ๆ
          แล้วเห็นว่าถูกต้องครบถ้วนและมีวัตถุประสงค์การขอกู้ยืมเงินตรงตามที่ได้รับอนุมัติ
        </Paragraph>
        <Paragraph>
          (ข)
          ผู้ให้กู้จะจ่ายเงินกู้ให้แก่ผู้กู้ด้วยวิธีการที่ผู้กู้ระบุไว้ในคำขอสินเชื่อ
          คือ โอนเงินเข้าบัญชีเงินฝากของผู้กู้ ตามความประสงค์ไว้กับผู้ให้กู้
          ให้ถือว่าสัญญาฉบับนี้มีผลบังคับใช้และถือว่าผู้กู้ได้รับเงินกู้ตามสัญญาฉบับนี้โดยชอบด้วยกฎหมายแล้ว
          นับแต่วันที่ผู้ให้กู้นำเงินเข้าบัญชีดังกล่าว
          โดยผู้กู้ไม่ต้องลงนามในหลักฐานการรับเงินกู้ใด ๆ
          ให้แก่ผู้ให้กู้อีกและไม่ต้องคำนึงว่าผู้กู้จะได้เบิกถอนเงินกู้นั้นออกไปจากบัญชีธนาคารแล้วหรือไม่
        </Paragraph>
        <Paragraph>
          1.4
          ในกรณีที่ผู้กู้มียอดหนี้คงค้างอยู่กับผู้ให้กู้ตามสัญญาอื่นก่อนทำสัญญานี้
          ซึ่งต่อไปจะเรียกว่า “ยอดปิดปรับหรือรีไฟแนนซ์”
          ผู้กู้ตกลงให้ผู้ให้กู้นำยอดหนี้คงค้างดังกล่าวยกยอดมาเป็นยอดหนี้ตามสัญญานี้
          และให้ถือเสมือนว่าผู้กู้ได้รับเงินกู้จากผู้ให้กู้เท่ากับจำนวนยอดหนี้คงค้างดังกล่างแล้วในวันทำสัญญานี้
          อนึ่งในการลงลายมือชื่อด้านท้ายแห่งสัญญานี้ให้ถือเป็นหลักฐานแสดงว่าผู้กู้ยอมรับว่าผู้กู้มียอดปิดปรับหรือรีไฟแนนซ์อยู่ตามจำนวนที่ระบุในคำขอ
        </Paragraph>
        <Title>ข้อ 2. ดอกเบี้ยและการชำระดอกเบี้ย</Title>
        <Paragraph>
          2.1
          ผู้กู้ตกลงชำระดอกเบี้ยเงินกู้ในอัตราที่ผู้ให้กู้อนุมัติและแจ้งให้ผู้กู้ทราบตามตารางแสดงภาระหนี้โดยคำนวณจากต้นเงินกู้ที่ผู้กู้ได้เบิกรับและยังมิได้ชำระคืนแก่ผู้ให้กู้
          นับแต่วันที่ผู้กู้ได้รับเงินกู้เป็นต้นไป จนถึงวันชำระหนี้เสร็จสิ้น
          มาชำระหนี้แก่ผู้ให้กู้ พร้อมกับการชำระต้นเงินกู้ตามที่ระบุไว้ในข้อ 3.
        </Paragraph>
        <Paragraph>
          ทั้งนี้อัตราดอกเบี้ยในตารางแสดงภาระหนี้จะคำนวณเป็นรายวันตามจำนวนวันที่ผ่านพ้นไปจริง
          โดยกำหนดให้ 1 (หนึ่ง) ปีมี 365 (สามร้อยหกสิบห้า) วัน
        </Paragraph>
        <Paragraph>
          2.2
          ในกรณีที่ผู้กู้ผิดนัดงวดใดงวดหนึ่งหรือผิดสัญญาฉบับนี้ไม่ว่าข้อหนึ่งข้อใด
          ไม่ว่ากรณีใดก็ตามผู้กู้ตกลงให้ผู้ให้กู้คิดอัตราดอกเบี้ยเมื่อรวมกับดอกเบี้ยกรณีผิดนัดชำระหนี้ในอัตราดอกเบี้ยสูงสุดที่เรียกเก็บจริง
          (Effective Interest Rate) ที่ผู้ให้กู้อนุมัติและแจ้งให้ผู้กู้ทราบ
          ไม่เกินร้อยละ 24 (ยี่สิบสี่) ต่อปี
          โดยคำนวณบนฐานเงินต้นของค่างวดที่ค้างชำระในแต่ละงวด
          นับแต่วันที่ผู้กู้ผิดนัดผิดสัญญาเป็นต้นไป
          จนถึงวันที่ศาลมีคำสั่งประทับรับฟ้อง
          และคำนวณบนฐานเงินต้นที่ค้างชำระทั้งจำนวนนับถัดจากวันที่ศาลมีคำสั่งประทับรับฟ้องเป็นต้นไป
          จนกว่าผู้ให้กู้จะได้รับชำระหนี้ตามสัญญาฉบับนี้จนเสร็จสิ้นครบถ้วน
          หรือเรียกเบี้ยปรับในกรณีผิดนัดดังกล่าว ตามอัตราที่
          <div className="underline inline">
            ระบุไว้ในประกาศอัตราค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนด
          </div>
          เพื่อการผิดนัดดังกล่าว
          จนกว่าผู้กู้จะชำระหนี้ให้กับผู้ให้กู้ครบถ้วนทุกจำนวน ทั้งนี้
          ไม่เป็นการตัดสิทธิของผู้ให้กู้ในการเรียกร้องให้ผู้กู้ชำระหนี้ตามสัญญาฉบับนี้ทั้งหมดทันที
          หากผู้กู้มิได้เยียวยาการผิดนัดผิดสัญญาภายในระยะเวลาที่ผู้ให้กู้กำหนดไว้ในหนังสือบอกกล่าวการผิดนัดผิดสัญญา
          ทั้งนี้ เมื่อรวมดอกเบี้ย ค่าบริการ เบี้ยปรับ และค่าธรรมเนียมแล้ว
          จะต้องไม่เกินอัตราดอกเบี้ยสูงสุดที่ผู้ให้กู้และกฎหมายประกาศกำหนดให้ผู้ให้กู้เรียกเก็บได้
          ซึ่งในขณะทำสัญญาฉบับนี้เท่ากับร้อยละ 24 ต่อปี (ตามเอกสารแนบ ก.)
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} page={2} pageCount={9} paid={true}>
        <Header />
        <Paragraph>
          2.3 ผู้กู้ตกลงชำระดอกเบี้ยและค่าธรรมเนียม ในการกู้ยืมเงินตามสัญญานี้
          ให้แก่ผู้ให้กู้ในอัตราที่ผู้ให้กู้กำหนดตามอัตราที่ระบุไว้ในประกาศอัตราดอกเบี้ยและค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนดและเอกสารแนบ
          ก และตามหนังสือแจ้งอนุมัติสินเชื่อ
          โดยในการคิดคำนาณดอกเบี้ยและค่าธรรมเนียมให้คิดคำนวณเป็นรายวันจากยอดเงินกู้คงค้างทั้งหมดแบบลดต้นลดดอก
          นับแต่วันที่ผู้กู้ได้รับเงินกู้เป็นต้นไปจนกว่าจะชำระหนี้เงินกู้เสร็จสิ้น
          และผู้กู้ตกลงให้ถือว่าประกาศอัตราดอกเบี้ยและค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนดและเอกสารแนบ
          ก และหนังสือแจ้งอนุมัติสินเชื่อ
          เป็นส่วนหนึ่งของสัญญาเงินกู้ฉบับนี้ด้วย
        </Paragraph>
        <Paragraph>
          2.4 ผู้กู้ตกลงชำระค่าปรับ ค่าบริการ ค่าธรรมเนียมใดๆ และค่าใช้จ่ายอื่นๆ
          ที่เกิดขึ้นตามสัญญานี้ในอัตราดังที่ปรากฏตามตารางอัตราดอกเบี้ย ค่าปรับ
          ค่าบริการ ค่าธรรมเนียมใดๆ และค่าใช้จ่ายอื่นๆ
          ที่ผู้ให้กู้ส่งมอบให้แก่ผู้กู้
          หรือตามประกาศที่ผู้ให้กู้ได้ติดประกาศไว้ที่สำนักงานหรือสาขาของกลุ่มบริษัทฯในเครือของผู้ให้กู้ทุกแห่ง
          โดยผู้กู้ตกลงยินยอมให้ผู้ให้กู้มีสิทธิเปลี่ยนแปลงอัตราดอกเบี้ย ค่าปรับ
          ค่าบริการ ค่าธรรมเนียมใดๆ และค่าใช้จ่ายอื่นๆ
          เพิ่มขึ้นหรือลดลงได้โดยไม่จำต้องได้รับความยินยอมจากผู้กู้อีกแม้ผู้กู้จะเสียประโยชน์
          ในกรณีที่ผู้ให้กู้จะทำการเปลี่ยนแปลง
        </Paragraph>
        <Paragraph>
          2.5 หากผู้กู้ผิดนัดชำระหนี้งวดหนึ่งงวดใด และผู้ให้กู้ได้บอกเลิกสัญญา
          และเรียกให้ผู้กู้ส่งมอบรถซึ่งนำมาเป็นหลักประกันแก่ผู้ให้กู้ภายในระยะเวลาที่ผู้ให้กู้กำหนดตามระยะเวลาที่ผู้ให้กู้กำหนดตามหนังสือบอกกล่าวแล้ว
          ผู้กู้ไม่ปฏิบัติตามหรือไม่ยินยอมส่งมอบรถซึ่งนำมาเป็นหลักประกัน
          หรือไม่สามารถส่งมอบรถซึ่งนำมาเป็นหลักประกันแก่ผู้ให้กู้ได้
          ผู้กู้ยินยอมชำระค่าใช้ประโยชน์ในรถซึ่งนำมาเป็นหลักประกันแก่ผู้ให้กู้ในอัตราวันละ{" "}
          {others?.RETURN_CAR} บาท
          เป็นต้นไปจนกว่าผู้กู้จะส่งมอบรถซึ่งนำมาเป็นหลักประกันให้แก่ผู้ให้กู้ในสภาพเรียบร้อยใช้การได้ดีตามปกติทั่วไปของรถรุ่นเดียวกันกับรถซึ่งผู้กู้นำมาเป็นหลักประกัน
        </Paragraph>
        <Title>ข้อ 3. การชำระคืนเงินกู้</Title>
        <Paragraph>
          3.1 ผู้กู้ตกลงชำระต้นเงินกู้ที่เบิกรับไปจากผู้ให้กู้
          พร้อมดอกเบี้ยตามข้อ 2.1 ให้แก่ผู้ให้กู้เป็นงวดรายเดือน เดือนละ
          _________ บาท (_____________________) เป็นระยะเวลา _________ เดือน
          ที่ผู้ให้กู้อนุมัติและแจ้งให้ผู้กู้ทราบตามตารางแสดงภาระหนี้
          โดยงวดสุดท้ายผู้กู้จะต้องชำระคืนต้นเงินกู้และดอกเบี้ย
          รวมทั้งค่าธรรมเนียม หรือค่าใช้จ่ายต่าง ๆ
          ที่เกิดขึ้นจริงทั้งหมดให้เสร็จสิ้น
          ในกรณีที่จำนวนเงินที่ผ่อนชำระไม่เพียงพอสำหรับการนำเข้าชำระค่างวดได้ทั้งหมด
          ผู้กู้ตกลงให้นำจำนวนเงินที่ผ่อนชำระเข้าชำระค่าใช้จ่ายต่าง ๆ
          ตามที่เกิดขึ้นจริง ค่าบริการ ค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนด
          และเบี้ยปรับตามสัญญาฉบับนี้ (ตามลำดับ)
          ตามอัตราที่ระบุไว้ในประกาศอัตราค่าธรรมเนียมก่อน
          แล้วจึงหักชำระค่าดอกเบี้ยที่ค้างชำระทั้งหมด
          หากมีเงินคงเหลือเท่าใดจึงนำเข้าชำระต้นเงินกู้
          ผู้ให้กู้ตกลงจะนำเงินที่ได้รับชำระหนี้ไปตัดชำระหนี้ตามยอดหนี้แต่ละงวด
          โดยตัดค่าธรรมเนียม ดอกเบี้ย
          และเงินต้นของยอดหนี้ที่ผู้กู้ค้างชำระนานที่สุดก่อน
          และจึงค่อยตัดชำระยอดหนี้ที่ค้างชำระนานรองลงมาตามลำดับ
          (ตัดชำระตามแนวนอน) โดยจะชำระทุกวันที่ ______ ของทุกเดือน
          เริ่มชำระงวดแรกในวันที่ ______ เดือน ______________ ปี ____________
          เป็นต้นไป
        </Paragraph>
        <Paragraph>
          3.2 ผู้กู้ตกลงชำระหนี้เงินกู้เป็นงวดรายเดือน
          ซึ่งต่อไปในสัญญานี้เรียกว่า “ค่างวด” ตามกำหนดระยะเวลาการผ่อนชำระ
          และรายละเอียดที่ระบุในหนังสืออนุมัติเงินกู้
          ในกรณีที่มีการเพิ่มหรือลดวงเงินกู้
          ผู้กู้ตกลงยินยอมให้มีการเปลี่ยนแปลงค่างวดเพิ่มขึ้นหรือลดลงได้ตามสัดส่วนของวงเงินกู้ที่เพิ่มขึ้นหรือลดลงแล้วนั้นแล้วแต่กรณี
          ซึ่งหากมีการเปลี่ยนแปลงค่างวด
          ผู้ให้กู้จะทำการแจ้งให้ผู้กู้ทราบเป็นลายลักษณ์อักษรล่วงหน้าไม่น้อยกว่า
          30 วัน ก่อนวันที่การเปลี่ยนแปลงดังกล่างจะมีผลบังคับใช้
          หรือในกรณีเร่งด่วนผู้ให้กู้จะแจ้งทาง SMS (Short Message Service)
          ล่วงหน้าไม่น้อยกว่า 7 วัน ไปยังเบอร์โทรศัพท์เคลื่อนที่ที่ผู้กู้แจ้งไว้
          ก่อนวันที่การเปลี่ยนแปลงดังกล่างจะมีผลบังคับใช้
          หรือผู้ให้กู้อาจแจ้งรายละเอียดค่างวดใหม่ที่เปลี่ยนแปลงไปพร้อมกับใบแจ้งหนี้ก็ได้
          โดยผู้กู้ตกลงชำระค่างวดในอัตราใหม่ตามที่ผู้ให้กู้แจ้ง
          และให้ถือว่าเอกสารการแจ้งเปลี่ยนแปลงค่างวดและใบแจ้งหนี้เป็นส่วนหนึ่งของสัญญากู้ยืมเงินฉบับนี้ด้วย
        </Paragraph>
        <Paragraph>
          ทั้งนี้
          จำนวนค่างวดนั้นถือเป็นจำนวนเงินขั้นต่ำที่ผู้กู้ต้องชำระตามกำหนดระยะเวลาการผ่อนชำระ
          ซึ่งหากผู้กู้ชำระเกินจำนวนค่างวดในงวดใดๆ
          จะไม่มีผลเป็นการชำระค่างวดล่วงหน้าในงวดถัดไป อนึ่งค่างวดหรือเงินใดๆ
          ที่ผู้กู้ชำระตามสัญญานี้จะถูกจัดสรรเพื่อชำระหนี้ต่างๆ ตามลำดับดังนี้
          คือ (1) ค่าบริการ (2) ค่าติดตามทวงถาม (3) ค่าปรับ (4) ค่าธรรมเนียมใดๆ
          (5) ดอกเบี้ย และ (6) เงินต้นเป็นลำดับสุดท้าย
        </Paragraph>
        <Paragraph>
          3.3 ในกรณีที่ผู้ให้กู้จัดให้มีบริการหรือช่องทางใดๆ
          ในการทำธุรกรรมรับเงินกู้ โดยไม่จำกัดอยู่เพียงการรับ เบิก ถอน
          โอนเงินกู้ผ่านทางโทรศัพท์หรือระบบอิเล็กทรอนิกส์ใดๆ
          หรือมีคำสั่งผ่านระบบตอบรับอัตโนมัติ หรือพนักงาน/ตัวแทนของผู้ให้กู้
          หากผู้กู้ตกลงใช้บริการหรือช่องทางใดๆดังกล่าว
          ให้ถือว่าการกระทำตามวิธีการ ขั้นตอน
          และเงื่อนไขที่ผู้ให้กู้ได้กำหนดไว้สำหรับในแต่ละบริการหรือช่องทางนั้นๆ
          มีผลผูกพันผู้กู้ทุกประการโดยไม่ต้องมีเอกสารหลักฐานใดๆ
          ให้ผู้กู้ลงนามอีก
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={3} paid={true}>
        <Header />
        <Title>ข้อ 4. หลักประกันและสิทธิหน้าที่ของคู่สัญญา</Title>
        <Paragraph>
          4.1 เพื่อเป็นประกันในการชำระหนี้ตามสัญญาฉบับนี้
          ผู้กู้ได้ตกลงยินยอมมอบสมุดคู่มือรถคัด หมายเลขทะเบียน _____________
          หมายเลขเครื่องยนต์ ____________________ หมายเลขตัวรถ
          ______________________
          ให้บริษัทผู้ให้กู้ยึดถือไว้เป็นประกันการชำระหนี้ตามสัญญาฉบับนี้
          โดยดำเนินการส่งมอบสมุดคู่มือจดทะเบียนรถตามที่ระบุในข้อ 1.2
          และเมื่อผู้ให้กู้จะดำเนินการโอนกรรมสิทธิ์รถที่เป็นหลักประกันเมื่อใดผู้ให้กู้
          หรือตัวแทนผู้ได้รับมอบอำนาจจากผู้ให้กู้จะได้นำสมุดคู่มือจดทะเบียนรถไปจดทะเบียนโอนกรรมสิทธิ์รถต่อกรมการขนส่งทางบกได้ทันที
          เว้นแต่ผู้ให้กู้จะตกลงเป็นอย่างอื่นตามแบบวิธีที่ผู้ให้กู้กำหนด
          ในการดำเนินการจดทะเบียนโอนกรรมสิทธิ์รถซึ่งเป็นหลักประกันดังกล่าวนี้
          หากผู้ให้กู้ประสงค์ให้ผู้กู้จัดการหรือดำเนินการอย่างหนึ่งอย่างใด
          เพื่อให้การจดทะเบียนโอนกรรมสิทธิ์รถเป็นไปโดยสะดวกรวดเร็ว
          ผู้กู้ยินดีที่จะจัดการหรือดำเนินการตามที่ผู้ให้กู้เรียกร้องทันที อนึ่ง
          ผู้กู้จะต้องดำเนินการตามที่ระบุในข้อนี้ให้เรียบร้อยก่อนจึงจะมีสิทธิเบิกรับเงินกู้เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรจากผู้ให้กู้เป็นอย่างอื่น
        </Paragraph>
        <Paragraph>
          4.2 ผู้กู้รับรองว่ารถ
          <div className="underline inline">ที่ระบุในคำขอสินเชื่อ</div>
          เป็นกรรมสิทธิ์ของผู้กู้แต่เพียงผู้เดียว โดยปราศจากภาระติดพัน
          หรือบุริมสิทธิใดๆ หรือมิได้กระทำด้วยประการใดๆ
          ซึ่งมีลักษณะทำนองเดียวกัน อันก่อให้เกิดภาระติดพัน หรือบุริมสิทธิใดๆ
          ขึ้นกับรถดังกล่าว และผู้กู้ตกลงว่า ตลอดระยะเวลานับจากวันทำสัญญาฉบับนี้
          จนกว่าผู้กู้จะชำระหนี้คืนให้แก่ผู้ให้กู้เสร็จสิ้น เมื่อผู้กู้นำรถตาม
          <div className="underline inline">ที่ระบุในคำขอสินเชื่อ</div>
          มาเป็นหลักประกันการกู้ยืมเงินแล้ว ผู้กู้จะดูแลรถอันเป็นหลักประกันเอง
          จะไม่นำรถอันเป็นหลักประกันไปไว้กับบุคคลอื่นที่ไม่ใช่ผู้กู้
          จะไม่โอนกรรมสิทธิ์ในรถดังกล่าวให้กับบุคคลภายนอก หรือก่อภาระติดพัน
          หรือบุริมสิทธิใดๆ และจะไม่กระทำการด้วยประการใดๆ
          ซึ่งมีลักษณะทำนองเดียวกันอันก่อให้เกิดภาระติดพันหรือบุริมสิทธิใดๆ
          อันอาจทำให้ผู้ให้กู้เสียสิทธิตามสัญญาฉบับนี้
          โดยไม่คำนึงว่าจะเป็นเหตุให้มูลค่าของรถดังกล่าวลดน้อยถอยลงหรือไม่
          รวมถึงกระทำหรือไม่กระทำการสิ่งใดซึ่งผู้ให้กู้เห็นว่าอาจเสียหายต่อรถ
          เช่น กระทำการใดๆ จนเป็นเหตุให้รถถูกริบ ยึด อายัด หรือตกเป็นของรัฐ
          ไม่ว่าจะเป็นการกระทำของผู้กู้เองหรือบุคคลอื่นใด หากในเวลาใดๆ
          ได้เกิดกรณีดังกล่าวขึ้น
          และผู้กู้มิได้เยียวยาภายในระยะเวลาที่กำหนดไว้ในหนังสือบอกกล่าวการผิดนัดผิดสัญญา
          ผู้กู้ตกลงให้เงินกู้ตามสัญญาฉบับนี้ถึงกำหนดชำระในทันที
          โดยผู้กู้จะต้องชำระหนี้ที่คงค้างชำระทั้งหมดทุกจำนวนตามสัญญาฉบับนี้พร้อมดอกเบี้ย
          หรือเบี้ยปรับในอัตราผิดนัดชำระหนี้ตาม
          <span className="underline">
            ที่ระบุในตารางอัตราดอกเบี้ยท้ายสัญญาฉบับนี้
            หรือตามที่ระบุไว้ในประกาศอัตราค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนด
          </span>
          นับแต่วันผิดนัดผิดสัญญาเป็นต้นไปจนถึงวันที่ผู้ให้กู้ได้รับชำระหนี้เสร็จสิ้น
          เว้นแต่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากผู้ให้กู้
        </Paragraph>
        <Paragraph>
          กรณีสัญญาฉบับนี้เลิกกัน หากผู้กู้ตกลงส่งมอบ หรือตกลงให้ผู้ให้กู้
          หรือตัวแทนของผู้ให้กู้สามารถเข้าครอบครองและดำเนินการให้รถตามที่ระบุในคำขอสินเชื่อตกเป็นกรรมสิทธิ์ของผู้ให้กู้รถดังกล่าวจะต้องอยู่ในสภาพเรียบร้อยและใช้การได้ดี
          เพื่อให้ผู้ให้กู้สามารถประเมินราคาท้องตลาดรถเพื่อเป็นเกณฑ์ในการตีราคาชำระหนี้
          หรือสามารถนำรถออกขายตามวิธีการที่ผู้ให้กู้เห็นสมควรได้ทันที
          ทั้งยินยอมให้ผู้ให้กู้สามารถดำเนินการนำสมุดคู่มือจดทะเบียนรถและเอกสารโอนกรรมสิทธิ์รถตามแบบของกรมการขนส่งทางบกที่ให้ไว้เป็นหลักประกันตามสัญญาฉบับนี้
          ไปดำเนินการจดทะเบียนโอนกรรมสิทธิ์รถกับกรมการขนส่งทางบกได้
          โดยผู้กู้จะไม่โต้แย้งใด ๆ ทั้งสิ้น ทั้งนี้
          การใช้สิทธิของผู้ให้กู้ตามที่กำหนดไว้ข้างต้นไม่เป็นการตัดสิทธิผู้ให้กู้ในอันที่จะเรียกร้องให้ผู้กู้ชำระหนี้ค้างชำระ
          ตลอดจนค่าภาระติดพัน และค่าเสียหายอื่น ๆ (ตามที่เกิดขึ้นจริง)
          หรือประโยชน์อื่นใดอันพึงได้ของผู้ให้กู้
        </Paragraph>
        <Paragraph>
          ในการที่ผู้ให้กู้จะเข้าครอบครองและดำเนินการให้รถตกเป็นกรรมสิทธิ์ของผู้ให้กู้ดังกล่าว
          ผู้กู้ยินยอมให้ผู้ให้กู้ หรือตัวแทนของผู้ให้กู้เข้าไปในสถานที่
          หรือสำนักงานในความครอบครองของผู้กู้เพื่อรับมอบ หรือ
          ยึดถือครอบครองรถดังกล่าวโดยไม่มีข้อโต้แย้งหรือเรียกร้องและฟ้องร้องใด ๆ
          ทั้งสิ้นไม่ว่าทั้งทางแพ่งและทางอาญา
        </Paragraph>
        <Paragraph>
          4.3 ในกรณีที่ผู้ให้กู้จะเข้าครอบครอง และดำเนินการให้กรรมสิทธิ์ในรถ
          เป็นของผู้ให้กู้เมื่อผู้กู้ผิดนัดผิดสัญญาฉบับนี้
          ผู้กู้ตกลงให้ผู้ให้กู้แต่งตั้งบุคคลภายนอกตามที่ผู้ให้กู้เห็นสมควร
          เพื่อทำหน้าที่ประเมินราคาท้องตลาดของรถดังกล่าวนี้เพื่อเป็นเกณฑ์ในการตีราคาชำระหนี้
        </Paragraph>
        <Paragraph>
          เมื่อประเมินราคาได้เป็นจำนวนเงินเท่าใด
          ให้ผู้ให้กู้หักค่าใช้จ่ายในการโอน หรือค่าภาษีอากรต่างๆ
          หรือค่าใช้จ่ายใดๆ ตามอัตรา
          <span className="underline">
            ที่ระบุไว้ในประกาศอัตราค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนด
          </span>
          ออกเสียก่อน
          หากราคาของรถดังกล่าวที่ยังคงเหลือเป็นเงินจำนวนน้อยกว่าจำนวนหนี้ที่ผู้กู้ยังค้างชำระกับผู้ให้กู้
          ให้ถือว่าหนี้ที่ผู้กู้มีอยู่กับผู้ให้กู้ระงับไปเพียงเท่าที่ได้ตีราคา
          (หลังหักค่าใช้จ่ายเกี่ยวกับการโอน ค่าอากรแสตมป์ ค่าภาษีรถประจำปี
          หรือค่าใช้จ่ายใดๆ ) ดังกล่าวนั้น
          และผู้กู้มีหน้าที่ต้องชำระเงินกู้ส่วนที่เหลือให้แก่ผู้ให้กู้จนครบถ้วน
          แต่ถ้าราคาประเมินดังกล่าวคงเหลือเป็นเงินจำนวนมากกว่าจำนวนหนี้ที่ผู้กู้เป็นหนี้อยู่เท่าใด
          ผู้ให้กู้จะคืนเงินส่วนที่เกินนั้นให้แก่ผู้กู้ต่อไป
        </Paragraph>
        <Paragraph>
          4.4 ตลอดระยะเวลานับจากวันทำสัญญาฉบับนี้
          ผู้กู้ขอให้สัญญากับผู้ให้กู้ว่า
          ผู้กู้จะไม่โอนกรรมสิทธิ์ในรถดังกล่าวให้กับบุคคลภายนอก
          หรือก่อภาระผูกพันใด ๆ อันอาจทำให้ผู้ให้กู้เสียสิทธิตามสัญญาฉบับนี้
          และหากผู้ให้กู้ถูกบุคคลภายนอกรอนสิทธิ
          หรือกระทำการอื่นใดทำนองเดียวกันอันเป็นการรบกวน ขัดสิทธิของผู้ให้กู้
          ผู้กู้ยินยอมรับผิดชอบและชดใช้ค่าเสียหายสำหรับกรณีดังกล่าวให้แก่ผู้ให้กู้โดยสิ้นเชิง
        </Paragraph>
        <Paragraph>
          4.5 บรรดาค่าธรรมเนียม หรือค่าใช้จ่ายใด ๆ ในการดำเนินการโอนกรรมสิทธิ์รถ
          ผู้กู้ตกลงเป็นผู้ออกทั้งสิ้น
          และหากผู้ให้กู้ได้ออกชำระไปแล้วเป็นจำนวนเท่าใด
          ผู้กู้ยินยอมชดใช้คืนให้แก่ผู้ให้กู้จนครบถ้วน
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={4} paid={true}>
        <Header />
        <Paragraph>
          4.6 ผู้กู้ให้ถือว่าข้อตกลงที่ให้ไว้ในสัญญาฉบับนี้
          เป็นหนังสือมอบอำนาจที่ผู้กู้แต่งตั้งให้ผู้ให้กู้
          หรือผู้ที่ได้รับมอบหมายจากผู้ให้กู้เป็นผู้รับมอบอำนาจของผู้กู้ในการติดต่อทำเอกสารสัญญา
          รวมทั้งทำการอื่นใดเกี่ยวกับการดำเนินการโอนกรรมสิทธิ์รถดังกล่าวตามข้อตกลงในข้อ
          4. นี้แทนผู้กู้ทุกประการ
        </Paragraph>
        <Title>ข้อ 5. ค่าธรรมเนียม</Title>
        <Paragraph>
          บรรดาค่าภาษี ค่าอากร ค่าธรรมเนียม และค่าใช้จ่ายต่างๆ
          อื่นใดอันเนื่องมาจากหรือที่ระบุว่าต้องชำระตามสัญญาฉบับนี้
          ให้แก่ผู้ให้กู้ตามอัตรา
          <span className="underline">
            {" "}
            ที่ระบุไว้ในประกาศอัตราค่าธรรมเนียมที่ผู้ให้กู้ประกาศกำหนด{" "}
          </span>{" "}
          ผู้กู้ตกลงเป็นผู้รับผิดชอบแต่เพียงฝ่ายเดียว
          และในกรณีที่ผู้ให้กู้ได้ชำระแทนผู้กู้ไป
          ผู้กู้ตกลงรับผิดชอบชำระคืนแก่ผู้ให้กู้ตามค่าใช้จ่ายที่เกิดขึ้นจริงโดยพลันจนครบถ้วน
        </Paragraph>
        <Title>ข้อ 6. เหตุผิดนัด</Title>
        <Paragraph>
          หากเกิดเหตุการณ์ใดเหตุการณ์หนึ่ง ดังจะกล่าวต่อไปนี้
          ผู้กู้ยินยอมให้ผู้ให้กู้มีสิทธิที่จะถือว่าเป็นกรณีที่ผู้กู้ผิดนัดชำระหนี้
          และให้ถือว่าภาระหนี้ทั้งหมดที่เกิดขึ้นตามสัญญาฉบับนี้ถึงกำหนดชำระทันที
          ทั้งนี้ ผู้ให้กู้สามารถระงับการเบิกจ่ายเงินกู้ตามสัญญาฉบับนี้
          ตลอดจนใช้สิทธิบอกเลิกสัญญา
          หรือเรียกให้ชำระหนี้ทั้งหมดตามสัญญาฉบับนี้ได้ทันที อาทิ
        </Paragraph>
        <Paragraph>
          6.1 ผู้กู้ผิดนัดหนี้ค่างวด
          หรือไม่ปฏิบัติตามสัญญาฉบับนี้ไม่ว่าข้อหนึ่งข้อใด
          ซึ่งผู้ให้กู้จะมีหนังสือแจ้งเหตุแห่งการผิดนัดผิดสัญญาให้ผู้กู้ทราบภายในระยะเวลาที่กฎหมายกำหนด
          และในกรณีที่ผู้ให้กู้พิจารณาแล้วเห็นว่าเหตุดังกล่าวเป็นเหตุอันอาจแก้ไขได้
          ผู้ให้กู้อาจกำหนดระยะเวลาตามสมควรให้ผู้กู้แก้ไขเหตุดังกล่าว
          แต่หากผู้กู้มิได้ชำระหนี้หรือเยียวยาความเสียหายภายในระยะเวลาที่ผู้ให้กู้กำหนดไว้ในหนังสือบอกกล่าวการผิดนัดผิดสัญญา
          ให้ถือว่าผู้กู้ผิดนัดผิดสัญญาในจำนวนหนี้ทั้งหมดตามสัญญาฉบับนี้
          และบรรดาหนี้เงินกู้ทั้งหมดตามสัญญาฉบับนี้เป็นอันถึงกำหนดชำระทันที
          โดยผู้กู้ตกลงให้ผู้ให้กู้มีสิทธิระงับการเบิกจ่ายเงินกู้บางส่วนหรือทั้งหมด
          ตลอดจนใช้สิทธิบอกเลิกสัญญา
          หรือเรียกให้ชำระหนี้ทั้งหมดตามสัญญาฉบับนี้ได้ทันที
          พร้อมดอกเบี้ยในอัตราผิดนัดชำระหนี้
          นับแต่วันผิดนัดผิดสัญญาเป็นต้นไปจนถึงวันที่ผู้ให้กู้จะได้รับชำระหนี้เสร็จสิ้น
        </Paragraph>
        <Paragraph>
          6.2 คำรับรองหรือคำยืนยันใด ๆ ที่ผู้กู้ให้ไว้ตามสัญญาฉบับนี้
          เป็นคำรับรองหรือคำยืนยันที่ไม่เป็นความจริง
          หรือพิสูจน์ได้ว่าไม่เป็นความจริง ไม่ถูกต้อง
          หรืออาจจะก่อให้เกิดความเข้าใจผิดในสาระสำคัญ
          และหากผู้ให้กู้ได้รับรู้ข้อเท็จจริงดังกล่าวแล้วผู้ให้กู้จะไม่เข้าทำสัญญาฉบับนี้กับผู้กู้
        </Paragraph>
        <Paragraph>
          6.3 ผู้กู้เข้าทำความตกลงใด ๆ กับเจ้าหนี้
          หรือเป็นบุคคลผู้มีหนี้สินล้นพ้นตัว
          หรือถูกพิทักษ์ทรัพย์หรือถูกยึดอายัดทรัพย์
          หรือทำการโอนสิทธิเพื่อประโยชน์ของเจ้าหนี้ของตน
          หรือถ้ามีการดำเนินการคดีใด ๆ หรือมีการขอให้ออกคำสั่งอย่างใด ๆ
          เพื่อการล้มละลาย
        </Paragraph>
        <Paragraph>
          6.4 ในกรณีที่ผู้กู้ผิดนัดชำระหนี้ตามสัญญานี้และหากผู้ให้กู้เห็นสมควร
          โดยคำนึงถึงความสามารถในการชำระหนี้ของผู้กู้เป็นสำคัญ
          ผู้ให้กู้มีสิทธิเรียกให้ผู้กู้ชำระหนี้ค่างวดเฉพาะงวดที่ผิดนัดพร้อมค่าปรับ
          และค่าใช้จ่ายที่เกิดขึ้นตามสัญญานี้ก็ได้ ทั้งนี้อัตราค่าปรับ
          และค่าใช้จ่ายที่เกิดขึ้นตามสัญญาปรากฏตามตารางอัตราดอกเบี้ย ค่าปรับ
          ค่าบริการ ค่าธรรมเนียมใดๆ
          และค่าใช้จ่ายอื่นๆที่ผู้ให้กู้ส่งมอบให้แก่ผู้กู้
          หรือตามประกาศที่ผู้ให้กู้ติดประกาศไว้ที่สำนักงานหรือสาขาในเครือบริษัทฯ
          ของผู้ให้กู้ทุกแห่ง
          โดยในการคิดค่าปรับผู้กู้ตกลงให้ผู้ให้กู้ตกลงให้ผู้กู้มีสิทธิคิดนับแต่วันที่ผิดนัดเป็นต้นไปจนกว่าจะชำระหนี้คงค้างครบถ้วน
        </Paragraph>
        <Paragraph>
          6.5
          หากผู้กู้ไม่ทำการชำระหนี้หรือปฏิบัติตามหนังสือบอกกล่าวทวงถามซึ่งผู้ให้กู้ได้บอกกล่าวไปยังผู้กู้
          ผู้กู้ตกลงจะส่งมอบรถหลักประกันกับผู้ให้กู้
          หรือยินยอมให้ผู้ให้กู้เข้าครอบครองรถ
          เพื่อให้ผู้ให้กู้นำรถมาประเมินราคาเพื่อหักชำระหนี้คงค้างตามสัญญา
          โดยผู้กู้ยินยอมให้ผู้ให้กู้หรือตัวแทนของผู้ให้กู้มีสิทธิเข้าไปในสถานที่ใดๆ
          ซึ่งผู้กู้เป็นเจ้าของหรือมีสิทธิครอบครองซึ่งเป็นสถานที่ที่รถหลักประกันจอดอยู่ได้
          และผู้กู้ตกลงยินยอมให้ผู้ให้กู้มีสิทธินำรถหลักประกันไปขาย/จำหน่ายได้
          ทั้งนี้ผู้กู้ยินยอมให้ผู้ให้กู้มีสิทธิดำเนินการใดๆ
          ที่เกี่ยวข้องในการโอนกรรมสิทธิ์รถหลักประกันให้แก่ผู้ให้กู้
          หรือบุคคลใดๆ ที่ผู้ให้กู้กำหนดได้
        </Paragraph>
        <Paragraph>
          6.6 ในกรณีที่ผู้กู้ผิดนัดหลายครั้ง หากผู้ให้กู้ยอมผ่อนผันต่อการผิดนัด
          หรือผิดสัญญาครั้งใด มิให้ถือว่าเป็นการผ่อนผันการผิดนัด
          หรือผิดสัญญาครั้งอื่น ทั้งนี้ การที่ผู้ให้กู้ไม่ใช้
          หรือล่าช้าในการใช้สิทธิใดๆ ตามสัญญานี้ ไม่ถือเป็นการสละสิทธิ์ดังกล่าว
        </Paragraph>
        <Paragraph>
          6.7 ในกรณีที่มีการติดตามทวงถามให้ผู้กู้ชำระหนี้ที่ค้างชำระ
          ผู้กู้ตกลงชำระค่าใช้จ่ายในการติดตามทวงถามหนี้ให้แก่ผู้ให้กู้ในอัตราดังที่ปรากฏตามตารางอัตราดอกเบี้ย
          ค่าปรับ ค่าบริการ ค่าธรรมเนียมใดๆ และค่าใช้จ่ายอื่นๆ
          ที่ผู้ให้กู้ส่งมอบให้แก่ผู้กู้
          หรือตามประกาศที่ผู้ให้กู้ได้ติดประกาศไว้ที่สำนักงานสาขาในเครือของบริษัทฯทุกแห่ง
          โดยผู้กู้ตกลงยินยอมให้ผู้กู้มีสิทธิเปลี่ยนแปลงอัตราค่าใช้จ่ายในการติดตามทวงถามหนี้เพิ่มขึ้นหรือลดลงได้โดยไม่จำต้องได้รับความยินยอมจากผู้กู้อีกแม้ผู้กู้จะเสียประโยชน์
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={5} paid={true}>
        <Header />
        <Title>ข้อ 7. การเปลี่ยนแปลงเงื่อนไขสัญญาฉบับนี้</Title>
        <Paragraph>
          7.1
          หากการเปลี่ยนแปลงเงื่อนไขที่ส่งผลให้ผู้กู้เกิดภาระหรือความเสี่ยงที่เพิ่มขึ้นจะมีผลเมื่อผู้กู้ให้ความยินยอม
        </Paragraph>
        <Paragraph>
          7.2 หากเป็นการเปลี่ยนแปลงอื่น นอกจากข้อ 7.1 หรือการเปลี่ยนแปลง
          อัตราดอกเบี้ยอัตราค่าปรับอัตราค่าธรรมเนียมอัตราค่าบริการ
          และค่าใช้จ่ายต่าง ๆ อันเนื่องมาจากต้นทุนที่เพิ่มขึ้น
          ผู้กู้ตกลงยินยอมให้ผู้ให้กู้มีสิทธิเปลี่ยนแปลงได้ตามที่ผู้ให้กู้เห็นสมควรโดยทำการแจ้งข้อมูลอันเป็นสาระสำคัญของการเปลี่ยนแปลงที่ชัดเจนให้ผู้กู้ทราบเป็นลายลักษณ์อักษรล่วงหน้า
          (ด้วยขนาดของตัวอักษรไม่เล็กกว่า 2 (สอง) มิลลิเมตร)
        </Paragraph>
        <div className="indent-16">(1) ไม่น้อยกว่า 30 (สามสิบ) วัน หรือ</div>
        <div className="indent-16">
          (2) ในกรณีเร่งด่วนไม่น้อยกว่า 7 (เจ็ด) วัน โดย{" "}
        </div>
        <div className="indent-24">(ก) ทางจดหมาย</div>
        <div className="indent-24">
          (ข)
          ประกาศทางหนังสือพิมพ์รายวันภาษาไทยที่แพร่หลายในประเทศและแจ้งเป็นหนังสือซ้ำอีกครั้ง
        </div>
        <Paragraph>
          7.3 บรรดาเอกสารใดๆ
          ที่ผู้ให้กู้จัดทำขึ้นเพื่อการทำสัญญาหรือหักค่าใช้จ่ายที่เกี่ยวข้อง
          และการโอนเงินกู้จากวงเงินกู้ยืมตามสัญญาให้แก่ผู้กู้
          หรือเพื่อแจ้งยอดบัญชี ให้ถือเป็นหลักฐานตามสัญญานี้
          และให้ถือว่าเอกสารต่างๆ ดังกล่าวรวมทั้งข้อกำหนดและเงื่อนไขต่างๆ
          ที่เกี่ยวข้องกับการทำสัญญานี้ ทั้งที่มีอยู่แล้ว ณ วันทำสัญญา
          และที่จะมีขึ้นต่อไปภายหน้า เป็นส่วนหนึ่งแห่งสัญญานี้ด้วย
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 8. </span>
          ผู้กู้ตกลงและยอมรับว่าเงินจำนวนใด ๆ
          ที่ผู้กู้ต้องชำระให้แก่ผู้ให้กู้ตามสัญญาฉบับนี้
          จะต้องชำระให้แก่ผู้ให้กู้เป็นเงินในลักษณะที่ผู้ให้กู้อาจนำไปใช้ประโยชน์ได้ทันที
          ไม่เกินกว่าเวลาทำการของผู้ให้กู้ และผู้กู้ตกลงว่าการกล่าวอ้างอิงถึง
          “วันทำการ” ในสัญญาฉบับนี้ ให้หมายถึงวันเปิดทำการของผู้ให้กู้ ที่มิใช่
          วันเสาร์ อาทิตย์
          หรือวันหยุดทำการของธนาคารพาณิชย์ตามที่ธนาคารแห่งประเทศไทยประกาศกำหนด
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 9. </span> บรรดาหนังสือ จดหมายติดต่อ
          คำบอกกล่าวใด ๆ ของผู้ให้กู้ที่ได้ส่งถึงผู้กู้
          หากคู่สัญญาได้ส่งทางโทรสารหรือทางจดหมายอิเล็กทรอนิกส์ หรือทางไปรษณีย์
          หรือส่งทางไปรษณีย์ลงทะเบียนตอบรับ ตามที่อยู่ที่ระบุไว้ในสัญญาฉบับนี้
          หรือที่อยู่ที่ได้แจ้งการเปลี่ยนแปลงไว้เป็นหนังสือหลังสุด
          ให้ถือว่าส่งถึงกันได้โดยชอบแล้ว ทั้งนี้
          คู่สัญญาตกลงให้การส่งจดหมายอิเล็กทรอนิกส์มีผลผูกพันบังคับใช้ตามพระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์
          พ.ศ. 2544
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 10. </span>{" "}
          ถ้าข้อความในสัญญาฉบับนี้กลายเป็นโมฆะ ไม่ชอบด้วยกฎหมาย ไม่สมบูรณ์
          หรือใช้บังคับมิได้ในประการใด ๆ ตามกฎหมาย ให้ข้อความอื่น ๆ
          ที่เหลืออยู่ในสัญญาฉบับนี้ยังคงใช้บังคับได้ตามกฎหมาย
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 11. </span> การล่าช้า หรืองดเว้นใด ๆ
          ในการใช้สิทธิของผู้ให้กู้ตามกฎหมาย หรือตามสัญญาฉบับนี้
          ไม่ถือว่าผู้ให้กู้สละสิทธิ
          หรือให้ความยินยอมในการผิดสัญญาของผู้กู้แต่อย่างใด เว้นแต่
          ผู้ให้กู้จะยินยอมโดยชัดแจ้งเป็นลายลักษณ์อักษร ทั้งนี้ การกระทำใด ๆ
          ซึ่งผู้กู้และผู้ให้กู้ได้ตกลงยินยอมพร้อมกันทั้งสองฝ่ายแล้ว
          แม้จะแตกต่างกับเงื่อนไขในสัญญาฉบับนี้มิให้ถือว่าเป็นการกระทำที่ผิดสัญญา
          หากการตกลงยินยอมนั้นมีหลักฐานเป็นหนังสือ อนึ่ง การผ่อนผัน การผ่อนเวลา
          ตามสิทธิที่ผู้ให้กู้มีตามสัญญาฉบับนี้
          ซึ่งแม้จะได้กำหนดเวลาแน่นอนไว้ก็ตาม
          ให้ถือว่าเป็นเรื่องเฉพาะครั้งนั้นคราวนั้น
          และมิให้ถือว่าเป็นการสละสิทธิในเรื่องกำหนดเวลาการชำระหนี้ตามสัญญาฉบับนี้ของผู้ให้กู้แต่ประการใด
        </Paragraph>
        <Title>ข้อ 12. การเก็บรวบรวม ใช้ เปิดเผยข้อมูล</Title>
        <Paragraph>
          ผู้กู้ตกลงยินยอมให้ผู้ให้กู้เก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
          หรือข้อมูลใด ๆ ของผู้กู้
          โดยมีวัตถุประสงค์ที่จำเป็นเพื่อการให้บริการแก่ผู้กู้
          การดำเนินการตามคำขอของผู้กู้ก่อนให้บริการ
          การมอบหมายงานให้ผู้อื่นดำเนินการแทนผู้ให้กู้เพื่อสนับสนุนการให้บริการ
          เช่น งานเทคโนโลยีสารสนเทศ งานติดต่อสื่อสาร งานติดตามทวงถาม เป็นต้น
          การโอนสิทธิหรือหน้าที่
          หรือการจัดการข้อร้องเรียนนอกจากนี้ผู้กู้ตกลงยินยอมให้ผู้ให้กู้เปิดเผยข้อมูลดังกล่าวได้ทั้งในประเทศและต่างประเทศให้แก่
          ผู้ให้บริการภายนอก ตัวแทนของผู้ให้กู้ ผู้รับจ้างช่วงงานต่อ
          พันธมิตรทางธุรกิจที่ออกผลิตภัณฑ์ร่วมกันในลักษณะ Co-brand
          ผู้สนใจจะเข้ารับโอนสิทธิหน้าที่ผู้รับโอนสิทธิหน้าที่ หรือผู้ให้บริการ
          Cloud Computing และยินยอมให้ผู้รับข้อมูลจากผู้ให้กู้ดังกล่าวเก็บรวบรวม
          ใช้ หรือเปิดเผยข้อมูลดังกล่าวต่อไปได้ภายใต้วัตถุประสงค์ดังกล่าว
          ทั้งนี้ รายละเอียดอื่น ๆ
          และสิทธิปรากฏในนโยบายการคุ้มครองข้อมูลส่วนบุคคลในเว็บไซต์ของผู้ให้กู้{" "}
          <span className="underline">www.moneyhubservice.com </span>
        </Paragraph>
        <Paragraph>
          ในกรณีที่ผู้กู้มีการให้ข้อมูลส่วนบุคคลของบุคคลอื่นใดแก่ผู้ให้กู้เพื่อดำเนินการใด
          ๆ ที่เกี่ยวข้องกับวัตถุประสงค์ข้างต้น
          ผู้กู้ขอรับรองว่าได้รับความยินยอมจากบุคคลอื่นดังกล่าว
          หรือได้อาศัยหลักเกณฑ์อื่นทางกฎหมายในการให้ข้อมูลส่วนบุคคลของบุคคลอื่นดังกล่าวแก่ผู้ให้กู้
          และได้แจ้งให้บุคคลอื่นดังกล่าวทราบถึงรายละเอียดการเก็บรวบรวมใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลข้างต้นแล้ว
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={6} paid={true}>
        <Header />
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 13. </span>{" "}
          การจำหน่ายหรือการโอนสิทธิเรียกร้องในสัญญากู้ยืมเงิน
          หรือสิทธิในหลักประกันอื่นใดของผู้ให้กู้ไม่ว่าทั้งหมดหรือแต่บางส่วนให้แก่บุคคลภายนอก
          ผู้ให้กู้จะแจ้งเป็นหนังสือไปยังผู้กู้ล่วงหน้าไม่น้อยกว่าหนึ่งงวดของการชำระเงินกู้
          หรือดอกเบี้ย หรืองวดของการคิดดอกเบี้ย
          หรือจะต้องได้รับความยินยอมจากผู้กู้
          มิเช่นนั้นจะยกเป็นข้อต่อสู้ผู้กู้ไม่ได้
          ยกเว้นในกรณีที่มีกฎหมายกำหนดไว้โดยเฉพาะว่าไม่ต้องทำการบอกกล่าวหรือแจ้งแก่ผู้กู้หรือขอความยินยอมจากผู้กู้
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 14. </span>{" "}
          เพื่อเป็นประกันการชำระหนี้ตามสัญญานี้ ผู้กู้ตกลงให้ผู้ให้กู้
          ถือครอง/ยึดถือ โดยมีสิทธิยึดหน่วง และสิทธิครอบครอง
          (ก)ใบคู่มือจดทะเบียน/หนังสือแสดงการจดทะเบียนรถคันที่ระบุรายละเอียดอยู่ในแบบคำขอสินเชื่อทะเบียนรถของสัญญานี้
          ซึ่งต่อไปในสัญญานี้จะเรียกรถคันดังกล่าวว่า “รถหลักประกัน”
          (ข)เอกสารการโอนทะเบียนรถหลักประกัน และ
          (ค)หนังสือมอบอำนาจการจดทะเบียนการโอนรถหลักประกัน
          ไว้จนกว่าหนี้ทั้งหมดที่เกิดจากสัญญานี้จะได้รับชำระหนี้ครบถ้วน
          โดยแจ้งเป็นลายลักษณ์อักษรต่อผู้ให้กู้
          ทั้งนี้ผู้กู้ตกลงจะไม่เพิกถอนการมอบอำนาจจดทะเบียนการโอนรถหลักประกันดังกล่าว
          จนกว่าจะชำระหนี้ตามสัญญานี้จนเสร็จสิ้นสมบูรณ์และผู้กู้ได้แจ้งเป็นลายลักษณ์อักษรต่อผู้ให้กู้แล้ว
          อนึ่ง
          ผู้กู้เข้าใจดีว่าการให้ผู้ให้กู้ถือครอง/ยึดถือเอาเอกสารตามสัญญาข้อนี้เพื่อประกันหนี้ตามสัญญานั้นถือเป็นสาระสำคัญในการที่ผู้ให้กู้พิจารณาตกลงให้เงินกู้กับผู้กู้
          และผู้กู้ตกลงจะมอบเอกสารดังกล่าวข้างต้นไว้กับผู้ให้กู้พร้อมลงรายมือชื่ออย่างถูกต้องสมบูรณ์ตามที่ผู้ให้กู้กำหนด
          เพื่อให้ผู้กู้สามารถนำรถหลักประกันไปขาย/จำหน่ายเพื่อชำระหนี้ตามสัญญาได้เมื่อมีการผิดนัด
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 15. </span>
          ผู้ให้กู้จะส่งใบแจ้งหนี้หรือใบแจ้งยอดบัญชีให้แก่ผู้กู้ทราบทุกเดือน
          หากผู้กู้พบว่ามีรายการใดที่ไม่ถูกต้อง
          ผู้กู้ต้องมีหนังสือแจ้งความผิดพลาดมายังผู้ให้กู้ทราบภายใน 30 วัน
          นับแต่วันที่ได้รับเอกสารดังกล่าว
          หากพ้นระยะเวลาดังกล่าวผู้กู้ตกลงให้ถือว่ายอดหนี้ ยอดบัญชี
          และรายการที่ปรากฏในเอกสารดังกล่าวในแต่ละเดือนนั้นถูกต้องทุกประการ
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 16. </span>
          ผู้กู้ตกลงว่าผู้กู้จะไม่โอนกรรมสิทธิ์
          ในรถหลักประกันของผู้กู้ให้แก่บุคคลอื่นใด จนกว่าผู้กู้จะได้ชำระหนี้
          ตามสัญญานี้ให้แก่ผู้ให้กู้ครบถ้วนโดยแจ้งเป็นลายลักษณ์อักษร
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 17. </span>
          ผู้กู้ตกลงยินยอมให้ผู้ให้กู้มีสิทธิใช้เอกสารที่ผู้ให้กู้ถือครอบครอง/ยึดถือ
          เพื่อประกันหนี้ตามสัญญานี้ในการนำรถหลักประกันไปขาย/จำหน่ายเพื่อนำเงินมาชำระหนี้ตามสัญญานี้ได้เมื่อมีการผิดนัดชำระหนี้ตามข้อกำหนดเงื่อนไขแห่งสัญญานี้
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 18. </span>
          กรณีมีเหตุการณ์อย่างหนึ่งอย่างใดดังต่อไปนี้
          ให้ถือว่าผู้กู้ผิดสัญญาและให้ผู้ให้กู้บอกเลิกสัญญาได้
          <Paragraph>
            18.1 ผู้กู้ผิดนัด ไม่ชำระเงินจำนวนใดๆ ที่ถึงกำหนดชำระตามสัญญานี้
            หรือไม่ปฎิบัติตามเงื่อนไขในสัญญานี้ข้อหนึ่งข้อใด
          </Paragraph>
          <Paragraph>
            18.2 ข้อมูล หรือเอกสารใดๆ
            ที่ผู้กู้ได้ให้ไว้แก่ผู้ให้กู้เกี่ยวกับการขอสินเชื่อไม่ถูกต้องหรือแป็นเท็จหรือหมดอายุหรือถูกยกเลิกแล้วหรือทำให้ผู้ให้กู้เข้าใจผิดในสาระสำคัญ
          </Paragraph>
          <Paragraph>
            18.3 ในกรณีที่ผู้กู้ได้กระทำการใดๆ
            ที่มีลักษณะเป็นการออกใบคู่มือจดทะเบียนรถหลักประกันฉบับใหม่แทนฉบับที่ให้ไว้แก่ผู้ให้กู้
            หรือกระทำการใดๆ
            อันอาจส่งผลให้เอกสารที่มอบไว้เป็นประกันตามที่ระบุไว้ในข้อ 4.7
            แห่งสัญญานี้สิ้นผลลง หรือไม่สามารถใช้บังคับได้
          </Paragraph>
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 19.</span> กรณีมีเหตุการณ์ตามข้อ.18
          แห่งสัญญานี้
          ผู้ให้กู้จะมีหนังสือแจ้งไปยังผู้กู้เพื่อให้ผู้กู้แก้ไขเหตุแห่งการผิดสัญญาหรือผิดเงื่อนไขดังกล่าวให้เสร็จสิ้นภายใน
          20 วันนับแต่วันที่ผู้กู้ได้รับหนังสือบอกกล่าวจากผู้กู้
          หากผู้กู้ไม่สามารถแก้ไขเหตุดังกล่าว ได้ภายในเวลาข้างต้น
          ให้ถือว่าผู้ให้กู้บอกเลิกสัญญากู้ยืมเงินฉบับนี้
          ในวันทำการถัดไปจากวันที่ครบกำหนดการแก้ไขเหตุผิดสัญญาดังกล่าว
          และให้ผู้ให้กู้มีสิทธิเรียกให้ผู้กู้ชำระหนี้ที่ค้างชำระทั้งหมดตามสัญญานี้ได้โดยทันทีโดยให้คิดคำนาณดอกเบี้ย
          ค่าปรับ
          และค่าธรรมเนียมจากยอดเงินกู้คงค้างทั้งหมดต่อไปจนกว่าผู้ให้กู้จะได้รับหนี้ครบถ้วน
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 20. </span>{" "}
          กรณีผู้ให้กู้ติดตามเข้าครอบครองรถหลักประกันได้ ผู้กู้ตกลงให้ส่วนควบ
          และอุปกรณ์ใดที่ติดมากับรถเป็นส่วนหนึ่งของรถหลักประกัน
          และจะไม่เรียกร้องให้ผู้ให้กู้ชดใช้ราคาส่วนควบใดที่ติดมากับรถหลักประกัน
          หรือจัดการแยกชิ้นส่วนดังกล่าวออกแต่อย่างใด
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 21. </span>{" "}
          เพื่อเป็นการบังคับชำระหนี้ตามสัญญานี้
          ผู้กู้ยินยอมให้ผู้ให้กู้เป็นผู้จัดการประมูลขายทอดตลาดรถหลักประกันหรือขาย/จำหน่ายโดยวิธีใดๆ
          แก่บุคคลภายนอกตามราคาที่ผู้ให้กู้เห็นว่าเหมาะสม
          ซึ่งเป็นราคาขายทั่วไปในท้องตลาด
          และทำการส่งมอบและโอนหลักประกันทางทะเบียนแก่ผู้ชนะการประมูลหรือผู้ซื้อนั้นๆ
          รวมถึงดำเนินการอื่นใดเพื่อการดังกล่าว
          โดยรวมถึงการจดทะเบียนเปลี่ยนแปลง/ยกเลิกชื่อผู้ถือกรรมสิทธิ์/ผู้ประกอบการ/ผู้ครอบครอง/รายการอื่นๆ
          ทางทะเบียนของรถหลักประกันด้วย
          ทั้งนี้หากเงินที่ได้จากการขายหลังหักค่าธรรมเนียม ค่าอากรแสตมป์
          ค่าใช้จ่ายในการขายและค่าใช้จ่ายในการติดตามทวงหนี้แล้วมีจำนวนสูงกว่าหนี้ใดๆ
          ที่ค้างชำระทั้งหมดที่ผู้กู้มีอยู่กับผู้ให้กู้ตามสัญญานี้
          ผู้ให้กู้จะจัดการคืนส่วนที่เหลือให้แก่ผู้กู้
          แต่ถ้าเงินที่ได้หลังจากการหักสุทธิดังกล่าวมีจำนวนน้อยกว่าหนี้ที่ค้างชำระที่ผู้กู้มีอยู่กับผู้ให้กู้
          ผู้กู้ยังคงต้องรับผิดชอบชดใช้หนี้ที่ค้างชำระทั้งหมดจนครบถ้วน
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={7} paid={true}>
        <Header />
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 22. </span>
          คำบอกกล่าวใดที่มีกฎหมายหรือข้อสัญญาใดๆ
          กำหนดให้ต้องแจ้งหรือบอกกล่าวเป็นหนังสือ
          ผู้ให้กู้จะทำการส่งคำบอกกล่าวนั้นให้แก่ผู้กู้โดยการส่งทางไปรษณีย์ลงทะเบียนตอบรับตามที่อยู่ที่ระบุไว้ในสัญญาหรือที่อยู่ที่ผู้กู้ได้แจ้งเปลี่ยนแปลงไว้เป็นหนังสือหลังสุด
          ส่วนคำบอกกล่าวหรือเอกสารที่เป็นลายลักษณ์อักษรหรือหนังสือติดต่ออื่นๆ
          ผู้กู้ตกลงให้ส่งถึงตัวผู้กู้โดยตรงหรือโดยทางไปรษณีย์ธรรมดาหรือทางอิเล็กทรอนิกส์
          ทั้งนี้
          ในกรณีการส่งทางไปรษณีย์เมื่อผู้ให้กู้ส่งคำบอกกล่าวหรือเอกสารหรือหนังสือติดต่อใดๆ
          ตามวิธีการข้างต้นไปยังตำบลที่อยู่ของผู้กู้ตามที่ระบุไว้ในสัญญานี้
          หรือที่อยู่ตามที่ผู้กู้ได้แจ้งเปลี่ยนแปลงเป็นหนังสือครั้งหลังสุด
          ให้ถือว่าได้มีการส่งให้และผู้กู้ได้รับโดยชอบแล้ว
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 23. </span>
          หากสัญญานี้ข้อหนึ่งข้อใดเป็นโมฆะหรือไม่สมบูรณ์
          ผู้กู้และผู้ให้กู้ตกลงให้ถือว่าส่วนที่ไม่โมฆะหรือสมบูรณ์แยกออกจากส่วนที่เป็นโมฆะหรือไม่สมบูรณ์นั้นและมีผลใช้บังคับได้ต่อไป
        </Paragraph>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={8} paid={true}>
        <Header />
        <div className="border border-gray-700 pb-3 px-2 flex justify-center mx-24 ">
          “เงื่อนไขการติดตามหนี้ บริษัทขอเรียนให้ท่านทราบว่า
          ในกรณีที่บริษัทมีความจำเป็นที่จะต้องติดตามทางถามหนี้จากท่านหรือผู้ที่เกี่ยวข้อง
          บริษัทอาจดำเนินการด้วยตนเองหรืออาจมอบหมายให้บุคคลที่ประกอบธุรกิจติดตามทวงถามหนี้เป็นผู้ดำเนินการดังกล่าวแทนบริษัทก็ได้
          ทั้งนี้ การดังกล่าวอาจมีค่าใช้จ่ายเกิดขึ้น
          ซึ่งบริษัทหรือตัวแทนของบริษัทดังกล่าวจะแจ้งให้ท่านหรือผู้ที่เกี่ยวข้องทราบในโอกาสต่อไป”
        </div>
        <div className="font-bold text-center pt-8">
          ผู้กู้ได้อ่านและเข้าใจสัญญาโดยตลอดแล้ว
          จึงได้ลงลายมือชื่อไว้ข้างท้ายสัญญาไว้เป็นสำคัญ
          โดยผู้กู้ได้รับสำเนาสัญญาจากผู้ให้กู้แล้ว
        </div>
        <Signature
          fixedNameLeft={`${data?.article} ${data?.fullname}`}
          left={`ผู้กู้`}
          right={`ผู้ค้ำประกัน`}
        />
        <Signature left={`คู่สมรสผู้กู้`} right={`คู่สมรสผู้ค้ำประกัน`} />
        <Signature left={`พยาน`} right={`พยาน`} bottom={true} />
        <Signature
          left={`ผู้ให้กู้`}
          right={`พยาน`}
          fixedNameLeft={`นาย ชยุตม์ ปิยะวงศ์ไพบูลย์`}
        />
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={9} page={9} paid={true}>
        <Header />
        <div className="font-bold text-center">เอกสารแนบ ก.</div>
        <div className="text-center">
          ชื่อผู้ให้กู้
          <span className="font-bold"> บริษัท มันนี่ ฮับ เซอร์วิส จำกัด</span>
        </div>
        <div className="text-center">
          รายละเอียดเกี่ยวกับอัตราดอกเบี้ย เบี้ยปรับ ค่าปรับ ค่าบริการ
          ค่าธรรมเนียมใด ๆ
        </div>
        <div className="text-center">
          และค่าใช้จ่ายใด ๆ
          [ตามที่ได้จ่ายจริงและพอสมควรแก่เหตุเกี่ยวกับสินเชื่อส่วนบุคคลภายใต้การกำกับประเภทสินเชื่อที่มีทะเบียนรถเป็น
        </div>
        <div className="text-center">
          ประกัน]/[ที่ผู้ประกอบธุรกิจอาจเรียกได้ในการประกอบธุรกิจสินเชื่อส่วนบุคคลภายใต้การกำกับ
        </div>
        <div className="text-center">
          เริ่มใช้ตั้งแต่วันที่ {format(new Date(), "dd/MM/yyyy")}
        </div>
        <div>(1) อัตราดอกเบี้ย และค่าธรรมเนียม</div>
        <div className="indent-8">
          ✓ ดอกเบี้ยร้อยละ {getInterest(data)}% ต่อปี{" "}
        </div>
        <div className="indent-8">
          ✓ ค่าธรรมเนียมการใช้วงเงินร้อยละ {others?.FEE_MOTORBIKE}%{" "}
          ของวงเงินสินเชื่อ
        </div>
        <div className="indent-8">
          ✓ ดอกเบี้ยผิดนัดร้อยละ {others?.INTEREST_NTF_PENALTY_MOTORBIKE}% ต่อปี
        </div>
        <div className="indent-8">
          กรณีสินเชื่อส่วนบุคคลภายใต้การกำกับ (Effective rate)
          อัตราดอกเบี้ยไม่เกินกว่าอัตราดอกเบี้ยสูงสุดกรณีปกติสำหรับสินเชื่อนี้
          รวมทั้ง เมื่อคำนวณดอกเบี้ย เบี้ยปรับ ค่าปรับ ค่าบริการ ค่าธรรมเนียมใดๆ
          และค่าใช้จ่ายอื่นๆรวมกันไม่เกินอัตราร้อยละ 24 ต่อปี
        </div>
        <div>(2) การคำนวณดอกเบี้ยและค่าธรรมเนียมการใช้วงเงิน</div>
        <div className="indent-8">✓ การคำนวณดอกเบี้ย : รายวัน</div>
        <div className="indent-8">
          ✓ จำนวนวันต่อปีที่ใช้ในการคำนวณดอกเบี้ย : 365 วัน
        </div>
        <div>(3) ค่าธรรมเนียมและค่าบริการ</div>
        <div className="indent-8">
          ✓ ค่าอากรแสตมป์ : 0.05% ของวงเงินกู้สูงสุดไม่เกิน 1 หมื่นบาท
        </div>
        <div className="indent-8">
          ✓ ค่าใช้จ่ายในตรวจสอบข้อมูลเครดิต : ไม่มีเรียกเก็บ
        </div>
        <div className="indent-8">
          ✓ ค่าติดตามทวงถามหนี้ : มีการเรียกเก็บแล้วแต่กรณีไม่เกินที่กฎหมายกำหนด
        </div>
        <div className="indent-8">
          ✓ ค่าธรรมเนียมชำระเงิน ผ่านช่องทางผู้ให้บริการรายอื่น :
          ขึ้นอยู่กับผู้ให้บริการรายนั้น ๆ
        </div>
        <div className="indent-8">
          ✓ Bill Payment: QR Code พร้อมเพย์: ไม่มีค่าธรรมเนียม
        </div>
        <div>
          (<span className="font-bold">หมายเหตุ</span> :
          อัตราค่าธรรมเนียมอาจมีการเปลี่ยนแปลงได้ ซึ่งเป็นไปตามประกาศของบริษัทฯ
          ซึ่งสามารถตรวจสอบได้ผ่านเว็บไซต์ www.moneyhubservice.com)
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfLoanMotorcycle;
