import React from "react";
import { ButtonBlue } from "../../../components/button/Button";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_TITLE_STEPPERS,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import {
  ButtonSubmitController,
  Controllers,
  FunctionGetUrlFromFile,
} from "radium-omnibus";
import UploadImage from "../../../components/uploadImage/uploadImage";
import classNames from "classnames";
import { onScrollToTop } from "../../../utils/util.main";

function StepSeven({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork = false
}) {
  return (
    <div>
      <div className={`${STYLE_WHITE_CARD} my-10  p-10  `}>
        <Controllers
          loadData={form}
          fields={[
            {
              type: "none",
              name: "title",
              label: "สลิปเงินเดือน/หนังสือรับรองเงินเดือน",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
            },
            {
              type: "customs",
              name: "fcr_slip_imgs",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              classNameCore: classNames("col-span-2 mb-4", {
                ["w-1/2"]: !form.fcr_slip_imgs?.filter((item) => item)
                  .length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_slip_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
            {
              type: "none",
              name: "title",
              label: "รายการเดินบัญชีที่รับเงินเดือน",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
              classNameCore: "pt-4",
            },
            {
              type: "customs",
              name: "fcr_account_statement_imgs",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              classNameCore: classNames("col-span-2 mb-4", {
                ["w-1/2"]: !form.fcr_account_statement_imgs?.filter((item) => item)
                  .length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_account_statement_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
            {
              type: "none",
              name: "title",
              label: "ภาพถ่ายสมุดบัญชีธนาคารที่ต้องการรับเงิน",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
              classNameCore: "pt-4",
            },
            {
              type: "customs",
              name: "fcr_bank_book_imgs",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              classNameCore: classNames("col-span-2 mb-4", {
                ["w-1/2"]: !form.fcr_bank_book_imgs?.filter((item) => item)
                  .length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_bank_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepSeven;
