import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "../components/layouts/Navbar";
import { STYLE_WINDOWS } from "../utils/useStyles";
import "react-toastify/dist/ReactToastify.css";
import { Toastify } from "../components/toast/Toastify";

//PAGES
import LoginMain from "../pages/login/login.main";
import ReportMain from "../pages/report/report.main";
import JobMain from "../pages/job/job.main";
import JobInfo from "../pages/job/render/Job.info";
import ProtectedRoute from "./protectedRoute";
import JobAppoint from "../pages/job/render/Job.appoint";
import WorkMain from "../pages/work/work.main";
import FormMain from "../pages/form/form.main";
import ProfileMain from "../pages/profile/profile.main";

const MENU = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <JobMain />
      </ProtectedRoute>
    ),
  },
  {
    path: "/report",
    element: (
      <ProtectedRoute>
        <ReportMain />{" "}
      </ProtectedRoute>
    ),
  },
  { path: "/login", element: <LoginMain /> },
  {
    path: "/job/:request_id",
    element: (
      <ProtectedRoute>
        <JobInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/job/:request_id/appoint",
    element: (
      <ProtectedRoute>
        <JobAppoint />
      </ProtectedRoute>
    ),
  },
  {
    path: "/job/:request_id/work",
    element: (
      <ProtectedRoute>
        <WorkMain />
      </ProtectedRoute>
    ),
  },
  { path: "/form", element: <FormMain /> },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <ProfileMain />
      </ProtectedRoute>
    ),
  },
];

function RoutesMain() {
  return (
    <Router>
      <div className={STYLE_WINDOWS}>
        <Navbar />
        <Toastify />
        <Routes>
          {MENU.map((route, index) => {
            return (
              <Route
                key={`key-route-non-protect-${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
      </div>
    </Router>
  );
}

export default RoutesMain;
