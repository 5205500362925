import classNames from "classnames";
import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfConsiderLoan = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  const rows = [
    [
      { label: "แบบคำขอสินเชื่อ", value: 1 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      { label: "หนังสือให้ความยินยอมในการเปิดเผยข้อมูล", value: 1 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "ความยินยอมในการใช้ข้อมูลเพื่อจัดทำแบบจำลองด้านเครดิต",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      { label: "ความยินยอมในการประมวลผลข้อมูล", value: 1 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      { label: "สำเนาบัตรประชาชน", value: 5 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      { label: "สำเนาทะเบียนบ้าน", value: 5 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      { label: "เอกสารแสดงรายได้", value: 1 },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label:
          "หลักฐานทางการเงิน (เอกสารโฉนดที่ดิน ,ใบเสร็จชำระเงินค่าบ้าน , เอกสารอื่นๆ)",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สำเนาบัญชีธนาคาร",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "เอกสารอื่นๆ……………………………………………………………………….",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
  ];

  const rows2 = [
    [
      {
        label: "เอกสารประกอบอื่นๆ",
        value: "",
        dump: true,
      },
    ],
    [
      {
        label: "ใบรับรถยนต์",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "หนังสือรับรองการเช่าซื้อ",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สัญญาซื้อขายรถยนต์",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สัญญาเช่าซื้อรถยนต์",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สัญญาต่อท้ายสัญญาเช่าซื้อ",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สัญญาค้ำประกัน (ถ้ามี)",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "คำเตือนสำหรับผู้ค้ำประกัน (ถ้ามี)",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สัญญากู้ยืมเงิน",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "แบบฟอร์มตรวจสภาพรถยนต์",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "แผนที่ที่อยู่ปัจจุบันและที่ทำงาน",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "แบบฟอร์มตรวจสอบที่อยู่อาศัย",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "EDD",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "เอกสารอื่นๆ………………………………………………………………………",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "เอกสารประกอบสำหรับโอนรถยนต์",
        value: "",
        dump: true,
      },
    ],
    [
      {
        label: "แบบคำขอโอนและรับโอน",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "หนังสือมอบอำนาจ",
        value: 2,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "สำเนาคู่มือจดทะเบียนรถ หน้าปัจจุบัน + หน้า 16 + หน้า 18",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "ใบคู่มือจดทะเบียนรถยนต์ (เล่มจริง)",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "ชุดเอกสารขอตรวจสอบข้อมูลทะเบียนรถ",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    [
      {
        label: "เอกสารอื่นๆ……………………………………………………………………",
        value: 1,
      },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
  ];

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={2} page={1}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className="">
            <h2 className="font-bold text-lg">
              แบบฟอร์มตรวจสอบเอกสารพิจารณาสินเชื่อ
            </h2>
            <h2 className="font-bold text-lg">Document Checklist</h2>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="pt-10">
          <div>
            ชื่อผู้เช่าซื้อ {data?.article} {data?.name} {data?.lastname} เลขที่สัญญา{" "}
            {data?.request_id}&nbsp;&nbsp;
          </div>
          <div>
            วันที่...........................................ยี่ห้อ………………………..........…………….………...………รุ่น/แบบ…………………..………………………………………..……………………ทะเบียนรถ…………..….........…………................
          </div>

          <div className="pt-6">
            {/* <div className="border-t border-r border-l border-gray-700 h-6 w-72 pr-1">
              <div className="flex justify-between relative">
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  รายการเอกสารที่ตรวจ
                </div>
              </div>
            </div> */}

            <table className="border-collapse border-t border-r border-l border-gray-700 w-full">
              <thead className="">
                <tr>
                  <th className="border-r border-gray-700 relative h-6">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      รายการเอกสารที่ตรวจ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ผู้เช่าซื้อ
                    </div>
                  </th>
                </tr>
              </thead>
            </table>

            <table className="border-collapse border border-gray-700 w-full">
              <thead className="">
                <tr>
                  <th className="border-r border-gray-700 relative h-6 w-36">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      เอกสารประกอบสินเชื่อ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-4">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ชุด
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-20">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      มี
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-20">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ไม่มี
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((col, index) => {
                  return (
                    <tr key={index}>
                      {col.map((item, itemIndex) => {
                        if (item?.dump) {
                          return (
                            <td
                              colSpan={4}
                              key={itemIndex}
                              className="border-t border-r border-gray-700 relative h-6 w-28"
                            >
                              <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left ml-20 font-bold">
                                {item?.label}
                              </div>
                            </td>
                          );
                        }

                        if (itemIndex === 0) {
                          count++;
                          return (
                            <td
                              key={itemIndex}
                              className="border-t border-r border-gray-700 relative h-6 w-28"
                            >
                              <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                                {count}. {item?.label}
                              </div>
                            </td>
                          );
                        }

                        if (itemIndex === 1) {
                          return (
                            <td
                              key={itemIndex}
                              className={classNames(
                                "border-r border-t border-gray-700 relative h-6",
                                {
                                  "w-4": itemIndex === 1,
                                  "w-20": itemIndex !== 1,
                                }
                              )}
                            >
                              <div className="pl-1 absolute top-1 left-5 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                                {rows[index][0]?.value}
                              </div>
                            </td>
                          );
                        }

                        return (
                          <td
                            key={itemIndex}
                            className={classNames(
                              "border-r border-t border-gray-700 relative h-6",
                              {
                                "w-4": itemIndex === 1,
                                "w-20": itemIndex !== 1,
                              }
                            )}
                          ></td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={2} page={2}>
        {/* Content */}
        <table className="border-collapse border border-gray-700 w-full">
          <thead className="">
            <tr>
              <th className="border-r border-gray-700 relative h-6 w-36">
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  เอกสารประกอบสินเชื่อ
                </div>
              </th>
              <th className="border-r border-gray-700 relative h-6 w-4">
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  ชุด
                </div>
              </th>
              <th className="border-r border-gray-700 relative h-6 w-20">
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  มี
                </div>
              </th>
              <th className="border-r border-gray-700 relative h-6 w-20">
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  ไม่มี
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows2?.map((col, index) => {
              return (
                <tr key={index}>
                  {col.map((item, itemIndex) => {
                    if (item?.dump) {
                      return (
                        <td
                          colSpan={4}
                          key={itemIndex}
                          className="border-t border-r border-gray-700 relative h-6 w-28"
                        >
                          <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left ml-20 font-bold">
                            {item?.label}
                          </div>
                        </td>
                      );
                    }

                    if (itemIndex === 0) {
                      count++;
                      return (
                        <td
                          key={itemIndex}
                          className="border-t border-r border-gray-700 relative h-6 w-28"
                        >
                          <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                            {count}. {item?.label}
                          </div>
                        </td>
                      );
                    }

                    if (itemIndex === 1) {
                      return (
                        <td
                          key={itemIndex}
                          className={classNames(
                            "border-r border-t border-gray-700 relative h-6",
                            {
                              "w-4": itemIndex === 1,
                              "w-20": itemIndex !== 1,
                            }
                          )}
                        >
                          <div className="pl-1 absolute top-1 left-5 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                            {rows2[index][0]?.value}
                          </div>
                        </td>
                      );
                    }

                    return (
                      <td
                        key={itemIndex}
                        className={classNames(
                          "border-r border-t border-gray-700 relative h-6",
                          {
                            "w-4": itemIndex === 1,
                            "w-20": itemIndex !== 1,
                          }
                        )}
                      ></td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pt-4 flex items-center relative">
          <div className="w-32 justify-center items-center flex flex-col">
            <div>ตรวจทานเอกสารตาม</div> <div>Checklist ครบถ้วน</div>
          </div>
          <div className="flex flex-col justify-center">
            <div>ลงชื่อ………………………………………………………….. เจ้าหน้าที่ตรวจสอบสินเชื่อ</div>
            <div>
              ลงชื่อ………………………………………………………….. ผู้ตรวจสอบ/เจ้าหน้าที่Admin
            </div>
          </div>
          <div className="flex flex-col justify-center ml-auto">
            <div>(วันที่……………………………………………………)</div>
            <div>(วันที่……………………………………………………)</div>
          </div>
        </div>
        <div className="pt-4 ml-4">
          ขอรับรองเอกสารที่ยื่นเสนอตาม Checklist
          ที่เสนอในแฟ้มเป็นเอกสารที่ถูกต้องสมบูรณ์
          ลงชื่อ………..…………………………………….ตัวบรรจง
        </div>
        <div className="pt-1 ml-4">
          วันที่จัดเก็บแฟ้มเอกสารและเล่มทะเบียนรถ………………………………………….ลงชื่อ………………………………………………...…..ผู้จัดเก็บ
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfConsiderLoan;
