import classNames from "classnames";
import SearchBar from "../../../components/searchbar/searchbar";
import {
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
} from "../../../utils/useStyles";

const JobHeader = ({ setQuery, query }) => {
  return (
    <div className={classNames(STYLE_HEADER_PADDING, "")}>
      <div className={classNames(STYLE_HEAD_TITLE, "flex self-start")}> JOB </div>
      <div className=" w-2/3">
        <SearchBar query={query} setQuery={setQuery} />
      </div>
    </div>
  );
};
export default JobHeader;
