import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JobCard from "../../../components/card/JobCard";
import WorkCard from "../../../components/card/WorkCard";
import { Container } from "../../../components/layouts/container";
import LoadingPage from "../../../components/loading/loadingPage";
import useQuery from "../../../hooks/useQuery";
import { ICON_ARROW_LEFT } from "../../../utils/useIcons";
import {
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_GOBACK,
  STYLE_JOB_CODE,
  STYLE_PARENT_JOB_LIST,
} from "../../../utils/useStyles";
import JobAppointHeader from "./Job.appointHeader";

function JobAppoint({ row }) {
  const params = useParams();
  const { user } = useSelector((state) => state.initializeApp);
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery("/leasing/request", {
    queryStringParameters: {
      appointBy: user?.username,
    },
  });

  const { data: dataJob, loading: loadingJob } = useQuery(
    `/leasing/request/${params?.request_id}`,
    {}
  );

  if (loading || loadingJob) {
    return (
      <Container>
        <LoadingPage />
      </Container>
    );
  }

  // if (!["นัดหมายแล้ว", "รอนัดหมาย"].includes(dataJob?.leasing?.loan_status)) {
  //   return <div className="flex items-center justify-center h-screen text-xl font-bold">ไม่สามารถนัดหมายได้เนื่องจากนัดหมายไปแล้ว</div>;
  // }

  return (
    <Container>
      <div className={STYLE_HEADER_PADDING}>
        <div className=" flex items-center gap-7 justify-between">
          <ICON_ARROW_LEFT
            className={STYLE_ICON_GOBACK}
            onClick={() => {
              navigate("/");
            }}
          />
          <div className={STYLE_HEAD_TITLE}> กรอกรายละเอียดการนัดหมาย</div>
        </div>
        <div className={STYLE_JOB_CODE}> Job No. {params.request_id} </div>
      </div>
      <JobAppointHeader refetch={refetch} />

      <div className={`${STYLE_HEAD_TITLE_WORK} mt-10 mb-4`}>
        ตารางงานเวลาของฉัน
      </div>
      <div className={STYLE_PARENT_JOB_LIST}>
        {data?.leasing_requests?.map((leasing_request) => {
          return (
            <WorkCard row={leasing_request} key={leasing_request?.request_id} />
          );
        })}
      </div>
    </Container>
  );
}

export default JobAppoint;
