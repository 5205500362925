import { format } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ICON_APPROVE, ICON_LOCATION } from "../../utils/useIcons";
import {
  STLE_TITLE_TYPE_CARD,
  STYLE_BG_CARD,
  STYLE_BORDER_CARD,
  STYLE_BOTTOM_CARD,
  STYLE_CODE_CARD,
  STYLE_CODE_CARD_REPORT,
  STYLE_HEAD_CARD,
  STYLE_ICON_LOCATION_CARD,
  STYLE_LOCATION_CARD,
  STYLE_LOCATION_WHITE_CARD,
  STYLE_NAME_CARD_REPORT,
  STYLE_PARENT_JOB_LIST,
  STYLE_PARENT_WHITE_CARD,
  STYLE_PLATE_REPORT,
  STYLE_STATUS_REPORT_COMPLETE,
  STYLE_STATUS_REPORT_CONFIRM,
  STYLE_STATUS_REPORT_DONE,
  STYLE_STATUS_REPORT_NOT_COMPLETE,
  STYLE_STATUS_REPORT_REJECT,
  STYLE_TITLE,
  STYLE_TITLE_ADDRESS,
  STYLE_TITLE_HEAD_CARD,
  STYLE_TYPE_CARD,
  STYLE_VALUE_WHITE_CARD,
  STYLE_WHITE_CARD,
  STYLE_WRAPPER_CARD_REPORT,
} from "../../utils/useStyles";
import { convertCarType } from "../../utils/util.main";
import { th } from "date-fns/locale";
import numeral from "numeral";
import classNames from "classnames";

function ReportCard({ row, onClick }) {
  const navigate = useNavigate();

  return (
    <div className={STYLE_PARENT_JOB_LIST}>
      <div className="">
        <div className={STYLE_HEAD_CARD}>
          <div className={STYLE_TITLE_HEAD_CARD}>
            {row?.appoint_date !== "-" &&
            typeof row?.appoint_date !== "undefined"
              ? format(new Date(row?.appoint_date), "dd MMMM yyyy", {
                  locale: th,
                })
              : "-"}
          </div>
        </div>

        <div
          className={` bg-white shadow-lg shadow-gray-100 p-8 space-y-8 cardbottom cardshadow `}
        >
          {row?.leasing_requests?.map((leasing_request, index) => {
            return (
              <div key={index}>
                <div className={STYLE_WRAPPER_CARD_REPORT}>
                  <div className=" text-mhs-blue-200 ">
                    {leasing_request?.appoint_date !== "-" &&
                    typeof leasing_request?.appoint_date !== "undefined"
                      ? `${leasing_request?.appoint_start_time} - ${leasing_request?.appoint_end_time}`
                      : "-"}
                  </div>
                  <div className=" flex ">
                    <div className={STYLE_PLATE_REPORT}>
                      {leasing_request?.plate}
                    </div>
                    <div className={`${STYLE_NAME_CARD_REPORT} text-right`}>
                      | {leasing_request?.name} {leasing_request?.lastname}
                      <div className={STYLE_CODE_CARD_REPORT}>
                        {" "}
                        รหัส : {leasing_request?.request_id}
                      </div>
                    </div>{" "}
                  </div>
                </div>

                <div className=" flex ">
                  <div
                    className={classNames({
                      [STYLE_STATUS_REPORT_COMPLETE]:
                        leasing_request?.loan_status === "สมบูรณ์",
                      [STYLE_STATUS_REPORT_NOT_COMPLETE]:
                        leasing_request?.loan_status === "ไม่สมบูรณ์",
                      [STYLE_STATUS_REPORT_DONE]:
                        leasing_request?.loan_status === "งานสำเร็จแล้ว",
                      [STYLE_STATUS_REPORT_CONFIRM]:
                        leasing_request?.loan_status === "ยืนยัน",
                      [STYLE_STATUS_REPORT_REJECT]:
                        leasing_request?.loan_status === "ไม่ผ่าน",
                    })}
                  >
                    {" "}
                    {leasing_request?.loan_status}
                  </div>
                  {"  "}
                  <div className={STYLE_VALUE_WHITE_CARD}>
                    {" "}
                    | ราคาประเมิน{" "}
                    {numeral(leasing_request?.amount).format("0,000.00")}{" "}
                  </div>
                </div>
                <div className=" flex ">
                  <div className={STLE_TITLE_TYPE_CARD}> ประเภทรถ</div>{" "}
                  <div>
                    {" "}
                    {/* ({convertCarType(leasing_request?.carType)}) */}
                    <div className={STYLE_VALUE_WHITE_CARD}>
                      | {leasing_request?.brand} {leasing_request?.model}{" "}
                      {leasing_request?.year}
                    </div>
                  </div>
                </div>
                <div className={STYLE_BORDER_CARD}> </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ReportCard;
