import { Container } from "../../components/layouts/container";
import useQuery from "../../hooks/useQuery";
import JobCard from "../../components/card/JobCard";
import JobHeader from "./render/Job.Header";
import { OPTION_LIMIT } from "../../constrant/option.constrant";
import { useEffect, useState } from "react";
import LoadingPage from "../../components/loading/loadingPage";
import { STYLE_PARENT_JOB_LIST } from "../../utils/useStyles";
import { useSelector } from "react-redux";

const JobMain = () => {
  const { user } = useSelector((state) => state?.initializeApp);

  const getDefaultQuery = () => {
    const objQuery = {
      Limit: OPTION_LIMIT[0].value,
      request_id: "",
      ScanIndexForward: false,
      status: "all_job",
      min: "",
      max: "",
      appointStatusBy: user?.username,
      passBy: "job"
    };
    return objQuery;
  };

  const [query, setQuery] = useState(getDefaultQuery());

  const { data, loading } = useQuery(
    "/leasing/request",
    {
      queryStringParameters: {
        ...query,
      },
    },
    [query]
  );

  return (
    <div className=" bg-mhs-bg w-full">
      <Container>
        <JobHeader query={query} setQuery={setQuery} />
        {loading ? (
          <LoadingPage />
        ) : (
          <div className={STYLE_PARENT_JOB_LIST}>
            {data?.leasing_requests?.map((leasing_request) => {
              return (
                <JobCard
                  row={leasing_request}
                  key={leasing_request?.request_id}
                />
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
};
export default JobMain;
