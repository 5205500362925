import {
  STYLE_BG_LOGIN,
  STYLE_LOGO_LOGIN,
  STYLE_TEXT_LOGIN,
} from "../../../utils/useStyles";

const LoginHead = () => {
  return (
    <div className={STYLE_BG_LOGIN}>
      <img src="/images/logo_mhs.png" className={STYLE_LOGO_LOGIN} />
      <div className={STYLE_TEXT_LOGIN}>" ยืนเคียงข้างทุกความหวัง "</div>
    </div>
  );
};
export default LoginHead;
