import React, { useId } from "react";
import {
  FunctionGetNumberFromString,
  FunctionGetAlphabetFromString,
} from "radium-omnibus";
import classNames from "classnames";

export const Pdf = ({ children }) => {
  return <div className="grid grid-cols-1">{children}</div>;
};

export const LineBottom = ({ className = "" }) => {
  return (
    <div className={classNames(`border-b border-gray-700 mt-2`, className)} />
  );
};

export const Paper = ({
  children,
  className,
  paid,
  page = 0,
  pageCount = 0,
  positionPage = "center",
  spaceY = true,
  showPage = true,
  id,
  hidden = false,
}) => {
  return (
    <div
      // hidden={hidden}
      // id="paper-container"
      id={`${id}_${page}`}
      style={{
        width: "600px",
        height: "842px",
        fontSize: "11px",
        // fontFamily: "TH K2D July8",
        fontFamily: "THSarabun",
        // fontFamily: "TH Baijam",
        position: hidden ? "absolute" : "relative",
        left: hidden ? -999 : 0,
      }}
      className={`  mx-auto ${
        spaceY ? " space-y-1 " : ""
      }  p-6 ${className} `}
      // className={`
      // page-${FunctionGetNumberFromString(trackingNumber)}
      //  bg-gray-100 mx-auto  p-10 space-y-1 ${className} border border-blue-900`}
    >
      {children}
      {/* <div className="absolute bottom-16  left-1/2 transform -translate-x-1/2">
        <div className="flex space-x-1">
          <div id={`self-${trackingNumber}`}></div>
          <div> /</div>
          <div id={`self-all-${trackingNumber}`}></div>
        </div>
      </div> */}
      {showPage && (
        <React.Fragment>
          {positionPage === "center" ? (
            <div className="absolute bottom-16  left-1/2 transform -translate-x-1/2">
              <div className="flex space-x-1">
                <div>{page}</div>
                <div> /</div>
                <div>{pageCount}</div>
              </div>
            </div>
          ) : (
            <div className="absolute bottom-16  right-4">
              <div className="flex space-x-1">
                <div>{page}</div>
                <div> /</div>
                <div>{pageCount}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {paid && <div className="absolute bottom-12 right-12">ชำระอากรแล้ว</div>}
    </div>
  );
};

export const Paragraph = ({ children, className = "", indent = true }) => {
  return (
    <div
      className={`${indent ? " indent-8 " : ""} pt-2 text-justify ${className}`}
    >
      {children}
    </div>
  );
};

export const Grid = ({ children, grid = 1, className = "", gap = 0 }) => {
  return (
    <div
      className={`grid grid-cols-${grid} ${className} ${
        gap > 0 ? ` gap-${gap} ` : ""
      }`}
    >
      {children}
    </div>
  );
};

export const Span = ({ children, span, className = "" }) => {
  return <div className={`col-span-${span} ${className}`}>{children}</div>;
};

export const CheckBox = ({ className = "", checkMark = false }) => {
  return (
    <React.Fragment>
      {/* <img className="w-2 h-2 mt-3" src="/images/checklist.jpg" alt="money-hub" /> */}
      <div
        className={classNames(
          "border border-gray-800 w-2 h-2 mt-4 relative",
          className
        )}
      >
        {checkMark && <div>✓</div>}
      </div>
    </React.Fragment>
  );
};

export const Flex = ({ children, between = false, className }) => {
  return (
    <div
      className={`flex space-x-2 ${className} ${
        between ? " justify-between " : ""
      }`}
    >
      {children}
    </div>
  );
};

// export const NumberPage = ({ page, totalPage }) => {
//   return (
//     <div
//       style={{
//         fontSize: "10px",
//       }}
//       className="text-center pt-8"
//     >
//       {page} / {totalPage}
//     </div>
//   );
// };

export const Logo = () => {
  return (
    <img className="w-8 h-8 ml-2" src="/images/logo_mhs.png" alt="money-hub" />
  );
};

export const Title = ({ children, className = "" }) => {
  return <div className={` font-bold  underline ${className}`}>{children}</div>;
};

export const Signature = ({
  left,
  right,
  bottom = false,
  fixedNameLeft = "",
  fixedNameRight = "",
}) => {
  return (
    <React.Fragment>
      <Grid grid={2} className="pt-10">
        <div className="mx-auto">
          <div className="mx-auto">
            ลายมือชื่อ ................................................................ {left}
          </div>
          <div className="flex justify-center mt-6">
            {fixedNameLeft ? (
              <React.Fragment>( {fixedNameLeft} )</React.Fragment>
            ) : (
              <React.Fragment>
                ( ................................................................ )
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="mx-auto">
          <div className="mx-auto">
            ลายมือชื่อ ................................................................ {right}
          </div>
          <div className="flex justify-center mt-6">
            {fixedNameRight ? (
              <React.Fragment>( {fixedNameRight} )</React.Fragment>
            ) : (
              <React.Fragment>
                ( ................................................................ )
              </React.Fragment>
            )}
          </div>
        </div>
      </Grid>
      {bottom && (
        <Grid grid={2} className="pt-12">
          <div className="mx-auto">
            <div className="mx-auto">บริษัท มันนี่ ฮับ เซอร์วิส จำกัด</div>
            <div className="flex justify-center mt-6"></div>
          </div>
          <div className="mx-auto">
            <div className="mx-auto"></div>
            <div className="flex justify-center mt-6"></div>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};
