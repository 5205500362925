import Swal from "sweetalert2";
import axios from "axios";
import { API, Auth } from "aws-amplify";

/**
 *
 * @param {("SUCCESS" | "ERROR" | "WARNING" | "CONFIRM")} status
 * @param {object = { text: "", logMessage: "" }} options
 * @returns
 */
export const EmitterStatus = async (
  status,
  options = { text: "", logMessage: "", preConfirmCb: () => {}, title: "คุณยืนยันคำขอนี้หรือไม่ ?" }
) => {
  const default_text = {
    SUCCESS: "อัพเดตข้อมูลสำเร็จ",
    ERROR: "อัพเดตข้อมูลไม่สำเร็จ",
    WARNING: "โปรดกรอกข้อมูลให้ครบ",
  };

  const text = options.text ? options.text : default_text[status];
  console.log("options.text", options.text);

  switch (status) {
    case "SUCCESS":
      return await Swal.fire({
        title: status,
        text,
        icon: "success",
        confirmButtonText: "ยืนยัน",
      });
    case "WARNING":
      return await Swal.fire({
        title: "WARNING",
        text,
        icon: "warning",
        confirmButtonText: "ยืนยัน",
      });
    case "ERROR":
      //   await lineNotify(options.logMessage);
      await Swal.fire({
        title: "ERROR",
        text,
        icon: "error",
        confirmButtonText: "ยืนยัน",
      });
      return;
    case "CONFIRM":
      return await Swal.fire({
        title: options?.title,
        showCancelButton: true,
        confirmButtonText: `บันทึก`,
        cancelButtonText: "ยกเลิก",
        showLoaderOnConfirm: true,
        showLoaderOnDeny: false,
        preConfirm: async () => {
          await options.preConfirmCb();
        },
      });
    default:
      break;
  }
};
