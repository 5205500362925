import classNames from "classnames";
import { ICON_RIGHT } from "../../utils/useIcons";
import { mock_data_form } from "../../utils/useMockdata";
import {
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
  STYLE_ICON_FORM,
  STYLE_PARENT_FORM,
  STYLE_TITLE_FROM,
} from "../../utils/useStyles";

const FormMain = () => {
  const onClickRouteForm = (url) => () => {
    window.open(url, "_blank");
  };

  return (
    <div className=" py-12 ">
      <div className={`${STYLE_HEAD_TITLE} mb-12 px-12`}>ฟอร์มเอกสารต่างๆ</div>
      {mock_data_form?.map((row, index) => {
        return (
          <div
            onClick={onClickRouteForm(row.url)}
            className={classNames(STYLE_PARENT_FORM, "cursor-pointer")}
          >
            <div className={STYLE_TITLE_FROM}>{row?.title}</div>
            <ICON_RIGHT className={STYLE_ICON_FORM} />
          </div>
        );
      })}
    </div>
  );
};
export default FormMain;
