import { format } from "date-fns";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import ReportCard from "../../components/card/ReportCard";
import { Container } from "../../components/layouts/container";
import LoadingPage from "../../components/loading/loadingPage";
import { OPTION_LIMIT } from "../../constrant/option.constrant";
import useQuery from "../../hooks/useQuery";
import { STYLE_BOTTOM_CARD } from "../../utils/useStyles";
import ReportHeader from "./render/Report.Head";
import { ButtonBlue } from "../../components/button/Button";
import { getLastItem } from "../../utils/util.main";
import _ from "lodash";
import classNames from "classnames";

const ReportMain = () => {
  const getDefaultQuery = () => {
    const objQuery = {
      Limit: OPTION_LIMIT[0].value,
      request_id: "",
      ScanIndexForward: false,
      status: "all_fcr",
      min: "",
      max: "",
    };
    return objQuery;
  };

  const [query, setQuery] = useState(getDefaultQuery());
  const [groupRequests, setGroupRequests] = useState([]);
  const [lastItem, setLastItem] = useState(null);

  const { data, loading, loadingMore, dataMore, fetchMoreData } = useQuery(
    "/leasing/request",
    {
      queryStringParameters: {
        ...query,
      },
    },
    [query]
  );

  useEffect(() => {
    if (data) {
      const groupRequests = getGroupRequests(data);
      setGroupRequests(groupRequests);
      setLastItem(data.lastItem);
    }
  }, [data]);

  useEffect(() => {
    if (dataMore) {
      const excludeRequests = getExcludeGroupRequests();
      const groupRequests = getGroupRequests({
        leasing_requests: [
          ...excludeRequests.leasing_requests,
          ...dataMore.leasing_requests,
        ],
      });
      setGroupRequests(groupRequests);
      setLastItem(dataMore.lastItem);
    }
  }, [dataMore]);

  const onClickFetchMore = async () => {
    if (lastItem === null) {
      return;
    }

    fetchMoreData({
      queryStringParameters: {
        ...query,
        ExclusiveStartKey: getLastItem(lastItem),
      },
    });
  };

  const getExcludeGroupRequests = () => {
    let groupExcludeRequests = [];
    groupRequests.forEach((groupRequest) => {
      groupExcludeRequests = [
        ...groupExcludeRequests,
        ...groupRequest?.leasing_requests,
      ];
    });
    return { leasing_requests: groupExcludeRequests };
  };

  const getGroupRequests = (data = []) => {
    const groupByAppointDate = data?.leasing_requests?.reduce((prev, curr) => {
      const findExistIndex = prev.findIndex((prevItem) => {
        if (curr?.appoint_date !== "-" && prevItem?.appoint_date !== "-") {
          const prevItemFormatAppointDate = format(
            new Date(prevItem?.appoint_date),
            "yyyy-MM-dd"
          );
          const currFormatAppointDate = format(
            new Date(curr?.appoint_date),
            "yyyy-MM-dd"
          );
          return prevItemFormatAppointDate === currFormatAppointDate;
        } else {
          return prevItem?.appoint_date === curr?.appoint_date;
        }
      });
      if (findExistIndex !== -1) {
        prev[findExistIndex].leasing_requests = [
          ...prev[findExistIndex].leasing_requests,
          curr,
        ];
      } else {
        prev.push({
          appoint_date: curr?.appoint_date,
          leasing_requests: [curr],
        });
      }
      return prev;
    }, []);
    return groupByAppointDate;
  };

  const getEstimateTotal = () => {
    const excludeRequests = getExcludeGroupRequests();
    const data = [...excludeRequests?.leasing_requests];
    const total = data?.reduce((prev, curr) => {
      return prev + curr?.amount;
    }, 0);
    return total;
  };

  return (
    <Container>
      <ReportHeader
        query={query}
        setQuery={setQuery}
        defaultQuery={getDefaultQuery()}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          {groupRequests?.map((rowGroup, index) => {
            return (
              <div className="mt-8">
                <ReportCard key={index} row={rowGroup} />
              </div>
            );
          })}
          <div className={`${STYLE_BOTTOM_CARD} my-10 cardshadowbottom`}>
            <div className=" text-mhs-buttonblue-100">ราคาประเมินรวม</div>{" "}
            <div> {numeral(getEstimateTotal()).format("0,000.00")} บาท </div>
          </div>
        </div>
      )}
      {groupRequests?.length >= query.Limit && (
        <div>
          <ButtonBlue
            loading={loadingMore}
            disabled={lastItem === null}
            onClick={onClickFetchMore}
            text="ดูข้อมูลเพิ่มเติม"
            className={classNames("w-40 mx-auto")}
          />
        </div>
      )}
    </Container>
  );
};

export default ReportMain;
