import { API, Storage } from "aws-amplify";
import classNames from "classnames";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadImage from "../../../components/uploadImage/uploadImage";
import { EmitterStatus } from "../../../event/EmitterStatus";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";

function StepSix({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  setFormError,
  setCheckSubmit,
  refetch,
  isWork = false,
  dataOther = {},
}) {
  const user = useSelector((state) => state?.user);
  const params = useParams();
  const navigate = useNavigate();

  const callbackFilter = (item) => {
    return item;
  };

  return (
    <div>
      <div className={`${STYLE_WHITE_CARD} my-10 p-10  space-y-0 `}>
        <Controllers
          fields={[
            {
              label: "ภาพถ่าย Checklist",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_personal_checklist_imgs",
              classNameCore: "col-span-2 pb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_checklist_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
          ]}
          onSubmit={async () => {
            try {
              let stepOneError = false;
              let stepTwoError = false;
              let stepThreeError = false;
              let stepFourError = false;
              let stepFiveError = false;
              let stepSixError = false;
              let stepSevenError = false;

              // ONE
              if (
                !form?.fcr_comfirm_customer_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_comfirm_sign_imgs?.filter(callbackFilter)?.length
              ) {
                stepOneError = true;
              }

              // TWO
              if (
                !form.fcr_car_front_imgs?.filter(callbackFilter)?.length ||
                !form.fcr_car_back_imgs?.filter(callbackFilter)?.length ||
                !form.fcr_car_left_imgs?.filter(callbackFilter)?.length ||
                !form.fcr_car_right_imgs?.filter(callbackFilter)?.length ||
                !form.fcr_car_hood_imgs?.filter(callbackFilter)?.length ||
                !form.fcr_car_back_skirt_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_console_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_mile_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_back_seat_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_tank_number_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_car_book_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_front_book_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_car_front_book_sixteen_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_car_front_book_eighteen_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_car_insurance_policy_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_car_tax_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_car_inspection_report_imgs?.filter(callbackFilter)
                  ?.length
              ) {
                stepTwoError = true;
              }

              // THREE
              if (
                !form?.fcr_contact_request_loan_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_contact_consent_book_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_contact_consent_book_pdpa_imgs?.filter(
                  callbackFilter
                )?.length ||
                !form?.fcr_contact_transfer_imgs?.filter(callbackFilter)
                  ?.length ||
                // !form?.fcr_contact_power_of_attorney_imgs?.filter(
                //   callbackFilter
                // )?.length ||
                // !form?.fcr_contact_insurance_transfer_imgs?.filter(
                //   callbackFilter
                // )?.length ||
                !form?.fcr_contact_car_registration_loan_imgs?.filter(
                  callbackFilter
                )?.length ||
                !form?.fcr_contact_save_end_imgs?.filter(callbackFilter)?.length
              ) {
                stepThreeError = true;
              }

              // FOUR
              if (
                !form.fcr_personal_id_card_imgs?.filter(callbackFilter)
                  ?.length ||
                // !form.fcr_personal_home_register_imgs ||
                !form.fcr_personal_current_address_imgs?.filter(callbackFilter)
                  ?.length ||
                !form.fcr_personal_home_address_imgs?.filter(callbackFilter)
                  ?.length
              ) {
                stepFourError = true;
              }

              // FIVE
              // if (
              //   !form.fcr_guarantor_id_card_imgs?.filter(callbackFilter)
              //     ?.length ||
              //   !form.fcr_guarantor_home_imgs?.filter(callbackFilter)?.length ||
              //   !form.fcr_guarantor_id_card_real_imgs?.filter(callbackFilter)
              //     ?.length
              // ) {
              //   stepFiveError = true;
              // }

              // SIX
              if (
                !form.fcr_personal_checklist_imgs?.filter(callbackFilter)
                  ?.length
              ) {
                stepSixError = true;
              }

              // SEVEN
              if (
                !form?.fcr_slip_imgs?.filter(callbackFilter)?.length ||
                !form?.fcr_account_statement_imgs?.filter(callbackFilter)
                  ?.length ||
                !form?.fcr_bank_book_imgs?.filter(callbackFilter)?.length
              ) {
                stepSevenError = true;
              }

              if (
                (stepOneError ||
                  stepTwoError ||
                  stepThreeError ||
                  stepFourError ||
                  stepFiveError ||
                  stepSixError ||
                  stepSevenError) &&
                dataOther?.SWITCH_FCR_VAL === "true"
              ) {
                await EmitterStatus("ERROR", {
                  text: "โปรดกรอกข้อมูลให้ครบถ้วน",
                });
                setCheckSubmit(true);
                return;
              }

              const resPreConfirm = await EmitterStatus("CONFIRM", {
                title: "ยืนยันการส่งข้อมูลนี้ใช่หรือไม่ ?",
                preConfirmCb: async () => {
                  try {
                    const formKeys = Object.keys(form);
                    for (const formKey of formKeys) {
                      if (form[formKey] && form[formKey].length > 0) {
                        if (!form[formKey]?.length) {
                          form[formKey] = [];
                        }
                        let fileIndex = 0;
                        for (const file of form[formKey]) {
                          if (file) {
                            const fileKey = `${params.request_id}_${formKey}_${
                              fileIndex + 1
                            }.jpg`;
                            if (String(file).startsWith("http")) {
                              form[formKey][fileIndex] = fileKey;
                            } else {
                              const keyUploaded = await Storage.put(
                                `${params.request_id}_${formKey}_${
                                  fileIndex + 1
                                }.jpg`,
                                file
                              );
                              form[formKey][fileIndex] = keyUploaded.key;
                            }
                          }
                          fileIndex++;
                        }
                      }
                    }

                    await API.post(
                      "MoneyHubAdmin",
                      `/leasing/fcr/${params.request_id}`,
                      {
                        body: {
                          ...form,
                        },
                      }
                    );
                    await API.put(
                      "MoneyHubAdmin",
                      `/leasing/requestStatus/${params.request_id}`,
                      {
                        body: {
                          new_loan_status: "งานสำเร็จแล้ว",
                          adminName: user?.username,
                        },
                      }
                    );
                    await EmitterStatus("SUCCESS", {
                      text: "ยืนยันภาพถ่ายเรียบร้อยแล้ว",
                    });
                  } catch (error) {
                    await EmitterStatus("ERROR", { text: error });
                  }
                },
              });
              // await refetch();

              if (resPreConfirm?.isConfirmed) {
                navigate("/");
                localStorage.removeItem(`fcr_form_${params.request_id}`);
                setStep(0);
              }
            } catch (error) {
              console.log("update error", error);
            }
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={classNames({
          hidden: isWork,
          [STYLE_BUTTON_BLUE_NEXT]: !isWork,
        })}
        labelSubmit="Submit"
      />
    </div>
  );
}

export default StepSix;
