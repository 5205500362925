import { Controllers } from "radium-omnibus";
import React from "react";
import {
  STLYE_CLEAR_BUTTON,
  STYLE_BUTTON_BLUE,
  STYLE_DATE_PICKER,
  STYLE_LABEL_JOB,
  STYLE_WHITE_CARD_JOB,
  STYLE_WRAPPER_JOB,
} from "../../../utils/useStyles";
import { toast } from "react-toastify";
import { Select } from "@celestialcode-react/components";
import { OPTION_APPOINT_DURATION } from "../../../constrant/option.constrant";
import { ClockIcon } from "@heroicons/react/24/outline";
import { EmitterStatus } from "../../../event/EmitterStatus";
import { API } from "aws-amplify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getEndTime } from "../../../utils/util.main";
import { useSelector } from "react-redux";
import { useState } from "react";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";

function JobAppointHeader({ refetch }) {
  const params = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.initializeApp);
  const [id, setId] = useState(new Date().getTime());

  return (
    <div className="">
      <div className={STYLE_WHITE_CARD_JOB}>
        <Controllers
          key={id}
          fields={[
            {
              type: "customs",
              name: "appoint_date",
              label: "วัน / เดือน / ปี ที่นัดหมาย",
              classNameLabel: `${STYLE_LABEL_JOB} `,
              classNameInput: `${STYLE_DATE_PICKER}`,
              classNameCore: " col-span-4",
              fieldCustoms: ({ setValue, value }) => {
                // return (
                //   <DatePicker style={{

                //   }} min={new Date().toISOString().split("T")[0]} />
                return (
                  <React.Fragment>
                    {/* <input
                      min={new Date().toISOString().split("T")[0]}
                      type="date"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      className={STYLE_DATE_PICKER}
                    /> */}
                    <DatePicker
                      allowClear={false}
                      disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                      format={"DD/MM/YYYY"}
                      style={{
                        color: `rgb(135 135 135 / 1)`,
                        fontWeight: "500",
                        padding: "0.5rem",
                        backgroundColor: `rgb(248 249 254 / 1)`,
                        borderColor: "rgb(170 175 181 / 1)",
                        borderWidth: `1px`,
                        borderRadius: `0.75rem`,
                        marginTop: "0.5rem",
                        width: "9rem",
                      }}
                      placeholder="DD/MM/YYYY"
                      value={value ? moment(value) : ""}
                      onChange={(date, dateString) => {
                        setValue(date);
                      }}
                      defaultPickerValue={moment(new Date())}
                    />
                  </React.Fragment>
                );
                // );
              },
            },
            {
              type: "customs",
              name: "appoint_start_time",
              label: "เวลาที่นัดหมาย",
              classNameInput: `${STYLE_DATE_PICKER} w-36`,
              classNameLabel: STYLE_LABEL_JOB,
              classNameCore: " col-span-2 ",
              fieldCustoms: ({ setValue, value }) => {
                return (
                  <TimePicker
                    type="time"
                    allowClear={false}
                    placeholder="-- : --"
                    format={"HH:mm"}
                    value={value ? moment(value) : ""}
                    showSecond={false}
                    onChange={(time, timeString) => setValue(time)}
                    // className={`${STYLE_DATE_PICKER} w-36`}
                    style={{
                      color: `rgb(135 135 135 / 1)`,
                      fontWeight: "500",
                      padding: "0.5rem",
                      backgroundColor: `rgb(248 249 254 / 1)`,
                      borderColor: "rgb(170 175 181 / 1)",
                      borderWidth: `1px`,
                      borderRadius: `0.75rem`,
                      marginTop: "0.5rem",
                      width: "9rem"
                    }}
                  />
                );
              },
            },
            {
              type: "none",
              classNameLabel: " text-mhs-gray-300 text-center mt-9 ml-6",
              label: "",
              // classNameCore: " col-span-1",
            },
            {
              type: "customs",
              name: "appoint_duration",
              label: "เวลาที่ใช้ในการทำงาน",
              fieldCustoms: ({ setValue, value }) => {
                return (
                  <Select
                    value={value}
                    size="lg"
                    placeholder="-- : --"
                    disabledSearch
                    disabledClose
                    classNameSearch="bg-transparen text-gray-700"
                    classNameBoxMenu="!w-36"
                    renderChevron={
                      <ClockIcon className="size-sm text-gray-400" />
                    }
                    classNameBox={`${STYLE_DATE_PICKER} w-36 h-10 px-3 py-0`}
                    options={OPTION_APPOINT_DURATION}
                    onChangeSelect={(newValue) => setValue(newValue)}
                  />
                );
              },
              classNameLabel: STYLE_LABEL_JOB,
              classNameCore: " col-span-3",
            },
          ]}
          firstButton="submit"
          onSubmit={async (form) => {
            if (
              !form.appoint_date ||
              !form.appoint_start_time ||
              !form.appoint_duration
            ) {
              await EmitterStatus("ERROR", {
                text: "กรุณากรอกข้อมูลให้ครบถ้วน",
              });
              return;
            }

            await EmitterStatus("CONFIRM", {
              title: "ยืนยันการนัดหมายนี้ใช่หรือไม่ ?",
              preConfirmCb: async (dataConfirm) => {
                try {
                  await API.put(
                    "MoneyHubAdmin",
                    `/leasing/requestStatus/${params.request_id}`,
                    {
                      body: {
                        appointBy: user?.username,
                        new_loan_status: "นัดหมายแล้ว",
                        appoint_admin_name: user?.adminName || user?.username,
                        appoint_admin_profile_img:
                          `${user?.username}_profile.jpeg` || "",
                        appoint_admin_phone_number: user?.phone_number,
                        appoint_date: `${moment(form?.appoint_date).format(
                          "yyyy-MM-DD"
                        )}T${moment(form?.appoint_start_time).format("HH:mm")}`,
                        appoint_start_time: moment(form?.appoint_start_time).format("HH:mm"),
                        appoint_end_time: `${getEndTime(
                          moment(form.appoint_start_time).format("HH:mm"),
                          form.appoint_duration
                        )}`,
                        appoint_duration: form.appoint_duration,
                      },
                    }
                  );
                  await EmitterStatus("SUCCESS", {
                    text: "ดำเนินการนัดหมายเรียบร้อยแล้ว",
                  });
                  navigate("/");
                } catch (error) {
                  await EmitterStatus("ERROR", { text: error });
                }
              },
            });
            // await refetch({
            //   queryStringParameters: {
            //     appointBy: user?.username,
            //   },
            // })
          }}
          classNameSubmit={`${STYLE_BUTTON_BLUE} w-32 `}
          classNameCancel={STLYE_CLEAR_BUTTON}
          labelCancel="Clear"
          classNameWrapper={STYLE_WRAPPER_JOB}
          onCancel={(props) => {
            setId(new Date().getTime());
          }}
        />
      </div>
    </div>
  );
}

export default JobAppointHeader;
