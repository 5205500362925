import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfCheckCondtionCar = ({ data, others, hidden, id }) => {
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  const rows = [
    [
      "กันชนหน้า + กระจังหน้า",
      "",
      "",
      "ตะเข็บขอบในฝากระโปรงหน้า",
      "",
      "",
      1,
      18,
    ],
    ["ฝากระโปรงหน้า", "", "", "น็อตยึดฝากระโปรง", "", "", 2, 19],
    ["โคมไฟหน้าซ้าย - ขวา", "", "", "ฐานขายึดฝากระโปรง", "", "", 3, 20],
    [
      "บังโคลนหน้าซ้าย - ขวา",
      "",
      "",
      "คานหน้ารถ(คานบน) รูน็อต , รูกลม",
      "",
      "",
      4,
      21,
    ],
    [
      "ประตูหน้า - หลัง (ทั้ง 4 บาน)",
      "",
      "",
      "โครงแขนบังโคลนซ้าย - ขวา",
      "",
      "",
      5,
      22,
    ],
    ["ฝากระโปรงหลัง", "", "", "เบ้าโช้คและซอกหลังซ้าย - ขวา", "", "", 6, 23],
    ["กันชนหลัง", "", "", "แผงหลังห้องเครื่อง", "", "", 7, 24],
    [
      "โคมไฟหลังซ้าย - ขวา",
      "",
      "",
      "แชชซีส์หน้า (ในห้องเครื่อง)",
      "",
      "",
      8,
      25,
    ],
    ["สภาพผิวหลังคา", "", "", "แผ่นเพลท", "", "", 9, 26],
    ["ร่องรางน้ำฝนซ้าย - ขวา", "", "", "ห้องโดยสาร", "", "", 10, 0],
    [
      "รอยต่อหลังคากับขอบกระจกหน้า - หลัง",
      "",
      "",
      "สภาพคอนโซล + เรือนไมล์ + เพดาน",
      "",
      "",
      11,
      27,
    ],
    [
      "กระจกบังลมหน้า - หลัง",
      "",
      "",
      "เบาะนั่ง + แผงประตูข้าง",
      "",
      "",
      12,
      28,
    ],
    [
      "สภาพล้อ + ยาง หน้า - หลัง",
      "",
      "",
      "ซับในเสาประตูหน้า - หลัง",
      "",
      "",
      13,
      29,
    ],
    [
      "กระบะหลัง + ฝาปิดท้าย",
      "",
      "",
      "ตะเข็บซับในประตูหน้า - หลัง",
      "",
      "",
      14,
      30,
    ],
    [
      "ห้องเก็บสัมภาระด้านท้าย",
      "",
      "",
      "อุปกรณ์อื่นๆเพิ่มเติม",
      "",
      "",
      15,
      30,
    ],
    ["ขายึดฝากระโปรง , หัวน็อต", "", "", "Airbag , ABS", "", "", 15, 1],
    ["ตะเข็บขอบซ้าย - ขวา", "", "", "ยางอะไหล่", "", "", 16, 2],
    [
      ".สภาพพื้นผิวที่เก็บยางอะไหล่ , สียาแนว",
      "",
      "",
      "………………………………………….",
      "",
      "",
      17,
      3,
    ],
  ];

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={2} page={1}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className="">
            <h2 className="font-bold text-lg">แบบฟอร์มตรวจสภาพรถยนต์</h2>
            <div className="text-sm">สัญญาเลขที่ {data?.request_id}</div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="pt-6">
          <div className="">
            ชื่อผู้เช่าซื้อ {data?.article} {data?.name} {data?.lastname}{" "}
          </div>
          <div>
            วันที่ตรวจ………………………………………………………………………………สถานที่ตรวจ……………………………………………………………………………………………………………………………………………………………….....
          </div>
          <div>
            ยี่ห้อ………………………...…………….…………………………………รุ่น………………………………………………………………………..……………………สี…………..………..ขนาด……………………….cc.
            เกียร์…………..……………
          </div>
          <div>
            หมายเลขตัวถัง……………………...…………………………………………………………หมายเลขเครื่อง……………………………………………………………….หมายเลขทะเบียน………………………………………………………….
          </div>
          <div>
            ปี ค.ศ.…………………………………ระยะทางที่ใช้แล้ว……………………………………………………………….กม.
            ระบบเชื้อเพลิง……………………………..…..………..………..…….…………
            ระบบขับเคลื่อน……………...ล้อ
          </div>

          <div className="pt-4">
            <div className="relative border border-gray-700 h-7">
              <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                การตรวจสอบและวิเคราะห์สภาพรถยนต์
              </div>
            </div>
            <table className="border-collapse mt-1 border border-gray-700 w-full">
              <thead className="">
                <tr>
                  <th className="border-r border-gray-700 relative h-6 w-28">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      รายการ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-6">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ปกติ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-20">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ผิดปกติ(ระบุ)
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-28">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      รายการ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-6">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ปกติ
                    </div>
                  </th>
                  <th className="border-r border-gray-700 relative h-6 w-20">
                    <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      ผิดปกติ(ระบุ)
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-t border-r border-gray-700 relative h-6 w-28">
                    <div className="absolute top-1 left-6 transform -translate-x-1/2 -translate-y-1/2 font-bold">
                      ภายนอกตัวรถ
                    </div>
                  </td>
                  <td className="border-t border-r border-gray-700 relative h-6 w-6"></td>
                  <td className="border-t border-r border-gray-700 relative h-6 w-20"></td>
                  <td className="border-t border-r border-gray-700 relative h-6 w-28">
                    <div className="absolute top-1 left-5 transform -translate-x-1/2 -translate-y-1/2 font-bold">
                      ห้องเครื่อง
                    </div>
                  </td>
                  <td className="border-t border-r border-gray-700 relative h-6 w-6"></td>
                  <td className="border-t border-r border-gray-700 relative h-6 w-20"></td>
                </tr>
                {rows?.map((col, index) => {
                  return (
                    <tr key={index}>
                      {col.map((item, itemIndex) => {
                        if ([6, 7].includes(itemIndex)) {
                          return null;
                        }

                        if (itemIndex === 1 || itemIndex === 4) {
                          return (
                            <td className="border-t border-r border-gray-700 relative h-6 w-6"></td>
                          );
                        }

                        if (itemIndex === 2 || itemIndex === 5) {
                          return (
                            <td className="border-t border-r border-gray-700 relative h-6 w-20"></td>
                          );
                        }

                        return (
                          <td
                            key={itemIndex}
                            className="border-t border-r border-gray-700 relative h-6 w-28"
                          >
                            {[
                              "ห้องโดยสาร",
                              "ห้องเก็บสัมภาระด้านท้าย",
                              "อุปกรณ์อื่นๆเพิ่มเติม",
                            ].includes(item) ? (
                              <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left font-bold">
                                {item}
                              </div>
                            ) : (
                              <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                                {itemIndex === 0
                                  ? rows[index][6]
                                  : rows[index][7]}
                                . {item}
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={2} page={2}>
        {/* Content */}
        <div className="">
          <div className="mt-2 font-bold inline-block ">
            การประเมินสภาพของรถโดยรวม
          </div>
          <div className="flex items-center">
            <div className="mr-3">1.สภาพสีของรถ</div>
            <CheckBox />
            <div className="ml-1 mr-3">ดีมาก</div>
            <CheckBox />
            <div className="ml-1 mr-3">ดี</div>
            <CheckBox />
            <div className="ml-1 mr-3">ปานกลาง</div>
            <CheckBox />
            <div className="ml-1 mr-3">มีตำหนิต้องปรับปรุง</div>
          </div>
          <div className="flex items-center">
            <div className="mr-4">1.สภาพของรถ</div>
            <CheckBox />
            <div className="ml-1 mr-3">สภาพสวยที่สุดและเดิมที่สุด</div>
            <CheckBox />
            <div className="ml-1 mr-3">สภาพสวยเดิมเกือบทั้งคัน</div>
            <CheckBox />
            <div className="ml-1 mr-3">สภาพเดิมระดับปานกลาง</div>
          </div>
          <div className="ml-14 flex items-center">
            &nbsp;
            <CheckBox />
            <div className="ml-1 mr-3">มีตำหนิต้องปรับปรุงระดับปานกลาง</div>
            <CheckBox />
            <div className="ml-1 mr-3">มีตำหนิพอสมควรระดับเกือบปานกลาง</div>
            <CheckBox />
            <div className="ml-1 mr-3">มีตำหนิต้องปรับปรุงเกือบทั้งคัน</div>
          </div>
          <div className="mt-4">
            <div className="mt-2 font-bold break-words ">
              ความเห็นของเจ้าหน้าที่
              ………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………
            </div>
            <div className="flex items-center justify-end mr-1 mt-3">
              <div>ลงชื่อ………………………………………...เจ้าหน้าที่ผู้ตรวจสอบ</div>
            </div>
            <div className="mt-2">
              <div>
                ข้าพเจ้าได้ตรวจสภาพรถยนต์ร่วมกับเจ้าหน้าที่ของบริษัทฯ
                เป็นที่เรียบร้อยแล้ว ซึ่งมีรายละเอียดข้างต้น
                โดยรถยนต์มีสภาพเรียบร้อย
                ใช้การได้ดีตรงความประสงค์ของข้าพเจ้าทุกประการ
              </div>
              <div className="flex items-center justify-end mr-1 mt-3">
                <div>ลงชื่อ………………………………………………........ผู้เช่าซื้อ/ผู้กู้</div>
              </div>
            </div>
          </div>
          <div className="mt-6 relative border border-gray-700 h-10">
            <div className="pl-1 absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold">
              คัดลอกลายเลขเครื่อง-หมายเลขตัวถัง
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfCheckCondtionCar;
