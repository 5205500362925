import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Modal,
} from "@celestialcode-react/components";
import { API, Storage } from "aws-amplify";
import React, { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../components/layouts/container";
import useAsync from "../../hooks/useAsync";
import useQuery from "../../hooks/useQuery";
import LoadingPage from "../../components/loading/loadingPage";
import { useEffect } from "react";
import { EmitterStatus } from "../../event/EmitterStatus";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import {
  FunctionGetFileFromBase64,
  FunctionGetFileFromUrl,
} from "radium-omnibus";
import { initializeApp } from "../../redux/initializeAppSlice";

const TO_RADIANS = Math.PI / 180;

function ProfileMain() {
  const { user } = useSelector((state) => state?.initializeApp);
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [crop, setCrop] = useState({
    height: 144,
    unit: "px",
    width: 144,
    x: 165.90234375,
    y: 27.38671875,
  });
  const [loadingPage, setLoadingPage] = useState(true);
  const [openCrop, setOpenCrop] = useState(false);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (user) {
      getInitialForm();
    }
  }, [user]);

  useEffect(() => {
    if (openCrop) {
      canvasPreview(imgRef.current, previewCanvasRef.current, crop, 1, 0);
    }
  }, [crop]);

  async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
  }

  const getInitialForm = async () => {
    const profile_img = await Storage.get(user.profile_img);
    setForm({
      adminName: user.adminName || user.username,
      profile_img_raw_ori: user.profile_img,
      profile_img_ori: profile_img,
      profile_img: profile_img,
      phone_number: user?.phone_number,
    });
    setLoadingPage(false);
  };

  const { loading, onAsync: onClickSaveEdit } = useAsync(async () => {
    let profile_img = form?.profile_img;
    if (form?.profile_img_file) {
      const fileName = `${user?.username}_profile.jpeg`;
      const profileUploaded = await Storage.put(
        fileName,
        form?.profile_img_file
      );
      profile_img = profileUploaded.key;
    }

    if (String(profile_img).startsWith("https")) {
      profile_img = form.profile_img_raw_ori;
    }

    // const data = await API.get("MoneyHubAdmin", `/leasing/request`, {
    //   queryStringParameters: {
    //     appointAndStatus: "true",
    //     appointBy: user.username,
    //     loan_status: "นัดหมายแล้ว",
    //     fields: "appoint_admin_name,appoint_admin_phone_number,request_id,loan_status",
    //   },
    // });

    // if (data?.leasing_requests.length > 0) {
    //   const { leasing_requests } = data;
    //   for (const leasing_request of leasing_requests) {
    //     await API.put(
    //       "MoneyHubAdmin",
    //       `/leasing/requestAdminProfile/${leasing_request.request_id}`,
    //       {
    //         body: {
    //           appoint_admin_name: form.adminName,
    //           appoint_admin_phone_number: form.phone_number,
    //         },
    //       }
    //     );
    //   }
    // }

    await API.put("MoneyHubAdmin", `/leasing/adminProfile/${user?.email}`, {
      body: {
        ...form,
        username: user.username,
        profile_img,
      },
    });
    await dispatch(initializeApp(true));
    await EmitterStatus("SUCCESS");
  }, (error) => {
    console.log("error", error)
  });

  const getImage = (url) => {
    return new Promise(function (resolve, reject) {
      let img = new Image();
      img.onload = function () {
        resolve(url);
      };
      img.onerror = function () {
        reject(url);
      };
      img.height = 144;
      img.width = 144;
      img.src = url;
    });
  };

  const onClickSelectFile = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png, image/gif, image/jpeg";
    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setForm({
          ...form,
          profile_img_file: file,
          profile_img: reader.result,
        });
        setOpenCrop(true);
      };
    };
    fileInput.click();
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  };

  const onClickConfirmCrop = async () => {
    const newUrl = previewCanvasRef.current.toDataURL();
    setForm({
      ...form,
      profile_img_file: FunctionGetFileFromBase64(newUrl),
      profile_img: previewCanvasRef.current.toDataURL(),
    });
    setOpenCrop(false);
  };

  if (loadingPage || !user.isLoadAdmin) {
    return (
      <Container>
        <LoadingPage />
      </Container>
    );
  }

  return (
    <Container>
      <Modal
        open={openCrop}
        panel={
          <Dialog className="mb-80">
            <DialogHeader>Crop รูปภาพ (144 x 144)</DialogHeader>
            <DialogContent className="">
              <ReactCrop
                minHeight={144}
                minWidth={144}
                maxHeight={144}
                maxWidth={144}
                circularCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
              >
                <img ref={imgRef} src={form?.profile_img} />
              </ReactCrop>
              <div hidden>
                <canvas
                  id="profile_canvas"
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: crop?.width,
                    height: crop?.height,
                  }}
                />
              </div>
            </DialogContent>
            <DialogFooter className="bg-transparent border-t-0 pt-0">
              <Button label="ตกลง" color="green" onClick={onClickConfirmCrop} />
              <Button
                label="ยกเลิก"
                color="red"
                onClick={() => setOpenCrop(false)}
              />
            </DialogFooter>
          </Dialog>
        }
      />
      <div className="bg-gray-100 py-4 px-6">
        <div className="flex">
          <div className="flex w-1/4 flex-col">
            <div
              style={{
                width: "144px",
                height: "144px",
                // backgroundImage: `url(${
                //   openCrop ? form?.profile_img_ori : form?.profile_img
                // })`,
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
              }}
              className="relative  rounded-full bg-gray-300  overflow-hidden"
            >
              <img
                id="crop_img"
                src={openCrop ? form?.profile_img_ori : form?.profile_img}
                className="rounded-full  w-full h-full"
                // style={{ width: "144px", height: "144px" }}
              />
              <div
                onClick={() => onClickSelectFile()}
                className=" absolute left-1/2 -bottom-6 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex">
              <div className="font-bold">อีเมล : </div>
              <div className="ml-1">{user?.email}</div>
            </div>
            <div className="flex items-start">
              <div className="font-bold">ชื่อ : </div>
              <div className="ml-1">
                <Input
                  placeholder=""
                  onChange={(e) =>
                    setForm((prev) => {
                      return {
                        ...prev,
                        adminName: e.target.value,
                      };
                    })
                  }
                  value={form?.adminName}
                />
                {/* {!form.phone_number && (
                  <div className="text-red-400 text-sm mt-1">
                    โปรดกรอกเบอร์โทรศัพท์
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex items-start">
              <div className="font-bold">เบอร์โทร : </div>
              <div className="ml-1">
                <Input
                  placeholder=""
                  onChange={(e) =>
                    setForm((prev) => {
                      return {
                        ...prev,
                        phone_number: e.target.value,
                      };
                    })
                  }
                  value={form?.phone_number}
                />
                {/* {!form.phone_number && (
                  <div className="text-red-400 text-sm mt-1">
                    โปรดกรอกเบอร์โทรศัพท์
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={onClickSaveEdit}
            disabled={loading}
            loading={loading}
            color="green"
          >
            บันทึก
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default ProfileMain;
