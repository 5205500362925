import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfRequestLoan = ({ data, others, hidden, id }) => {
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={2} page={1}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className="font-bold text-lg">
            <h2>แบบคำขอสินเชื่อ</h2>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="flex flex-col">
          <div>คำขอเลขที่ {data?.request_id}</div>
          <div>สัญญาเลขที่ {data?.contract_id}</div>
        </div>

        {/* Content */}
        <div className="flex justify-between pt-1">
          <div className=" w-4/5 flex flex-col mr-1">
            <div className="border border-gray-800 pb-2">
              <div className="border-b border-r border-gray-600 px-2 relative inline-block text-black font-bold bg-gray-300 h-6 w-12 -mb-3">
                <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-full pl-1">
                  ข้อมูลส่วนตัว
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="flex items-center mr-1">
                  <div className=""> ชื่อ-นามสกุล </div>
                  <div className="ml-1">
                    {data?.article} {data?.name} {data?.lastname}
                  </div>
                </div>
                <div className="ml-1 flex items-center space-x-1">
                  <CheckBox />
                  <div>สินเชื่อทะเบียนรถ (แบบไม่โอนกรรมสิทธิ์)</div>
                </div>
                <div className="ml-1 flex items-center space-x-1">
                  <CheckBox />
                  <div>สินเชื่อเช่าซื้อ (แบบโอนกรรมสิทธิ์)</div>
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div>อายุ {data?.age}</div>
                <div className="ml-1">วัน/เดือน/ปีเกิด {data?.birthdate}</div>
                <div className="ml-1">เพศ {data?.gender}</div>
                <div className="ml-1">สถานะภาพ {data?.marritalStatus}</div>
              </div>
              <div className="ml-1 flex items-center">
                <div>เลขที่บัตรประชาชน {data?.citizenid}</div>

                <div className="ml-1">
                  คู่สมรส:ชื่อ-นามสกุล{" "}
                  {data.marritalStatus === "สมรส" ? (
                    <React.Fragment>
                      {data?.articlePartner} {data?.namePartner}{" "}
                      {data?.lastnamePartner}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      .............................................
                    </React.Fragment>
                  )}
                </div>
                <div className="ml-1 mr-auto">
                  มือถือ{" "}
                  {data.marritalStatus === "สมรส" ? (
                    <React.Fragment>{data.phoneNumberPartner}</React.Fragment>
                  ) : (
                    <React.Fragment>
                      ..............................................
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className=" w-48">
                  ที่อยู่ปัจจุบันชื่อหมู่บ้าน/อาคาร
                  .........................................................
                </div>
                <div className="ml-1">เลขที่ .............. </div>
                <div className="ml-1">หมู่ที่ .............. </div>
                <div className="ml-1">ซอย .............. </div>
                <div className="ml-1">
                  ถนน ...........................................
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">
                  ตำบล/แขวง {getAddress("currentAddress")?.subDistirct}
                </div>
                <div className="ml-1">
                  อำเภอ/เขต {getAddress("currentAddress")?.distirct}
                </div>
                <div className="ml-1">
                  จังหวัด {getAddress("currentAddress")?.province}
                </div>
                <div className="ml-1">
                  รหัสไปรษณีย์ {getAddress("currentAddress")?.postcode}
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">โทรศัพท์มือถือ {data?.phone_number}</div>
                <div className="ml-1">อีเมล {data?.email}</div>
                <div className="ml-1">จำนวนปีที่อาศัย .............. </div>
                <div className="ml-1">ปี .............</div>
                <div className="ml-1">เดือน .......................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div>ลักษณะที่พักอาศัย</div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>บ้านตนเอง</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>บ้านญาติ</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>บ้านพักสวัสดิการ</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>เช่าเอกชน</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>เช่าการเคหะ</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>บ้านบิดา/มารดา</div>
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div>อาศัยอยู่กับ</div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>คนเดียว</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>บิดา/มารดา</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>ญาติ</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>คู่สมรส</div>
                </div>
                <div className="flex items-center space-x-1 ml-1">
                  <CheckBox />
                  <div>เพื่อน</div>
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="mr-1">ที่อยู่ตามทะเบียนบ้าน</div>
                <CheckBox />
                <div className="ml-1">
                  เหมือนที่อยู่ปัจจุบัน ชื่อหมู่บ้าน/อาคาร..............................................
                </div>
                <div className="">เลขที่.................................... </div>
                <div className="">หมู่ที่.......................... </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">ซอย........................................................... </div>
                <div className="">ถนน........................................................... </div>
                <div className="">
                  ตำบล/แขวง {getAddress("address")?.subDistirct}
                </div>
              </div>
              <div className="flex items-center">
                <div className="ml-1">
                  อำเภอ/เขต {getAddress("address")?.distirct}
                </div>
                <div className="ml-1">
                  จังหวัด {getAddress("address")?.province}
                </div>
                <div className="ml-1">
                  รหัสไปรษณีย์ {getAddress("address")?.postcode}
                </div>
              </div>
              <div className="flex items-center">
                <div className="ml-1 mr-1">การศึกษา</div>
                <CheckBox />
                <div className="ml-1 mr-1">ประถมศึกษา-ม.ต้น</div>
                <CheckBox />
                <div className="ml-1 mr-1">ม.ปลาย/ปวช.</div>
                <CheckBox />
                <div className="ml-1 mr-1">อนุปริญญา/ปวส.</div>
                <CheckBox />
                <div className="ml-1 mr-1">ปริญญาตรี</div>
                <CheckBox />
                <div className="ml-1 mr-1">ปริญญาโทขึ้นไป</div>
              </div>
            </div>
            <div className="border border-gray-800 pb-2 mt-1">
              <div className="border-b border-r border-gray-600 px-2 relative inline-block text-black font-bold bg-gray-300 h-6 w-16 -mb-2">
                <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-full pl-1">
                  สถานะการทำงาน
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">ชื่อบริษัท/ห้าง/ร้าน...............................................................................................................</div>
                <div className="">เลขที่...........................................</div>
                <div className="">ชั้น...............................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">ชื่ออาคาร...................................</div>
                <div className="">หมู่ที่...........................</div>
                <div className="">ซอย...........................</div>
                <div className="">ถนน........................................</div>
                <div className="">ตำบล/แขวง............................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">อำเภอ/เขต.............................................</div>
                <div className="">จังหวัด.....................................</div>
                <div className="">รหัสไปรษณีย์....................</div>
                <div className="">โทรศัพท์................................</div>
                <div className="">ต่อ...........................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">เวลาสะดวกเพื่อการติดต่อ....................................</div>
                <div className="">ลักษณะงาน............................................................</div>
                <div className="">ตำแหน่ง............................................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">แผนก.......................................</div>
                <div className="">จำนวนปีที่ทำงาน...............</div>
                <div className="">ปี..........................เดือน</div>
                <div className="ml-2">รายได้หลัก ฐานเงินเดือน........................................บาท/เดือน</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">รายได้อื่นๆ...........................</div>
                <div className="">บาท/เดือน ประเภทของรายได้อื่นๆ............................</div>
                <div className="">ประเทศของแหล่งที่มาของรายได้อื่นๆ.........................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="mr-1">ประเภทอาชีพ</div>
                <CheckBox />
                <div className="ml-1 mr-1">พนักงานบริษัทเอกชน</div>
                <CheckBox />
                <div className="ml-1 mr-1">รับราชการ</div>
                <CheckBox />
                <div className="ml-1 mr-1">พนักงานรัฐวิสาหกิจ</div>
                <CheckBox />
                <div className="ml-1 mr-1">ประกอบธุรกิจส่วนตัว</div>
              </div>
              <div className="ml-12 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">อาชีพอิสระ</div>
                <CheckBox />
                <div className="ml-1 mr-1">รับจ้างชั่วคราว</div>
                <CheckBox />
                <div className="ml-1 mr-1">พ่อบ้าน/แม่บ้าน</div>
                <CheckBox />
                <div className="ml-1 mr-1">อื่นๆ ระบุ</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="font-bold mr-1">
                  กรณีทำงานในบริษัทเอกชน รับราชการ และรัฐวิสาหกิจ
                  โปรดระบุสถานะการจ้างงาน
                </div>
                <CheckBox />
                <div className="mr-1 ml-1">พนักงานประจำ</div>
                <CheckBox />
                <div className="mr-1 ml-1">พนักงานชั่วคราว/พนักงานรายวัน</div>
              </div>
              <div className="ml-1 flex items-center">
                <div>
                  สถานที่ทำงานเดิม (กรณีทำงานในสถานที่ปัจจุบันต่ำกว่า 4 เดือน) :
                  ชื่อบริษัท/ห้าง/ร้าน ................................................................................................
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">โทรศัพท์....................................</div>
                <div className="">ตำแหน่ง.....................................</div>
                <div className="">แผนก......................................</div>
                <div className="">อายุงาน...............</div>
                <div className="">ปี..................</div>
                <div className="">เดือน...............................</div>
              </div>
            </div>
            <div className="border border-gray-800 pb-2">
              <div className="ml-1 flex items-center">
                <div>บุคคลที่สามารถติดต่อได้เพื่อการทวงถามหนี้ (โปรดระบุ)</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">1.ชื่อ-นามสกุล..................................................................</div>
                <div className="">มือถือ..............................................................</div>
                <div className="">ความสัมพันธ์.....................................................</div>
              </div>
              <div className="ml-1 flex items-center">
              <div className="">2.ชื่อ-นามสกุล..................................................................</div>
                <div className="">มือถือ..............................................................</div>
                <div className="">ความสัมพันธ์.....................................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div>
                  บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลและความเป็นหนี้
                  รวมทั้งข้อมูลอื่นแก่บุคคลดังกล่าวเพื่อวัตถุประสงค์ในการทวงถามหนี้
                </div>
              </div>
            </div>
          </div>
          <div className=" w-1/5" style={{ fontSize: ".6rem" }}>
            <div className="flex items-center justify-center">
              <div className="text-center font-bold bg-gray-400 w-full border border-black relative h-5">
                <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-full">
                  รายละเอียดการประเมิน
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-center font-bold bg-gray-400 w-full border mt-1 border-black relative h-5">
                <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-full">
                  รายละเอียดรถ
                </div>
              </div>
            </div>
            <div className="mt-1 border border-black pb-1">
              <div className="flex items-center">
                <div className="ml-1">ประเภท</div>
                <div className="ml-1">
                  {data?.type === "CAR"
                    ? "รถยนต์"
                    : data?.type === "TRUCK"
                    ? "กระบะ"
                    : "รถจักรยานยนต์"}
                </div>
              </div>
              <LineBottom />
              <div className="flex items-center">
                <div className="ml-1">ยี่ห้อ</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <div className="">รุ่น/แบบ</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <div className="w-14">ซีซี</div>
                <div className="">สี</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <div className="mr-1">เกียร์</div>
                <CheckBox />
                <div className="mr-1 ml-1">AUTO</div>
                <CheckBox />
                <div className="ml-1">MANUAL</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <div className="mr-1">ระบบขับเคลื่อน</div>
                <CheckBox />
                <div className="mr-1 ml-1">2WD</div>
                <CheckBox />
                <div className="ml-1">4WD</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <div className="mr-1">ระบบเชื้อเพลิง</div>
                <CheckBox />
                <div className="mr-1 ml-1">เบนซิน</div>
                <CheckBox />
                <div className="mr-1 ml-1">ดีเซล</div>
              </div>
              <LineBottom />
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div style={{ marginLeft: ".15rem", marginRight: ".15rem" }}>
                  GAS
                </div>
                <CheckBox />
                <div style={{ marginLeft: ".15rem", marginRight: ".15rem" }}>
                  LPG
                </div>
                <CheckBox />
                <div style={{ marginLeft: ".15rem", marginRight: ".15rem" }}>
                  NGV
                </div>
                <CheckBox />
                <div style={{ marginLeft: ".15rem", marginRight: ".15rem" }}>
                  HYBRID
                </div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">ระยะทางที่ใช้แล้ว</div>
                <div className="">กม.</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div>หมายเลขเครื่อง</div>
              </div>
              <LineBottom className="!mt-4" />
              <div className="ml-1 flex items-center">
                <div>หมายเลขตัวถัง</div>
              </div>
              <LineBottom className="!mt-4" />
              <div className="ml-1 flex items-center">
                <div>หมายเลขทะเบียน</div>
              </div>
              <LineBottom className="!mt-4" />
              <div className="ml-1 flex items-center pb-4">
                <div>จังหวัดที่จดทะเบียน</div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <Paper id={id} hidden={hidden} pageCount={2} page={2}>
        <div className="flex justify-between pt-2">
          <div className=" w-4/5 flex flex-col mr-1">
            <div className="border border-gray-800 box-border">
              <div className="border-b border-r border-gray-600 px-2 pt-1 pb-2 inline-block box-border text-black font-bold bg-gray-300">
                ประกันภัยรถยนต์
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">
                  ทำกับ บริษัท มันนี่ ฮับ เซอร์วิส จำกัด บริษัท.......................................................................................................................................................
                </div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">ประเภท 1&nbsp;&nbsp;</div>
                <div className="ml-1">ค่าเบี้ย...............................................................</div>
                <div className="">ทุนประกันภัย.........................................</div>
                <div className="">วันที่คุ้มครอง.........................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">ประเภท 2+</div>
                <div className="ml-1">ค่าเบี้ย...............................................................</div>
                <div className="">ทุนประกันภัย.........................................</div>
                <div className="">วันที่คุ้มครอง.........................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">ประเภท 3+</div>
                <div className="ml-1">ค่าเบี้ย...............................................................</div>
                <div className="">ทุนประกันภัย.........................................</div>
                <div className="">วันที่คุ้มครอง.........................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-1">ประเภท 2&nbsp;&nbsp;</div>
                <div className="ml-1">ค่าเบี้ย...............................................................</div>
                <div className="">ทุนประกันภัย.........................................</div>
                <div className="">วันที่คุ้มครอง.........................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1">มีประกันภัยแล้ว บริษัท...................................</div>
                <div className="">ประเภท...................</div>
                <div className="">ทุนประกันภัย...........................</div>
                <div className="">สิ้นสุดความคุ้มครอง.....................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1 mr-2">รวมพรบ.</div>
                <CheckBox />
                <div className="ml-1">ไม่รวมพรบ.......................................</div>
                <div>บาท</div>
              </div>
              <div className="ml-1 flex items-center">
                <CheckBox />
                <div className="ml-1">ไม่ทำประกันภัย (ระบุ)...........................................................................................................................................................................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">ผู้รับแจ้ง..............................................</div>
                <div className="">วันที่.................................</div>
                <div className="">เวลา...................</div>
                <div className="">น. เลขที่รับแจ้ง...........................</div>
                <div className="">โทร..............................................</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="">กำหนดชำระทุกวันที่......................</div>
                <div className="">ของเดือน.........................</div>
                <div className="">ชำระค่างวดแรกวันที่............................</div>
                <div className="mr-1">สถานที่จัดส่งเอกสาร</div>
                <CheckBox />
                <div className="ml-1 mr-2">บ้าน</div>
                <CheckBox />
                <div className="ml-1 mr-1">ที่ทำงาน</div>
              </div>
            </div>
          </div>
          <div className=" w-1/5" style={{ fontSize: ".6rem" }}>
            <div className="flex items-center justify-center">
              <div className="text-center font-bold bg-gray-400 w-full border border-black relative h-5">
                <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-full">
                  การคำนวณค่าเช่าซื้อ
                </div>
              </div>
            </div>
            <div className="mt-1 border border-black pb-1">
              <div className="ml-1 flex items-center">
                <div className="w-20">ราคาเงินสด</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">เงินดาวน์</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">ยอดกู้</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">ภาษีมูลค่าเพิ่ม</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">รวมยอดกู้</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">อัตราดอกเบี้ย</div>
                <div className="">% / ปี</div>
              </div>
              <div className="ml-1 flex items-center">
                <div className="w-20">ระยะเวลาเช่าซื้อ</div>
                <div className="">งวด</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center">
                <div className="w-20">ค่างวดรวม</div>
                <div className="">บาท</div>
              </div>
              <LineBottom className="" />
              <div className="ml-1 flex items-center pb-2">
                <div className="w-20">ราคาประเมิน</div>
                <div className="">บาท</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-justify">
          ข้าพเจ้าผู้ขอเช่าซื้อมีความประสงค์จะเช่าซื้อรถยนต์ซึ่งมีรายละเอียดตามที่ระบุในแบบคำขอเช่าซื้อรถยนต์ฉบับนี้
          จึงขอให้บริษัท มันนี่ ฮับ เซอร์วิส จำกัด ("บริษัทฯ")
          ซื้อรถยนต์คันดังกล่าวเพื่อให้ข้าพเจ้าได้เช่าซื้อตามข้อตกลง และเงื่อนไข
          แห่งสัญญาเช่าซื้อรถยนต์ของบริษัทฯ โดยข้าพเจ้าตกลงและรับทราบว่าบริษัทฯ
          มิได้เป็นผู้แทนจำหน่ายรถยนต์
          และมิได้รับรองในคุณภาพของรถยนต์คันดังกล่าวแต่อย่างใด
          และเพื่อประกอบพิจารณาของบริษัทฯ
          ข้าพเจ้าจึงได้ให้ข้อมูลส่วนบุคคลของข้าพเจ้า
          โดยข้าพเจ้าขอรับรองว่าข้อมูลที่ข้าพเจ้าได้ให้ไว้แก่บริษัทฯ
          ตามที่ปรากฏในแบบคำขอเช่าซื้อรถยนต์ฉบับนี้เป็นความจริงทุกประการ
          <div className="flex items-center mr-auto justify-end">
            <div className="font-bold">*ข้อควรระวัง</div> :
            การผิดนัดชำระอาจมีค่าทวงถาม โปรดทำความเข้าใจก่อนลงนาม
          </div>
        </div>
        <div className="grid grid-cols-3 items-center py-4">
          <div className="flex items-center flex-col justify-center">
            <div>…………………………………………………………</div>
            <div>
              ({data?.article} {data?.name} {data?.lastname})
            </div>
            <div className="font-bold">ลายมือชื่อผู้ขอเช่าซื้อ</div>
            <div className="flex items-center">
              <div className="">วันที่……………/………………/……………........</div>
            </div>
          </div>
          <div className="flex items-center flex-col justify-center">
            <div>…………………………………………………………</div>
            <div>(________________________________)</div>
            <div className="font-bold">ลายมือชื่อคู่สมรส/ผู้ค้ำประกัน</div>
            <div className="flex items-center">
              <div className="">วันที่……………/………………/……………........</div>
            </div>
          </div>
          <div className="flex items-center flex-col justify-center">
            <div>…………………………………………………………</div>
            <div>(________________________________)</div>
            <div className="font-bold">
              ลายมือชื่อเจ้าหน้าที่ผู้รับผิดชอบ(ตัวบรรจง)
            </div>
            <div className="flex items-center">
              <div className="">วันที่……………/………………/……………........</div>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfRequestLoan;
