import jsPDF from "jspdf";
import fontSarabunTH from "../font/fontSarabunTH";
import fontK2DJuly from "../font/fontK2DJuly";
import fontBaiJam from "../font/fontBaijam"
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import parseHtml from "html-react-parser";
import { Storage } from "aws-amplify";

const FunctionExportPdf = async ({
  fileName = new Date().getTime(),
  export_items = [],
  setPercent,
}) => {
  // let element = component({ data, others });
  const width = 595;
  const height = 842;

  const doc = new jsPDF("p", "px", "a4",);

  // doc.addFileToVFS("TH K2D July8.ttf", fontK2DJuly);
  // doc.addFont("TH K2D July8.ttf", "TH K2D July8", "normal");
  // doc.setFont("TH K2D July8");

  doc.addFileToVFS("THSarabun.ttf", fontSarabunTH);
  doc.addFont("THSarabun.ttf", "THSarabun", "normal");
  doc.setFont("THSarabun");

  // doc.addFileToVFS("TH Baijam.ttf", fontBaiJam);
  // doc.addFont("TH Baijam.ttf", "TH Baijam", "normal");
  // doc.setFont("TH Baijam");

  // doc.text( "สวัสดี คนทั่วโลก ถ้าเรา", 20, 20);
  // doc.save("a4.pdf");

  // const docElement = document.getElementById("PdfEDD");
  // doc.html(docElement, { callback: () => {
  //   doc.save(`${fileName}.pdf`);
  // }});

  for (const export_item of export_items) {
    const { id, count } = export_item;
    for (let index = 0; index < count; index++) {
      const docElement = document.getElementById(`${id}_${index + 1}`);
      const canvas = await html2canvas(docElement, { scale: 3 });
      const image = canvas.toDataURL("image/png");

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
      if (index < count - 1) {
        doc.addPage();
      }
    }
  }
  doc.save(`${fileName}.pdf`);
};

export default FunctionExportPdf;
