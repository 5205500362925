import classNames from "classnames";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import React from "react";
import UploadImage from "../../../components/uploadImage/uploadImage";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import { onScrollToTop } from "../../../utils/util.main";

function StepFive({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork,
}) {
  return (
    <div>
      <div className={`${STYLE_WHITE_CARD} my-10 p-10  space-y-0 `}>
        <Controllers
          fields={[
            {
              label: "สำเนาบัตรประชาชนผู้ค้ำ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_guarantor_id_card_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_guarantor_id_card_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "สำเนาทะเบียนบ้านผู้ค้ำ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              subLabel: "ทะเบียนสมรส, ใบเปลี่ยนชื่อ-สกุล, สูติบัตรบุตร",
              classNameSubLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_guarantor_home_imgs",
              classNameCore: classNames(`mb-4 col-span-2`, {
                ["w-1/2"]: !form.fcr_guarantor_home_imgs?.filter(item => item).length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_guarantor_home_imgs"
                    onChangeFile={onChangeFile}
                    isMultiple
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "ภาพถ่ายบัตรประชาชนตัวจริงคู่สำเนาผู้ค้ำ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_guarantor_id_card_real_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_guarantor_id_card_real_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepFive;
