import { Controllers } from "radium-omnibus";
import { ICON_SEARCH } from "../../utils/useIcons";
import {
  STYLE_ICON_SEARCH,
  STYLE_OPTION,
  STYLE_OPTIONS,
  STYLE_PARENT_SEARCHBAR,
  STYLE_SEARCH_INPUT,
  STYLE_VALUE_SEARCH,
} from "../../utils/useStyles";
import { OPTION_JOB, OPTION_REPORT } from "../../constrant/option.constrant";
import { Radio, Select } from "@celestialcode-react/components";
import classNames from "classnames";
import { useState } from "react";

const SearchBar = ({ query, setQuery, type = "JOB", defaultOption = 0 }) => {
  const [form, setForm] = useState({
    status: "",
    search: "",
    searchBy: "name",
    filterMatch: true,
    name: "",
    phone_number: "",
  });

  const onClickSubmit = (e) => {
    let newQuery = {
      ...query,
      ...form,
    };

    delete form?.name;
    delete form?.phone_number;
    newQuery[form.searchBy] = form?.search;
    if (form.status === "") {
      newQuery.status = getOptions()[defaultOption].value;
    } else {
      newQuery.status = form.status.value;
    }
    setQuery(newQuery);
  };

  const getOptions = () => {
    if (type === "JOB") {
      return OPTION_JOB;
    } else if (type === "REPORT") {
      return OPTION_REPORT;
    }
  };

  const onChangeForm = (value, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div>
      {/* <form> */}
        <div className={STYLE_PARENT_SEARCHBAR}>
          <div className="col-span-2">
            <Select
              disabledClose
              disabledSearch
              classNameSearch=" bg-transparent text-xs  "
              classNameBox={`${STYLE_VALUE_SEARCH} `}
              options={getOptions()}
              onChangeSelect={(e) => onChangeForm(e, "status")}
              value={form.status}
              renderMenuOption={(option) => {
                return (
                  <div
                    className={classNames(STYLE_OPTION, {
                      "hover:bg-mhs-blue-50 ": option?.isHover,
                      "bg-mhs-blue-50": option?.isActive,
                    })}
                  >
                    {option.label}
                  </div>
                );
              }}
            />
          </div>
          <div className="col-span-5">
            <input
              value={form.search}
              onChange={(e) => onChangeForm(e.target.value, "search")}
              className={STYLE_SEARCH_INPUT}
              placeholder="ชื่อ / เบอร์โทร"
            />
          </div>
          <button onClick={onClickSubmit} className={STYLE_ICON_SEARCH}>
            <ICON_SEARCH className="w-5" />
          </button>
        </div>
        <div className="flex items-center space-x-3 mt-2">
          <Radio
            label="ชื่อ"
            checked={form.searchBy === "name"}
            value="name"
            onChange={(e) => onChangeForm(e.target.value, "searchBy")}
          />
          <Radio
            label="เบอร์โทร"
            checked={form.searchBy === "phone_number"}
            value="phone_number"
            onChange={(e) => onChangeForm(e.target.value, "searchBy")}
          />
        </div>
      {/* </form> */}
    </div>
  );
};

export default SearchBar;
