import React, { useState, useEffect } from "react";

function useAsync(
  cb = () => {},
  cbError = () => {},
  cbFinally = () => {},
  options = { loading: false }
) {
  const [loading, setLoading] = useState(options?.loading);
  const [error, setError] = useState(null);

  const onAsync = async (args) => {
    try {
      setLoading(true);
      await cb(args);
    } catch (error) {
      setError(error);
      await cbError(error);
    } finally {
      setLoading(false);
      await cbFinally();
    }
  };

  return {
    onAsync,
    loading,
    setLoading,
    error,
    setError,
  };
}

export default useAsync;
