import { format } from "date-fns";
import React from "react";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";
import { th } from "date-fns/locale";

const Header = ({ first, number }) => {
  return (
    <Flex between={true}>
      {first ? (
        <div className="border border-gray-700 w-40 px-2">
          เลขที่สัญญา {number}
        </div>
      ) : (
        <div></div>
      )}
      <Flex between={true}>
        <Grid grid={1}>
          <Span className="text-end font-bold -mt-2" span={1}>
            มันนี่ ฮับ ลีสซิ่ง
          </Span>
          <Span className="font-bold mb-2" span={1}>
            MONEY HUB LEASING
          </Span>
        </Grid>
        <Logo />
      </Flex>
    </Flex>
  );
};
 const Table = ({
  first,
  second,
  third,
  classNameWrapper,
  topLine = false,
  indent = true,
  line = 1,
}) => {
  return (
    <div className={`grid grid-cols-8 ${classNameWrapper} `}>
      <div
        style={{
          height: `${18 * line}px`,
        }}
        className={`col-span-4 border border-gray-700  relative h-auto w-full  ${
          indent ? " indent-4 " : ""
        } ${topLine ? " border-t-gray-700 " : " border-t-0 w-full "}`}
      >
        <div className="absolute -top-2   w-full ">{first}</div>
      </div>

      <div
        className={`col-span-3 border border-gray-700 border-l-0 text-center relative h-auto w-full ${
          topLine ? " border-t-gray-700 " : " border-t-0 "
        }`}
      >
        <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-full ">
          {second}
        </div>
      </div>

      <div
        className={`col-span-1 border border-gray-700 border-l-0 text-center relative h-auto w-full ${
          topLine ? " border-t-gray-700 " : " border-t-0 "
        }`}
      >
        <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-full ">
          {third}
        </div>
      </div>
    </div>
  );
};

const PdfInterestRate = ({ hidden, id, data, others }) => {
  return (
    <React.Fragment>
      <Paper hidden={hidden} id={id} spaceY={false} showPage={false} page={1}>
        <Header />
        <div className="text-center font-bold text-md">
          อัตราดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียมต่างๆ สินเชื่อเช่าซื้อ
          (รถยนต์)
        </div>
        <Paragraph className="text-md mt-2 mb-4">
          รายละเอียดเกี่ยวกับอัตราดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียมใดๆ
          และค่าใช้จ่ายตามที่ได้จ่ายจริง
          และพอสมควรแก่เหตุเกี่ยวกับสินเชื่อเช่าซื้อ (รถยนต์) เริ่มใช้ตั้งแต่
          วันที่{" "}
          {format(new Date(data?.startDueDate), "d MMMM yyyy", { locale: th })}
        </Paragraph>

        <Table
          first={`รายการ`}
          second={`อัตราที่เรียกเก็บ`}
          third={`หมายเหตุ`}
          classNameWrapper="text-center font-bold"
          topLine={true}
          indent={false}
        />
        <Table
          line={1}
          first={
            <div className="font-bold">
              1. ดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียมใดๆ
            </div>
          }
          // second={`ต่ำสุด 10.79% ถึงสูงสุดไม่เกิน 15% ต่อปี`}
          third={``}
        />
        <Table
          first={`•	ดอกเบี้ย`}
          second={`สูงสุดไม่เกิน 15% ต่อปี`}
          third={``}
        />
        <Table
          first={`•	ดอกเบี้ยกรณีผิดนัดสัญญา (อัตราดอกเบี้ยสูงสุด)`}
          second={`อัตราดอกเบี้ยตามสัญญาบวกไม่เกิน 5% ต่อปี`}
          // third={
          //   <div className="w-full">
          //     <span className="underline font-bold">หัก</span> ออกจากวงเงินกู้
          //   </div>
          // }
        />
        <Table
          first={`•	ค่าธรรมเนียมการประเมินราคารถยนต์`}
          second={`เรียกเก็บร้อยละ ${others?.ESTIMATE_CAR}% ของวงเงินกู้`}
          third={
            <div className="w-full">
              <span className="underline font-bold">หัก</span> ออกจากวงเงินกู้
            </div>
          }
        />
        <Table
          first={`•	ค่าธรรมเนียมการใช้วงเงินกู้`}
          second={`ไม่เรียกเก็บ`}
          third={``}
        />
        <Table
          first={`•	ค่าตรวจสอบข้อมูลเครดิต`}
          second={`ไม่เรียกเก็บ`}
          third={``}
        />
        <Table
          first={`•	ค่าโอนเล่ม,ค่าตรวจสภาพรถ`}
          second={`เรียกเก็บตามจริง`}
          third={
            <div className="w-full">
              <span className="underline font-bold">หัก</span> ออกจากวงเงินกู้
            </div>
          }
        />
        <div
          style={{
            height: `${18}px`,
          }}
          className={`col-span-4 border border-gray-700 font-bold indent-4 relative h-auto  border-t-0`}
        >
          <div className="absolute -top-2   w-full ">
            2. ค่าใช้จ่ายตามที่ได้จ่ายจริงและพอสมควรแก่เหตุ
          </div>
        </div>
        <Table
          first={`•	ค่าอากรแสตมป์`}
          second={`ไม่เกิน 0.05% ของวงเงินสินเชื่อ ไม่เกินอัตราที่กฎหมายเรียกเก็บ`}
          third={
            <div className="w-full">
              <span className="underline font-bold">หัก</span> ออกจากวงเงินกู้
            </div>
          }
        />
        <Table
          first={`•	ค่าธรรมเนียมการโอนเงิน`}
          second={`ไม่เรียกเก็บ`}
          third={``}
        />
        <Table
          first={`•	ค่าใช้จ่ายในการทวงถามหนี้ (กรณีค้างชำระ)`}
          second={``}
          third={``}
        />
        <Table
          first={<div className="indent-6">- กรณีค้างชำระ 1 งวด</div>}
          second={`งวดละ ${others?.DEMAND_FEE_NTF_ONE} บาท เมื่อล่าช้าเกิน ${others?.INTEREST_PENALTY_DATE} วัน จากวันที่กำหนดชำระ`}
          third={``}
        />
        <Table
          first={<div className="indent-6">- กรณีค้างชำระ 1 งวด ขึ้นไป</div>}
          second={`งวดละ ${others?.DEMAND_FEE_NTF_TWO} บาท เมื่อแต่ละงวดล่าช้าเกิน ${others?.INTEREST_PENALTY_DATE} วัน`}
          third={``}
        />
        <Table
          first={
            <div className="indent-6">
              - กรณีมีการส่งเจ้าหน้าที่ออกนอกสถานที่เมื่อลูกหนี้ผิดนัดมากกว่า 1
              งวด
            </div>
          }
          second={`ตามที่เกิดขึ้นจริง ไม่เกิน ${others?.VISIT_FEE} บาท ต่อรอบการทวงถามหนี้`}
          third={`ค้างชำระ 60-120 วัน`}
        />
        <Table
          first={
            <div className="indent-6">
              -
              ค่าใช้จ่ายในการติดตามบังคับทรัพย์สินที่ลูกหนี้วางไว้เป็นประกันการชำระหนี้
            </div>
          }
          second={`ค่าทนายความและค่าดำเนินการ 3,000 – 16,000 บาท และค่าธรรมเนียมศาลตามจริง`}
          third={``}
          line={2}
        />
        <Table
          first={
            <div className="indent-6">
              - ค่ายกเลิกสัญญา กรณีค้างชำระครบ 120 วัน
            </div>
          }
          second={`${others?.BREAK_FEE} บาท`}
          third={``}
          line={1}
        />
        <div
          style={{
            height: `${18}px`,
          }}
          className={`col-span-4 border border-gray-700 font-bold indent-4 relative h-auto  border-t-0`}
        >
          <div className="absolute -top-2   w-full ">
            3. ค่าบริการ ค่าใช้จ่าย และค่าธรรมเนียมอื่นๆ
            (อัตราค่าบริการเป็นไปตามเงื่อนไขที่ผู้บริการกำหนด
            และเรียกเก็บจากผู้ใช้บริการโดยตรง)
          </div>
        </div>
        <Table
          first={`•	ค่าบริการขอสำเนาเล่มทะเบียน`}
          second={`100 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าธรรมเนียมขอเอกสารเกี่ยวกับงานทะเบียน/สัญญา`}
          second={`200 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการขอแผ่นป้ายทะเบียน`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการแจ้งย้ายเข้า-ออก`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการแก้ไขชื่อ-สกุล ในสมุดคู่มือจดทะเบียนรถ`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการแจ้งเปลี่ยนสีรถ / เปลี่ยนเครื่องยนต์`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการคัดป้ายแสดงรายการเสียภาษี (กรณีสูญหาย)`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <Table
          first={`•	ค่าบริการแจ้งเปลี่ยนลักษณะรถ ติดตั้ง / ยกเลิกแก๊ส`}
          second={`กรุงเทพฯ 500 บาท / ต่างจังหวัด 800 บาท`}
          third={``}
        />
        <div className="underline font-bold pt-2">หมายเหตุ</div>
        <div className="indent-8">
          - การคำนวณอัตราดอกเบี้ยแบบลดต้นลดดอกต่อปี
          ขึ้นอยู่กับระยะเวลาที่เลือกผ่อนชำระของสินเชื่อที่ได้รับการอนุมัติ
        </div>
        <div className="indent-8">
          - อัตราค่าใช้จ่ายในการทวงถามหนี้ยังไม่รวมภาษีมูลค่าเพิ่ม
        </div>
        <div className="indent-8">
          - อัตราค่าธรรมเนียม ค่าใช้จ่ายและค่าบริการอื่นๆ รวมภาษีมูลค่าเพิ่ม
        </div>
        <div className="indent-8">
          - บริษัทฯ ขอเรียนให้ทราบว่า บริษัทฯ
          จะมอบหมายให้บุคคลอื่นที่ประกอบธุรกิจติดตามทวงถามหนี้
          เป็นผู้ติดตามทวงถามหนี้จากท่าน
        </div>
        <div className="indent-8">
          - รอบการทวงถามหนี้ หมายความว่า รอบระยะเวลาเพื่อการทวงถามหนี้
          โดยนับตั้งแต่วันผิดนัด
          ชำระหนี้ของงวดนั้นจนถึงวันครบกำหนดชำระหนี้งวดถัดไป ทั้งนี้
          การนับหนึ่งรอบระยะเวลาดังกล่าวจะต้องไม่น้อยกว่าหนึ่งเดือน
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfInterestRate;
