import { format } from "date-fns";
import th from "date-fns/locale/th";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ICON_APPROVE, ICON_LOCATION } from "../../utils/useIcons";
import {
  STLE_TITLE_TYPE_CARD,
  STYLE_BG_CARD,
  STYLE_BORDER_CARD,
  STYLE_CODE_CARD,
  STYLE_DATE_REPORT,
  STYLE_HEAD_CARD,
  STYLE_ICON_LOCATION_CARD,
  STYLE_LOCATION_CARD,
  STYLE_LOCATION_WHITE_CARD,
  STYLE_NAME_CARD_REPORT,
  STYLE_PARENT_JOB_LIST,
  STYLE_PARENT_WHITE_CARD,
  STYLE_TITLE,
  STYLE_TITLE_ADDRESS,
  STYLE_TITLE_HEAD_CARD,
  STYLE_TYPE_CARD,
  STYLE_VALUE_WHITE_CARD,
  STYLE_WHITE_CARD,
} from "../../utils/useStyles";
import { convertCarType } from "../../utils/util.main";

function WorkCard({ row, onClick }) {
  const navigate = useNavigate();

  const onClickRoute = () => {
    navigate(`/job/${row.request_id}`);
  };

  return (
    <div>
      <div onClick={onClick} className="">
        <div className={STYLE_HEAD_CARD}>
          <div className={STYLE_TITLE_HEAD_CARD}>
            <div className=" mx-1">
              {row?.name} {row?.lastname}
            </div>
            {/* <div>
              {row?.approve ? (
                <ICON_APPROVE className=" w-10 text-mhs-green my-auto " />
              ) : row?.loan_status === "อนุมัติ" ? (
                <div className="text-white">ยังไม่ได้นัดหมาย</div>
              ) : (
                <div className="text-white">{row?.loan_status}</div>
              )}
            </div> */}
          </div>
          <div className={STYLE_CODE_CARD}> รหัส : {row?.request_id}</div>
        </div>
        <div className={`${STYLE_BG_CARD} cardshadow`}>
          <div className=" flex justify-between">
            <div className={STYLE_DATE_REPORT}>
              {format(new Date(row?.appoint_date), "dd MMMM yyyy", {
                locale: th,
              })}{" "}
              / {row?.appoint_start_time} - {row?.appoint_end_time}
            </div>
            <div className={STYLE_NAME_CARD_REPORT}> {row?.plate}</div>
          </div>

          <div className={STYLE_TITLE_ADDRESS}> ที่อยู่ปัจจุบัน</div>
          <div className={STYLE_LOCATION_CARD}>
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
            {row?.officialAddressCode +
              " " +
              row.officialAddressDesc +
              " " +
              row.currentAddress}
          </div>
          <div className={STYLE_TITLE_ADDRESS}> ที่อยู่ที่ลูกค้าขอยื่น</div>
          <div className={STYLE_LOCATION_CARD}>
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
            {row?.latitude + " " + row?.longitude}
          </div>
          <div className={STYLE_TYPE_CARD}>
            {" "}
            ประเภทรถ ({convertCarType(row?.type)})
          </div>
          <div className={STLE_TITLE_TYPE_CARD}>
            {" "}
            {row?.brand} {row?.model} {row?.year}
          </div>
          <div className={STLE_TITLE_TYPE_CARD}> {row?.subModel}</div>
          <div className={STYLE_BORDER_CARD}> </div>
        </div>
      </div>
    </div>
  );
}

export default WorkCard;
