///ATRIBUTES///

//TITLE
export const STYLE_HEAD_TITLE = "text-mhs-blue-200 font-extrabold text-3xl";

//SIZE-WIDTH
export const STYLE_WINDOWS =
  " w-full h-full max-w-5xl mx-auto font-NotoSansThai";
export const STYLE_HEADER_PADDING = "flex justify-between items-center mb-12";
//NAVBAR
export const STYLE_SELECTED_MENU = "underline-offset-4 underline font-bold";
export const STYLE_UNSELECT_MENU = "underline-none font-normal";

//SEARCHBAR
export const STYLE_OPTION = "p-2 text-mhs-gray-300 text-xs";
export const STYLE_OPTIONS =
  "bg-white border border-gray-200 absolute top-4 mt-10 text-gray-700 text-sm text-center";
export const STYLE_VALUE_SEARCH =
  "focus:ring-0 focus:border-mhs-gray-200 rounded-full rounded-r-none  border border-mhs-gray-200 bg-mhs-gray-50 p-2 text-mhs-gray-300 text-sm h-10  flex items-center px-3 gap-2  ";
export const STYLE_SEARCH_INPUT =
  "border-l-transparent border-r-transparent border border-mhs-gray-200  bg-mhs-gray-50 p-2 text-mhs-gray-300 text-sm  focus:outline-none h-10 w-full placeholder:text-slate-400";
export const STYLE_ICON_SEARCH =
  "rounded-full rounded-l-none bg-mhs-blue-300 text-white p-3 h-10 flex items-center justify-center w-full";
export const STYLE_PARENT_SEARCHBAR = "w-full grid grid-cols-8 ";

//CARD
export const STYLE_HEAD_CARD =
  "cardtop bg-mhs-blue-100 px-8 p-4 w-full flex justify-between items-center";
export const STYLE_BOTTOM_CARD =
  "cardbottom bg-mhs-gray-50 px-8 p-4 w-full flex justify-center gap-4 font-bold text-mhs-blue-300 text-lg";
export const STYLE_TITLE_HEAD_CARD =
  "text-white font-semibold font-NotoSansThai text-lg h-10 flex items-center gap-2 ";
export const STYLE_CODE_CARD = "text-white text-sm font-light";
export const STYLE_CODE_REPORT_CARD = "text-mhs-300 text-sm font-light";
export const STYLE_BG_CARD = "bg-white p-8 cardbottom  space-y-2";
export const STYLE_TITLE_ADDRESS = "text-mhs-gray-300 text-xs";
export const STYLE_LOCATION_CARD =
  "flex gap-1 text-mhs-buttonblue-100 font-medium text-sm";
export const STYLE_ICON_LOCATION_CARD = " w-4";
export const STYLE_TYPE_CARD = "text-mhs-gray-300 text-xs pt-4";
export const STLE_TITLE_TYPE_CARD = "text-mhs-blue-300 font-medium text-sm ";
export const STYLE_BORDER_CARD = "border-b border-mhs-gray-100 w-full mt-4";
export const STYLE_WHITE_CARD =
  "bg-white p-8 cardshadow roundeadcard space-y-4";
export const STYLE_PARENT_WHITE_CARD = " flex gap-2 items-center w-full";
export const STYLE_TITLE = "text-mhs-blue-300 font-bold text-lg ";
export const STYLE_VALUE_WHITE_CARD = "text-mhs-gray-300 ";
export const STYLE_LOCATION_WHITE_CARD =
  "text-mhs-buttonblue-100 font-medium underline underline-offset-4 cursor-pointer";
export const STYLE_WHITE_CARD_JOB =
  "bg-white p-8 cardshadow rounded-2xl  grid grid-cols-10 ";
export const STYLE_WRAPPER_CARD_REPORT =
  "flex justify-between font-semibold text-xl";
export const STYLE_NAME_CARD_REPORT = "font-bold text-mhs-blue-300";
export const STYLE_CODE_CARD_REPORT =
  "text-mhs-gray-300 text-sm font-normal text-right";
export const STYLE_PLATE_REPORT = " text-mhs-buttonblue-100 font-bold  ";
export const STYLE_DATE_REPORT = "text-mhs-blue-200 font-semibold text-lg pb-4";

//BUTTON
export const STYLE_BUTTON_LIGHTBLUE =
  "bg-mhs-buttonblue-100 text-white font-bold rounded-full p-3 w-full text-lg flex justify-center items-center shadow-md shadow-gray-400";
export const STYLE_BUTTON_BLUE =
  "bg-mhs-buttonblue-200 text-white font-bold rounded-full p-3 w-full text-lg flex justify-center items-center shadow-md shadow-gray-400";
export const STYLE_BUTTON_DISABLE =
  "bg-mhs-gray-disable text-white font-bold rounded-full p-3 w-full text-lg flex justify-center items-center shadow-md shadow-gray-400 cursor-not-allowed";
export const STLYE_CLEAR_BUTTON =
  " text-mhs-blue-300 text-lg underline underline-offset-4 font-bold";
export const STYLE_ICON_GOBACK = "text-mhs-blue-200 w-11 cursor-pointer";
export const STYLE_TITLE_GOBACK = "text-mhs-gray-300 text-xs pl-5 -mt-2 ";
export const STYLE_BUTTON_BLUE_NEXT =
  "rounded-full w-44 mx-auto p-4 text-white text-center flex justify-center items-center bg-mhs-blue-100 text-2xl font-semibold shadow-md shadow-gray-400";

///PAGES///

//LOGIN
export const STYLE_BG_LOGIN =
  "bg-mhs-bg w-full h-full py-10 flex flex-col justify-center";
export const STYLE_LOGO_LOGIN = "mx-auto w-52";
export const STYLE_TEXT_LOGIN =
  "font-NotoSansThai font-medium text-3xl text-center mt-6 text-mhs-buttonblue";
export const STYLE_BG_INPUT =
  "bg-mhs-bg h-10 p-4 w-80 mx-auto flex flex-col justify-center rounded-full border border-mhs-gray-100";
export const STYLE_INPUT =
  " h-9 bg-transparent focus:outline-none  placeholder:text-mhs-gray-200 text-gray-500 w-full ";
export const STYLE_INPUT_PASSWORD =
  " h-9 bg-transparent focus:outline-none  placeholder:text-mhs-gray-200 text-gray-500 w-full border-none px-0 focus:ring-0 ";
export const SYLE_ICON_EYES_LOGIN = "w-6 text-mhs-gray-200 cursor-pointer";
export const STYLE_FORGET_PASSWORD =
  "text-mhs-buttonblue-100 text-xs font-medium px-4 cursor-pointer";

//JOB
export const STYLE_PARENT_JOB_LIST = "grid grid-cols-1 gap-12";
export const STYLE_JOB_CODE = "text-mhs-buttonblue-100 text-xl font-medium";
export const STYLE_PARENT_BUTTON_JOB_INFO =
  " grid grid-cols-2 gap-4 justify-center items-center my-12 w-2/3 mx-auto";
export const STYLE_LABEL_JOB = "text-mhs-blue-300 font-bold ";
export const STYLE_WRAPPER_JOB =
  "pt-10 col-span-full flex justify-center gap-6";
export const STYLE_TITLE_STEPPERS = "text-mhs-gray-300 text-xs mt-3";
export const STYLE_ICON_STEPPERS = "w-10";
export const STYLE_ICON_STEPPERS_INACTIVE =
  " w-10 h-10 border-4 border-mhs-gray-200 rounded-full text-center text-xl pt-0.5 font-bold";

//WORK
export const STYLE_HEAD_TITLE_WORK = "text-mhs-blue-300 font-bold text-2xl";
export const STYLE_UPLOAD_IMAGE =
  "border-2 rounded-2xl border-dashed border-mhs-gray-300 p-6 flex flex-col space-y-4 items-center";
export const STYLE_ICON_UPLOAD = "w-24 mx-auto";
export const STYLE_SUBLABEL_UPLOAD =
  "text-mhs-gray-300 font-Inter text-center mt-2";
export const STYLE_OR_UPLOAD = "text-mhs-gray-300 text-center pt-2 flex items-center mb-6 relative";
//REPORT
export const STYLE_DATE_PICKER =
  "focus:ring-0 focus:border-mhs-gray-200 bg-mhs-bg rounded-xl border border-mhs-gray-200 text-mhs-gray-300 p-2 focus:outline-none font-medium mt-2   ";
export const STYLE_LABEL_DATE_PICKER = "text-mhs-gray-300 font-medium text-lg";
export const STYLE_PARENT_WHITECARD_DATE_PICKER =
  "grid grid-cols-5 items-center gap-4 space-y-0 my-6";
export const STYLE_STATUS_REPORT_APPOINT = " text-mhs-yellow font-medium";
export const STYLE_STATUS_REPORT_DISAPPOINT = " text-mhs-orange font-medium";
export const STYLE_STATUS_CANCEL = " text-red-500 font-medium";

export const STYLE_STATUS_REPORT_DONE = " text-mhs-buttonblue-100 font-medium";
export const STYLE_STATUS_REPORT_COMPLETE = " text-mhs-green font-medium";
export const STYLE_STATUS_REPORT_NOT_COMPLETE = " text-mhs-red font-medium";
export const STYLE_STATUS_REPORT_CONFIRM = "text-mhs-yellow font-medium";
export const STYLE_STATUS_REPORT_REJECT = "text-mhs-red font-medium";

//FORM
export const STYLE_PARENT_FORM =
  "flex justify-between border-b border-mhs-gray-disable p-4 my-2";
export const STYLE_TITLE_FROM = "text-mhs-blue-300 font-medium text-xl px-12";
export const STYLE_ICON_FORM = "w-8 text-mhs-buttonblue-100";
