import React, { useEffect, useState, Profiler } from "react";
import { Container } from "../../components/layouts/container";
import Steppers from "../../components/steppers/steppers";
import StepOne from "./render/StepOne";
import StepTwo from "./render/StepTwo";
import StepThree from "./render/StepThree";
import StepFour from "./render/StepFour";
import StepFive from "./render/StepFive";
import StepSix from "./render/StepSix";
import {
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
  STYLE_ICON_GOBACK,
  STYLE_JOB_CODE,
} from "../../utils/useStyles";
import { GoBack } from "../../components/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Camera from "../../components/camera/camera";
import {
  FunctionGetBase64FromUrl,
  FunctionGetFileFromBase64,
  FunctionGetFileFromBlob,
  FunctionGetUrlFromFile,
} from "radium-omnibus";
import useQuery from "../../hooks/useQuery";
import LoadingPage from "../../components/loading/loadingPage";
import { API, Storage } from "aws-amplify";
import { formFileDto } from "./dto/dto.main";
import { ICON_ARROW_LEFT } from "../../utils/useIcons";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Modal,
  Button,
  DialogFooter,
  Radio,
} from "@celestialcode-react/components";
import StepSeven from "./render/StepSeven";
import { onScrollToTop } from "../../utils/util.main";
import _ from "lodash";
import Resizer from "react-image-file-resizer";

export const WorkContext = React.createContext();

const WorkProvider = ({ children, leasing }) => {
  return (
    <WorkContext.Provider
      value={{
        leasing,
      }}
    >
      {children}
    </WorkContext.Provider>
  );
};

function WorkMain() {
  const [form, setForm] = useState(formFileDto);
  const [checkSubmit, setCheckSubmit] = useState(false);
  const [step, setStep] = useState(0);
  const [turnOnCamera, setTurnOnCamera] = useState("");
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const [openModalLocation, setOpenModalLocation] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [radioTypeCamera, setRadioTypeCamera] = useState("front");
  const [valueTypeCamera, setValueTypeCamera] = useState("");
  const [stepName, setStepName] = useState("ภาพถ่ายยืนยันตัวตน FCR และลูกค้า");

  const params = useParams();

  const navigate = useNavigate();

  const { data, loading, refetch, dataOther } = useQuery(
    `/leasing/fcr/${params?.request_id}`,
    {},
    [params],
    {
      fetchOthers: [
        {
          pathName: `/leasing/interest?name=SWITCH_FCR_VAL`,
        },
      ],
    }
  );

  const {
    data: dataRequest,
    loading: loadingRequest,
    error: errorRequest,
  } = useQuery(`/leasing/request/${params?.request_id}`, {}, [params]);

  useEffect(() => {
    if (turnOnCamera) {
      setOpenModalCamera(true);
    } else {
      setOpenModalCamera(false);
    }
  }, [turnOnCamera]);

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "prompt" || result.state === "denied") {
        setOpenModalLocation(true);
      }
    });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async function (position) {
      if (position) {
        await API.put(
          "MoneyHubAdmin",
          `/leasing/workLocation/${params?.request_id}`,
          {
            body: {
              work_latitude: position.coords.latitude,
              work_longitude: position.coords.longitude,
            },
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    const sessionFcr = localStorage.getItem(`fcr_form_${params?.request_id}`);
    if (sessionFcr) {
      const parseForm = JSON.parse(sessionFcr);
      let { form, step, lastestName } = parseForm;
      const formKeys = Object.keys(form);

      for (const formKey of formKeys) {
        if (form[formKey]) {
          for (let index = 0; index < form[formKey].length; index++) {
            if (form[formKey][index]) {
              form[formKey][index] = FunctionGetFileFromBase64(
                form[formKey][index]
              );
            } else {
              form[formKey][index] = "";
            }
          }
        }
      }
      setForm(form);
      setStep(step);
    }
  }, []);

  useEffect(() => {
    if (data) {
      prePareData();
    }
  }, [data]);

  const prePareData = async () => {
    const formLoaded = { ...data.fcr };

    delete formLoaded?.request_id;
    delete formLoaded?.updatedAt;
    delete formLoaded?.createdAt;

    const fcrKeys = Object?.keys(data?.fcr);
    for (const fcrKey of fcrKeys) {
      if (formLoaded[fcrKey]) {
        if (!formLoaded[fcrKey]) {
          formLoaded[fcrKey] = [];
        }
        let fileIndex = 0;
        for (const img of formLoaded[fcrKey]) {
          if (img) {
            const imgLoaded = await Storage.get(img);
            formLoaded[fcrKey][fileIndex] = imgLoaded;
          }
          fileIndex++;
        }
      }
    }
    setForm(formLoaded);
  };

  const onClickRoute = () => {
    navigate(`/`);
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    // return new Promise((resolve) => {
    //   Resizer.imageFileResizer(
    //     file,
    //     400,
    //     400,
    //     "PNG",
    //     100,
    //     0,
    //     (uri) => {
    //       resolve(uri);
    //     },
    //     "base64"
    //   );
    // });
  };

  const getIsMultiple = (name) => {
    return [
      "fcr_personal_other_imgs",
      "fcr_contact_other_imgs",
      "fcr_car_other_imgs",
      "fcr_slip_imgs",
      "fcr_account_statement_imgs",
      "fcr_bank_book_imgs",
      "fcr_guarantor_home_imgs",
    ].includes(name);
  };

  const onCallbackCamera = async (src) => {
    const [name, position] = turnOnCamera?.split("-");
    const isMultipleForm = getIsMultiple(name);
    let temp = { ...form };
    if (!temp[name]) {
      temp[name] = [""];
    }

    if (isMultipleForm) {
      temp[name] = [...temp[name], FunctionGetFileFromBase64(src)];
      temp[name] = temp[name].filter((item) => item);
    } else {
      temp[name][position] = FunctionGetFileFromBase64(src);
    }
    setForm(temp);
    if (!getIsWork()) {
      let sessionForm = _.cloneDeep(temp);

      const formKeys = Object.keys(sessionForm);

      for (const formKey of formKeys) {
        if (sessionForm[formKey]) {
          for (let index = 0; index < sessionForm[formKey].length; index++) {
            if (sessionForm[formKey][index]) {
              sessionForm[formKey][index] = await toBase64(
                sessionForm[name][index]
              );
              // await FunctionGetBase64FromUrl(
              //   FunctionGetUrlFromFile(sessionForm[name][index])
              // );
            } else {
              sessionForm[formKey][index] = "";
            }
          }
        }
      }

      localStorage.setItem(
        `fcr_form_${params.request_id}`,
        JSON.stringify({ form: sessionForm, step, lastestName: name })
      );
    }
  };

  const onChangeFile =
    (isMultiple = false) =>
    (e) => {
      setForm((prev) => {
        return {
          ...prev,
          [e.target.name]: isMultiple
            ? [...e.target.files]
            : [e.target.files[0]],
        };
      });
    };

  const getIsWork = () => {
    return ["งานสำเร็จแล้ว"].includes(dataRequest?.leasing?.loan_status);
    // return data?.fcr !== null;
  };

  const onClickLocation = () => {
    setLoadingLocation(true);
    navigator.geolocation.getCurrentPosition(async function (position) {
      setOpenModalLocation(false);
      setLoadingLocation(false);
      await API.put(`/leasing/work/${params?.request_id}`, {
        body: {
          work_latitude: position?.coords?.latitude,
          work_longitude: position.coords.longitude,
        },
      });
    });
  };

  const onCloseCamera = () => {
    setTurnOnCamera("");
    setValueTypeCamera("");
    setRadioTypeCamera("front");
    setOpenModalCamera(false);
    const [name] = turnOnCamera?.split("-");
    onScrollToTop(`camera_${name}`);
  };

  const callbackFilter = (item) => {
    return item;
  };

  const onChangeRadio = (e) => {
    setRadioTypeCamera(e.target.value);
  };

  const onClickValueTypeCamera = () => {
    setValueTypeCamera(radioTypeCamera);
    setRadioTypeCamera("front");
    setOpenModalCamera(false);
  };

  const onClickGoBack = () => {
    if (step > 0) {
      return setStep(step - 1);
    }
    return navigate(-1);
  };

  if (loading || loadingRequest) {
    return <LoadingPage />;
  }

  return (
    <React.Fragment>
      {/* <input type="file" accept="image/*" capture="user"/> */}
      {/* <div>{JSON.stringify(tempFile)}</div> */}
      <WorkProvider leasing={dataRequest?.leasing}>
        <Modal
          open={openModalLocation}
          panel={
            <Dialog className="w-72">
              <DialogContent className="">
                เพื่อประสบการณ์ที่ดียิ่งขึ้น ให้เปิดตำแหน่งของอุปกรณ์
                ซึ่งใช้บริการตำแหน่ง Google
              </DialogContent>
              <DialogFooter className="bg-transparent border-t-0">
                <Button
                  loading={loadingLocation}
                  label="ตกลง"
                  variant="ghost"
                  onClick={onClickLocation}
                />
              </DialogFooter>
            </Dialog>
          }
        />
        <Modal
          open={openModalCamera}
          panel={
            <Dialog className="w-72">
              <DialogContent className="flex items-center space-x-2">
                <Radio
                  onChange={onChangeRadio}
                  name="type_camera"
                  value="front"
                  label="กล้องหน้า"
                  checked={radioTypeCamera === "front"}
                />
                <Radio
                  onChange={onChangeRadio}
                  name="type_camera"
                  value="rear"
                  label="กล้องหลัง"
                  checked={radioTypeCamera === "rear"}
                />
              </DialogContent>
              <DialogFooter className="bg-transparent border-t-0 -mt-2">
                <Button
                  label="ตกลง"
                  variant="ghost"
                  onClick={onClickValueTypeCamera}
                />
              </DialogFooter>
            </Dialog>
          }
        />
        {turnOnCamera && !openModalCamera && (
          <Camera
            valueTypeCamera={valueTypeCamera}
            callback={onCallbackCamera}
            onClose={onCloseCamera}
          />
        )}
        <div id="work_main" hidden={turnOnCamera}>
          <Container>
            <React.Fragment>
              <div className={STYLE_HEADER_PADDING}>
                <div className=" flex items-center gap-7 justify-between">
                  <GoBack cb={onClickGoBack} className={STYLE_ICON_GOBACK} />
                  <div className={STYLE_HEAD_TITLE}>{stepName}</div>
                </div>
                <div className={STYLE_JOB_CODE}>
                  {" "}
                  Job No. {params.request_id}{" "}
                </div>
              </div>
              <Steppers
                step={step}
                setStep={setStep}
                setStepName={setStepName}
                steppers={[
                  {
                    header: "1",
                    describe: "ภาพถ่ายยืนยันตัวตน FCR และลูกค้า",
                    isError:
                      (!form?.fcr_comfirm_customer_imgs.filter(callbackFilter)
                        ?.length ||
                        !form?.fcr_comfirm_sign_imgs.filter(callbackFilter)
                          ?.length) &&
                      checkSubmit,
                    isSuccess:
                      form?.fcr_comfirm_customer_imgs?.filter(callbackFilter)
                        ?.length > 0 &&
                      form?.fcr_comfirm_sign_imgs?.filter(callbackFilter)
                        ?.length > 0,
                    render: () => (
                      <StepOne
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "2",
                    describe: "ภาพถ่ายรถ",
                    isError:
                      (!form.fcr_car_front_imgs.filter(callbackFilter)
                        ?.length ||
                        !form.fcr_car_back_imgs.filter(callbackFilter)
                          ?.length ||
                        !form.fcr_car_left_imgs.filter(callbackFilter)
                          ?.length ||
                        !form.fcr_car_right_imgs.filter(callbackFilter)
                          ?.length ||
                        !form.fcr_car_hood_imgs.filter(callbackFilter)
                          ?.length ||
                        !form.fcr_car_back_skirt_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_console_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_mile_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_back_seat_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_tank_number_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_book_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_front_book_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_front_book_sixteen_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_car_front_book_eighteen_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_car_insurance_policy_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_car_tax_imgs.filter(callbackFilter)
                          ?.length ||
                        !form?.fcr_car_inspection_report_imgs.filter(
                          callbackFilter
                        )?.length) &&
                      checkSubmit,
                    isSuccess:
                      form.fcr_car_front_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_back_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_left_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_right_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_hood_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_back_skirt_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_car_console_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_mile_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_back_seat_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_car_tank_number_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_car_book_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_front_book_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_car_front_book_sixteen_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form.fcr_car_front_book_eighteen_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form.fcr_car_insurance_policy_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_car_tax_imgs.filter(callbackFilter)?.length >
                        0 &&
                      form.fcr_car_inspection_report_imgs.filter(callbackFilter)
                        ?.length > 0,
                    render: () => (
                      <StepTwo
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "3",
                    describe: "ภาพถ่ายใบสมัครและชุดสัญญา",
                    // isError: formError?.stepThreeError,
                    isError:
                      (!form?.fcr_contact_request_loan_imgs.filter(
                        callbackFilter
                      )?.length ||
                        !form?.fcr_contact_consent_book_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_contact_consent_book_pdpa_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_contact_transfer_imgs.filter(callbackFilter)
                          ?.length ||
                        // !form?.fcr_contact_power_of_attorney_imgs.filter(
                        //   callbackFilter
                        // )?.length ||
                        // !form?.fcr_contact_insurance_transfer_imgs.filter(
                        //   callbackFilter
                        // )?.length ||
                        !form?.fcr_contact_car_registration_loan_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form?.fcr_contact_save_end_imgs.filter(callbackFilter)
                          ?.length) &&
                      checkSubmit,
                    isSuccess:
                      form?.fcr_contact_request_loan_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form?.fcr_contact_consent_book_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form?.fcr_contact_consent_book_pdpa_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form?.fcr_contact_transfer_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form?.fcr_contact_power_of_attorney_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form?.fcr_contact_insurance_transfer_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form?.fcr_contact_car_registration_loan_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form?.fcr_contact_save_end_imgs.filter(callbackFilter)
                        ?.length > 0,
                    render: () => (
                      <StepThree
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "4",
                    describe: "ภาพถ่ายเเอกสารส่วนตัว",
                    isError:
                      (!form.fcr_personal_id_card_imgs.filter(callbackFilter)
                        ?.length ||
                        // !form.fcr_personal_home_register_imgs.filter(
                        //   callbackFilter
                        // )?.length ||
                        // !form.fcr_personal_other_imgs.filter(callbackFilter)
                        //   ?.length ||
                        !form.fcr_personal_current_address_imgs.filter(
                          callbackFilter
                        )?.length ||
                        !form.fcr_personal_home_address_imgs.filter(
                          callbackFilter
                        )?.length) &&
                      checkSubmit,
                    isSuccess:
                      form.fcr_personal_id_card_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_personal_home_register_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form.fcr_personal_other_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_personal_current_address_imgs.filter(
                        callbackFilter
                      )?.length > 0 &&
                      form.fcr_personal_home_address_imgs.filter(callbackFilter)
                        ?.length > 0,
                    render: () => (
                      <StepFour
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "5",
                    describe: "ภาพถ่ายเอกสารผู้ค้ำประกัน",
                    isError: false,
                    // (!form.fcr_guarantor_id_card_imgs.filter(callbackFilter)
                    //   ?.length ||
                    //   !form.fcr_guarantor_home_imgs.filter(callbackFilter)
                    //     ?.length ||
                    //   !form.fcr_guarantor_id_card_real_imgs.filter(
                    //     callbackFilter
                    //   )?.length) &&
                    // checkSubmit,
                    isSuccess:
                      form.fcr_guarantor_id_card_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_guarantor_home_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_guarantor_id_card_real_imgs.filter(
                        callbackFilter
                      )?.length > 0,
                    render: () => (
                      <StepFive
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "6",
                    describe: "เอกสารทางการเงินของลูกค้า",
                    isError:
                      !form.fcr_slip_imgs.filter(callbackFilter)?.length &&
                      !form.fcr_account_statement_imgs.filter(callbackFilter)
                        ?.length &&
                      !form.fcr_bank_book_imgs.filter(callbackFilter)?.length &&
                      checkSubmit,
                    isSuccess:
                      form.fcr_slip_imgs.filter(callbackFilter)?.length > 0 &&
                      form.fcr_account_statement_imgs.filter(callbackFilter)
                        ?.length > 0 &&
                      form.fcr_bank_book_imgs.filter(callbackFilter)?.length >
                        0,
                    render: () => (
                      <StepSeven
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        refetch={refetch}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                  {
                    header: "7",
                    describe: "ภาพถ่าย Checklist",
                    isError:
                      !form.fcr_personal_checklist_imgs.filter(callbackFilter)
                        ?.length && checkSubmit,
                    isSuccess:
                      form.fcr_personal_checklist_imgs.filter(callbackFilter)
                        ?.length > 0,
                    render: () => (
                      <StepSix
                        dataOther={dataOther}
                        step={step}
                        setStep={setStep}
                        setForm={setForm}
                        form={form}
                        setTurnOnCamera={setTurnOnCamera}
                        onChangeFile={onChangeFile}
                        setCheckSubmit={setCheckSubmit}
                        refetch={refetch}
                        isWork={getIsWork()}
                      />
                    ),
                  },
                ]}
              />
            </React.Fragment>
          </Container>
        </div>
      </WorkProvider>
    </React.Fragment>
  );
}

export default WorkMain;
