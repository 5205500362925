import "./App.css";
import RoutesMain from "./router/route.main";
import { Auth, Storage, Amplify } from "aws-amplify";
import { useEffect } from "react";
import store from "./redux/store";
import { initializeApp } from "./redux/initializeAppSlice";
import "antd/dist/antd.css";


Storage.configure({
  bucket: "imagestorage232737-production", //REQUIRED -  Amazon S3 bucket name
  region: "ap-southeast-1", //OPTIONAL -  Amazon service region
  identityPoolId: "ap-southeast-1:f276e9e7-023b-4ab1-8bef-e6c9da6a3ace",
});




Amplify.configure({
  API: {
    endpoints: [
      {
        name: "MoneyHubAdmin",
        endpoint: "https://api.moneyhubservice.com",
      },
    ],
  },
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "ap-southeast-1:f276e9e7-023b-4ab1-8bef-e6c9da6a3ace",
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-1",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "ap-southeast-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-1_E1FPtKy2R",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "3f9imvr0pqbg2kl54ceid1f4ob",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: "imagestorage232737-production", //REQUIRED -  Amazon S3 bucket name
      region: "ap-southeast-1", //OPTIONAL -  Amazon service region
    },
  },
});

function App() {

  useEffect(() => {
    store.dispatch(initializeApp(true))
  }, [])

  return (
    <div className="">
      <RoutesMain />
    </div>
  );
}

export default App;
