import React from "react";
import LoginHead from "./render/Login.Head";
import LoginUser from "./render/Login.User";

function LoginMain() {
  return (
    <div className="min-h-screen w-full h-full grid grid-rows-2">
      <LoginHead />
      <LoginUser />
    </div>
  );
}

export default LoginMain;
