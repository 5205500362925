import classNames from "classnames";
import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfEDD = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  const rows = [
    [
      "",
      "หลังจากการตรวจสอบรายชื่อลูกค้า (Name Screening) ในฐานข้อมูลบริษัท พบว่าลูกค้าติดอยู่ในรายชื่อ AMLO List หรือไม่",
      "",
      "ปฎิเสธการรับลูกค้ารายนี้",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "ลูกค้ามีสัญชาติ / แหล่งที่มาของรายได้อื่นๆ จากประเทศอิหร่าน / เกาหลีเหนือหรือไม่",
      "",
      "ปฎิเสธการรับลูกค้ารายนี้",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "หลังจากการตรวจสอบรายชื่อลูกค้า (Name Screening) ในฐานข้อมูลบริษัท พบว่าลูกค้าติดอยู่ในรายชื่อ PEPs List หรือมีความสัมพันธ์เกี่ยวข้องกับนักการเมืองหรือไม่",
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "ลูกค้ามีสัญชาติอยู่ในกลุ่มที่เป็นประเทศที่มีความเสี่ยงสูงด้านการฟอกเงินหรือไม่",
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "ลูกค้ามีภูมิลำเนาอยู่ในกลุ่มที่เป็นประเทศที่มีความเสี่ยงสูงด้านการฟอกเงินหรือไม่",
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "ลูกค้ามีแหล่งที่มาของรายได้อื่นๆ จากกลุ่มที่เป็นประเทศที่มีความเสี่ยงสูงด้านการฟอกเงินหรือไม่",
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาตอบข้อถัดไป",
    ],
    [
      "",
      "ลูกค้าเป็นเจ้าของธุรกิจ หรือ ประกอบอาชีพอิสระใช่หรือไม่",
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาระบุความเสี่ยงของลูกค้าด้านล่าง",
    ],
    [
      "",
      [
        `ลูกค้าตามข้อ 7 ประกอบธุรกิจที่จัดอยู่ในกลุ่มอาชีพที่มีความเสี่ยงสูง 9 อาชีพดังต่อไปนี้หรือไม่`,
        "- ธุรกิจค้าอาวุธยุทธภัณฑ์",
        "- ธุรกิจคาสิโนหรือการพนัน",
        "- ธุรกิจค้าอัญมณี / เพชรพลอย / ทองคำ",
        "- ธุรกิจจัดหางาน (รับคนเข้าหรือส่งคนไปทำงานต่างประเทศ)",
        "- ธุรกิจทัวร์",
        "- ธุรกิจสถานบริการ",
        "- ธุรกิจรับแลกเปลี่ยนเงินตราต่างประเทศ",
        "- ธุรกิจค้าขายของเก่า",
        "- ธุรกิจบริการรับ-โอนเงินทั้งในและต่างประเทศ",
      ],
      "",
      "กรุณาทำ EDD Form",
      "",
      "กรุณาระบุความเสี่ยงของลูกค้าด้านล่าง",
    ],
  ];

  //   `ลูกค้าตามข้อ 7 ประกอบธุรกิจที่จัดอยู่ในกลุ่มอาชีพที่มีความเสี่ยงสูง 9 อาชีพดังต่อไปนี้หรือไม่
  //   - ธุรกิจค้าอาวุธยุทธภัณฑ์
  //   - ธุรกิจคาสิโนหรือการพนัน
  //   - ธุรกิจค้าอัญมณี / เพชรพลอย / ทองคำ
  //   - ธุรกิจจัดหางาน (รับคนเข้าหรือส่งคนไปทำงานต่างประเทศ)
  //   - ธุรกิจทัวร์
  //   - ธุรกิจสถานบริการ
  //   - ธุรกิจรับแลกเปลี่ยนเงินตราต่างประเทศ
  //   - ธุรกิจค้าขายของเก่า
  //   - ธุรกิจบริการรับ-โอนเงินทั้งในและต่างประเทศ`,

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        {/* Header */}
        <div className="flex items-center justify-center mt-4 relative text-xl font-bold">
          <div className="">
            Questionnaire of Enhanced Due Diligence (EDD) for Individual
            customer
          </div>
          <div className=" absolute right-0">
            <Logo />
          </div>
        </div>

        {/* Content */}
        <div className="pt-6 pb-1 flex items-center justify-between">
          <div>
            ชื่อลูกค้า (Customer name) {data?.article} {data?.name} {data?.lastname}
          </div>
          <div>Date:…………………………</div>
        </div>

        {/* Table */}
        <table className="border-collapse border border-gray-700 w-full pt-4">
          <thead className="">
            <tr>
              <th
                className="border-r border-gray-700 relative h-6 w-36"
                colSpan={2}
              >
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  รายการ
                </div>
              </th>
              <th
                className="border-r border-gray-700 relative h-6 w-4"
                colSpan={2}
              >
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  ใช่
                </div>
              </th>
              <th
                className="border-r border-gray-700 relative h-6 w-4"
                colSpan={2}
              >
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  ไม่ใช่
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((col, index) => {
              return (
                <tr key={index}>
                  {col.map((item, itemIndex) => {
                    if (itemIndex === 0) {
                      count++;
                    }

                    if (itemIndex === 0 || itemIndex === 2) {
                      return (
                        <td
                          key={itemIndex}
                          className="border-t border-r border-gray-700 relative h-6 w-2"
                        >
                          {itemIndex === 0 && (
                            <div className="pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left">
                              {count}
                            </div>
                          )}
                        </td>
                      );
                    }

                    if (itemIndex === 3 || itemIndex === 5) {
                      return (
                        <td
                          key={itemIndex}
                          className="border-t border-r border-gray-700 relative h-6 w-10"
                        >
                          <div
                            className={classNames(
                              "pl-1 absolute top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left",
                              {
                                "top-3":
                                  item ===
                                  "กรุณาระบุความเสี่ยงของลูกค้าด้านล่าง",
                                "top-1":
                                  item !==
                                  "กรุณาระบุความเสี่ยงของลูกค้าด้านล่าง",
                              }
                            )}
                          >
                            {item}
                          </div>
                        </td>
                      );
                    }

                    if (itemIndex === 1) {
                      return (
                        <td
                          key={itemIndex}
                          className={classNames(
                            " relative border-t border-r border-gray-700",
                            {
                              "w-36": itemIndex === 1,
                              "h-44": rows.length - 1 === index,
                              "h-10": rows.length - 1 !== index,
                              // "border-t border-r border-gray-700": index < 9,
                              // "border-t border-r border-gray-300": index >= 9,
                            }
                          )}
                        >
                          <div
                            className={classNames(
                              "pl-1 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-left flex flex-col",
                              {
                                "top-1":
                                  typeof item !== "object" &&
                                  index !== 0 &&
                                  index !== 2,
                                "top-3": index === 0 || index === 2,
                                "top-17 pb-3 -mt-1": typeof item === "object",
                              }
                            )}
                          >
                            {typeof item === "object"
                              ? item?.map((item2, item2Index) => {
                                  return <div key={item2Index}>{item2}</div>;
                                })
                              : item}
                          </div>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className=" flex items-center">
          <div className="text-center mr-1">
            ระดับความเสี่ยงของลูกค้ารายนี้คือ
          </div>

          <div className="flex items-center ml-1">
            <CheckBox />
            <div className="ml-1 mr-2">ความเสี่ยงสูง (High Risk Customer)</div>
          </div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-2">ความเสี่ยงกลาง (Medium Risk Customer)</div>
          </div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1">ความเสี่ยงต่ำ (Low Risk Customer)</div>
          </div>
        </div>

        <div style={{ fontSize: "0.65rem" }}>
          *ตั้งแต่ข้อ 3-8 หากมีคำตอบ "ใช่(Yes)" ในข้อใดข้อหนึ่ง หรือมากกว่า 2
          ข้อ ลูกค้ารายดังกล่าว ถูกจัดอยู่ในกลุ่มความเสี่ยงระดับสูง (High Risk
          Customer) และต้องจัดทำแบบฟอร์ม
        </div>
        <div className="-mt-2" style={{ fontSize: "0.65rem" }}>
          การตรวจสอบข้อมูลลูกค้าอย่างเข้มข้น (Enhanced Due Diligence : EDD Form)
        </div>
        <div className="ml-auto w-1/3 pl-10">
            <div>ลงชื่อ……………………………………………..………………</div>
            <div className="text-center">( ....................................................... )</div>
            <div className="text-center">วันที่……………………………………………..………………</div>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfEDD;
