import { Block, Button, Row } from "@celestialcode-react/components";
import classNames from "classnames";
import numeral from "numeral";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useAsync from "../../hooks/useAsync";
import { ICON_APPROVE, ICON_LOCATION } from "../../utils/useIcons";
import {
  STLE_TITLE_TYPE_CARD,
  STYLE_BG_CARD,
  STYLE_BORDER_CARD,
  STYLE_CODE_CARD,
  STYLE_HEAD_CARD,
  STYLE_ICON_LOCATION_CARD,
  STYLE_LOCATION_CARD,
  STYLE_LOCATION_WHITE_CARD,
  STYLE_PARENT_JOB_LIST,
  STYLE_PARENT_WHITE_CARD,
  STYLE_TITLE,
  STYLE_TITLE_ADDRESS,
  STYLE_TITLE_HEAD_CARD,
  STYLE_TYPE_CARD,
  STYLE_VALUE_WHITE_CARD,
  STYLE_WHITE_CARD,
} from "../../utils/useStyles";
import { getStatusStyle, onClickRouteMapSearch } from "../../utils/util.main";
import { API } from "aws-amplify";
import { EmitterStatus } from "../../event/EmitterStatus";
import { format } from "date-fns";

export const Card = ({ row, onClick }) => {
  const location = useLocation();
  return (
    <div onClick={onClick} className=" cursor-pointer">
      <div className={STYLE_HEAD_CARD}>
        <div className={STYLE_TITLE_HEAD_CARD}>
          {row?.date}
          <div className=" mx-1">
            {row?.first_name} {row?.last_name}
          </div>
          <div>
            {row?.approve ? (
              <ICON_APPROVE className=" w-10 text-mhs-green my-auto " />
            ) : (
              // <ICON_APPROVE className=" w-10 text-mhs-green" />
              row?.status
            )}
          </div>
        </div>
        <div className={`${STYLE_CODE_CARD}`}>
          {" "}
          {location?.pathname === "/job" && `${row?.code}`}{" "}
        </div>
      </div>
      <div className={STYLE_BG_CARD}>
        <div className={STYLE_TITLE_ADDRESS}> {row?.title_address}</div>
        <div className={STYLE_LOCATION_CARD}>
          {row?.icon_1 ? (
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
          ) : (
            ""
          )}
          {row?.address_1}
        </div>
        <div className={STYLE_TITLE_ADDRESS}> {row?.title_address_2}</div>
        <div className={STYLE_LOCATION_CARD}>
          {row?.icon_2 ? (
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
          ) : (
            ""
          )}
          {row?.address_2}
        </div>
        <div className={STYLE_TYPE_CARD}> {row?.type}</div>
        <div className={STLE_TITLE_TYPE_CARD}> {row?.type_car_1}</div>
        <div className={STLE_TITLE_TYPE_CARD}> {row?.type_car_2}</div>
        <div className={STYLE_BORDER_CARD}> </div>
      </div>
    </div>
  );
};

export const WhiteCard = ({ row, refetch }) => {
  const { loading, onAsync: onClickCancel } = useAsync(async () => {
    await EmitterStatus("CONFIRM", {
      preConfirmCb: async () => {
        await API.put(
          "MoneyHubAdmin",
          `/leasing/requestStatus/${row?.request_id}`,
          {
            body: {
              new_loan_status: "ยกเลิก",
            },
          }
        );
        await EmitterStatus("SUCCESS");
        if (refetch) {
          refetch();
        }
      },
      title: "ยืนยันการยกเลิก?",
    });
  });

  return (
    <div className={STYLE_WHITE_CARD}>
      {/* <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>สถานะ : </div>
        <div
          className={classNames(
            STYLE_VALUE_WHITE_CARD,
            getStatusStyle(row?.loan_status),
            "!fort-bold"
          )}
        >
          {" "}
          {row?.loan_status}
        </div>
      </div> */}

      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>ชื่อ - นามสกุล : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>{row?.fullname}</div>
      </div>

      {/* <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>ประเภทสินเชื่อ : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>{row?.purpose}</div>
      </div> */}
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>เบอร์โทร : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>{row?.phone_number}</div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>ไลน์ไอดี : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>{row?.lineid}</div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>วันที่นัด : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>
          {row?.appointBy !== "-"
            ? format(new Date(row?.appoint_date), "dd/MM/yyyy")
            : "-"}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>ระยะเวลา : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>
          {row?.appointBy !== "-" ? `${row?.appoint_duration} นาที` : "-"}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>เวลาเริ่ม : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>
          {row?.appointBy !== "-" ? `${row?.appoint_start_time}` : "-"}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>เวลาสิ้นสุด : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>
          {row?.appointBy !== "-" ? `${row?.appoint_end_time}` : "-"}
        </div>
      </div>
      <div className={`${STYLE_PARENT_WHITE_CARD}`}>
        <div className={`${STYLE_TITLE} col-span-2`}>
          ที่อยู่ตามทะเบียนบ้าน :{" "}
        </div>
        <div className={`${STYLE_VALUE_WHITE_CARD}  col-span-5`}>
          {" "}
          {row?.addressCode + " " + row.addressDesc + " " + row.address}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>ที่อยู่ปัจจุบัน : </div>
        <div
          onClick={onClickRouteMapSearch(
            row?.officialAddressCode +
              " " +
              row.officialAddressDesc +
              " " +
              row.currentAddress
          )}
          className={STYLE_LOCATION_WHITE_CARD}
        >
          {" "}
          {row?.officialAddressCode +
            " " +
            row.officialAddressDesc +
            " " +
            row.currentAddress}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}> ที่อยู่ที่ลูกค้าขอยื่น : </div>
        <div
          onClick={onClickRouteMapSearch(row?.latitude + " " + row?.longitude)}
          className={`${STYLE_LOCATION_WHITE_CARD} col-span-3 `}
        >
          {row?.latitude + " " + row?.longitude}
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>รถที่ต้องการประเมิน :</div>
        <div className="col-span-8">
          <div className={STYLE_VALUE_WHITE_CARD}>
            {row?.brand} {row?.model} {row?.year}
          </div>
          <div className={STYLE_VALUE_WHITE_CARD}>{row?.subModel}</div>
        </div>
      </div>
      <div className={STYLE_PARENT_WHITE_CARD}>
        <div className={STYLE_TITLE}>วงเงินประเมินขั้นต้น (บาท) : </div>
        <div className={STYLE_VALUE_WHITE_CARD}>
          {numeral(Number(row?.amount)).format("0,000")}
        </div>
      </div>
      {row.loan_status === "ยกเลิกนัดหมาย" && (
        <Row className=" justify-end">
          <Button
            loading={loading}
            disabled={loading}
            onClick={onClickCancel}
            color="red"
            label="ยกเลิก"
          />
        </Row>
      )}
    </div>
  );
};
