import React from "react";
import THBText from "thai-baht-text";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";

const Header = ({ first, number }) => {
  return (
    <Flex between={true}>
      {first ? (
        <div className="border border-gray-700 w-40 px-2">
          เลขที่สัญญา {number}
        </div>
      ) : (
        <div></div>
      )}
      <div className={"flex flex-col"}>
        <Flex className={"flex justify-end"}>
          <Grid grid={1}>
            <Span className="text-end font-bold -mt-2" span={1}>
              มันนี่ ฮับ ลีสซิ่ง
            </Span>
            <Span className="font-bold mb-2" span={1}>
              MONEY HUB LEASING
            </Span>
          </Grid>
          <Logo />
        </Flex>
        <div className="text-right">
          บริษัท มันนี่ ฮับ เซอร์วิส จํากัด เลขที่ 1010 อาคารชนิ วัตร 3 ชั้น 12
        </div>
        <div className="text-right">
          ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
        </div>
        <div className="text-right">
          โทร. 02-8216710 Fax. 02-5139060 E-mail : support@moneyhubservice.com
        </div>
      </div>
    </Flex>
  );
};

const PdfTransferInsurance = ({ data, id, hidden }) => {
  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        <Header />

        <div className="text-lg font-bold text-center py-6 pb-6">
          หนังสือรับรองการเช่าซื้อ
        </div>
        <div className="flex justify-end">
          วันที่ ................... เดือน
          ...................................... พ.ศ. ...................
        </div>

        <div className="pt-6 ml-8">
          <div className="flex items-center">
            {" "}
            <div className="mr-2">เรียน</div>
            <div className="text-left">นายทะเบียนยานพาหนะ กรมการขนส่งทางบก</div>
          </div>
          <div className="flex items-center">
            {" "}
            <div className="mr-6"></div>
            <div className="text-left">
              โดยหนังสือฉบับนี้ บริษัท มันนี่ ฮับ เซอร์วิส จำกัด ("บริษัท")
              ขอรับรองว่า บริษัทฯ ได้ตกลงให้
            </div>
          </div>
        </div>
        <div className="ml-8 flex flex-col">
          <div>
            {data?.article}{data?.fullname}
            &nbsp;("ผู้เช่าซื้อ")
          </div>
          <div>
            ที่อยู่
            ............................................................................................................................................................................................................................................
            เป็นผู้เช่าซื้อ
          </div>
          <div>
            รถยนต์ยี่ห้อ/รุ่น
            ............................................................................................................
            หมายเลขเครื่อง
            .....................................................................................................
          </div>
          <div>
            หมายเลขตัวถัง
            .............................................................................................................
            ตั้งแต่วันที่
            ..............................................................................................................
          </div>
          <div>
            ในราคาเช่าซื้อทั้งสิ้น
            .................................................................................
            บาท โดยแบ่งชำระเป็นรายงวด งวดละ
            ..............................................................................บาท
          </div>
        </div>

        <div className="indent-8 pt-4">
          เพื่อการจดทะเบียนรถยนต์คันดังกล่าว บริษัทฯ
          ขออนุญาตนำส่งหนังสือฉบับนี้แก่ท่าน
          เพื่อรับรองการทำสัญญาเช่าซื้อระหว่างบริษัทฯ และผู้เช่าซื้อ
          แทนสัญญาเช่าซื้อ โดยบริษัทฯ ได้ให้ผู้เช่าซื้อลงนามไว้ในหนังสือฉบับนี้
          เพื่อรับรองการทำสัญญาเช่าซื้อร่วมกับบริษัทฯ ด้วย
        </div>
        <div className="indent-8 pt-1">
          จึงเรียนมาเพื่อขอให้ท่านโปรดพิจารณา
          อนุมัติการจดทะเบียนรถยนต์คันดังกล่าว ในนามของ บริษัท มันนี่ ฮับ
        </div>

        <div className="flex justify-end indent-8 pt-2 pr-6">
          <div className="flex flex-col">
            <div className="text-center">ขอแสดงความนับถือ</div>
            <div className="text-center">บริษัท มันนี่ ฮับ เซอร์วิส จำกัด</div>
          </div>
        </div>

        <div className="flex justify-end indent-8 pt-4 pr-3">
          <div className="flex flex-col">
            <div className="text-center">
              ..............................................................
            </div>
            <div className="text-center"> ( นายชยุตม์ ปิยะวงศ์ไพบูลย์ )</div>
            <div className="text-center">ผู้รับมอบอำนาจ</div>
          </div>
        </div>

        <div className="ml-8 flex flex-col pt-6">
          <div>
            ข้าพเจ้า&nbsp;
            {data?.article}{data?.fullname}&nbsp; ขอยืนยันการทำสัญญาเช่าซื้อกับ บริษัท มันนี่
            ฮับ เซอร์วิส จำกัด ดังรายละเอียดที่ปรากฏในหนังสือฉบับนี้
          </div>
        </div>

        <div className="flex justify-end indent-8 pt-4 pr-3">
          <div className="flex flex-col">
            <div className="text-center">
              ..............................................................
            </div>
            <div className="flex items-center justify-between">
              <div className="text-center mx-auto">
                {" "}
                ( {data?.article}{data?.fullname} )
              </div>
            </div>
            <div className="text-center mb-4">ผู้เช่าซื้อ</div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfTransferInsurance;
