import classNames from "classnames";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import React from "react";
import UploadImage from "../../../components/uploadImage/uploadImage";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import { onScrollToTop } from "../../../utils/util.main";

function StepThree({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork,
}) {
  return (
    <div>
      <div
        className={`${STYLE_WHITE_CARD} my-10 p-10 grid grid-cols-2 gap-x-10 space-y-0 `}
      >
        <Controllers
          fields={[
            {
              label: "แบบคำขอสินเชื่อทะเบียนรถ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_contact_request_loan_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_request_loan_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "หนังสือยินยอมฯ ให้จำลองด้านเครดิต",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_contact_consent_book_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_consent_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "หนังสือให้ความยินยอมเปิดเผยข้อมูลส่วนบุคคล (PDPA)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_contact_consent_book_pdpa_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_consent_book_pdpa_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "แบบคำขอโอนและรับโอน (โอนลอย)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_contact_transfer_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_transfer_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "หนังสือมอบอำนาจ (ถ้ามี)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_contact_power_of_attorney_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_power_of_attorney_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "ใบโอนสิทธิประกันภัย (ถ้ามี)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_contact_insurance_transfer_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_insurance_transfer_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "สัญญากู้ยืมเงินสินเชื่อทะเบียนรถ (ลงนามและพยาน)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_contact_car_registration_loan_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_car_registration_loan_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "บันทึกแนบท้าย",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_contact_save_end_imgs",
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_save_end_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "อื่นๆ (ถ้ามี)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_contact_other_imgs",
              classNameCore: classNames("col-span-2 mb-4", {
                ["w-1/2"]: !form.fcr_contact_other_imgs?.filter((item) => item)
                  .length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_contact_other_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepThree;
