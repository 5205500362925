import classNames from "classnames";
import React, { useState } from "react";
import { ICON_APPROVE, ICON_CLOSE } from "../../utils/useIcons";
import {
  STYLE_ICON_STEPPERS,
  STYLE_ICON_STEPPERS_INACTIVE,
  STYLE_TITLE_STEPPERS,
} from "../../utils/useStyles";
import { XCircleIcon as XCircleIconSolid } from "@heroicons/react/24/solid";
import { XCircleIcon as XCircleIconOutline } from "@heroicons/react/24/outline";

function Steppers({ steppers = [], step, setStep, setStepName }) {
  const onClickStep = (stepper, index) => (e) => {
    setStep(index);
    setStepName(stepper?.describe);
  };

  if (!steppers.length) {
    return null;
  }

  return (
    <div className=" ">
      {/* Header */}
      <div className=" grid grid-cols-7 gap-4">
        {steppers.map((stepper, index) => {
          const isActive = index === step;
          const isSuccess = stepper?.isSuccess || false;
          const isError = stepper?.isError || false;
          return (
            <div
              className={classNames("  text-mhs-gray-200 ", {
                "text-mhs-green": isSuccess && !isActive,
                "text-blue-500": isActive,
              })}
              onClick={onClickStep(stepper, index)}
              key={index}
            >
              <div className="grid grid-cols-2 items-center gap-4 ">
                <div>
                  {!isActive &&isSuccess && !isError && (
                    <div>
                      <ICON_APPROVE className={STYLE_ICON_STEPPERS} />
                    </div>
                  )}
                  {isActive ? (
                    <div
                      className={classNames(STYLE_ICON_STEPPERS_INACTIVE, {
                        ["border-blue-500"]: isActive,
                      })}
                    >
                      {stepper?.header}
                    </div>
                  ) : (
                    <React.Fragment>
                      {isError && (
                        <div>
                          {isSuccess ? (
                            <XCircleIconSolid
                              className={classNames(
                                STYLE_ICON_STEPPERS,
                                "text-red-500"
                              )}
                            />
                          ) : (
                            <XCircleIconOutline
                              className={classNames(
                                STYLE_ICON_STEPPERS,
                                "text-red-500"
                              )}
                            />
                          )}
                        </div>
                      )}
                      {!isSuccess && !isError && (
                        <div
                          className={classNames(STYLE_ICON_STEPPERS_INACTIVE, {
                            ["border-blue-500"]: isActive,
                          })}
                        >
                          {stepper?.header}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>

                <div
                  className={classNames({
                    ["border-blue-500"]: isActive,
                    ["border-mhs-green"]: isSuccess && !isActive,
                    ["border-mhs-gray-200"]: !isSuccess,
                    ["border-b-4"]: index !== steppers.length - 1,
                  })}
                ></div>
              </div>

              {stepper?.render ? (
                <div
                  className={classNames(STYLE_TITLE_STEPPERS, {
                    ["text-blue-500"]: isActive,
                  })}
                >
                  {stepper?.describe}
                </div>
              ) : null}
            </div>
          );
        })}

        {/* Render */}
      </div>
      {steppers.map((stepper, index) => {
        const isActive = index === step;
        if (stepper?.render && isActive) {
          return <div key={index}>{stepper?.render()}</div>;
        }
        return null;
      })}
    </div>
  );
}

export default Steppers;
