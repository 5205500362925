import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ICON_ARROW_LEFT } from "../../utils/useIcons";
import { STYLE_ICON_GOBACK, STYLE_TITLE_GOBACK } from "../../utils/useStyles";
import LoadingMutation from "../loading/loadingMutation";

export const ButtonBlue = ({
  className,
  text,
  loading = false,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "rounded-full w-full p-2 text-white text-center flex justify-center items-center  ",
        {
          "cursor-not-allowed bg-gray-300": loading || disabled,
          "bg-mhs-buttonblue-100 cursor-pointer": !loading && !disabled,
        },
        className
      )}
      {...rest}
    >
      {loading && <LoadingMutation />}
      <div> {text}</div>
    </button>
  );
};

export const Button = ({ text, loading = false, className, ...rest }) => {
  return (
    <button {...rest} className={className}>
      {loading && <LoadingMutation />}
      {text}
    </button>
  );
};

export const GoBack = ({ className = STYLE_ICON_GOBACK, title, cb }) => {
  const navigate = useNavigate();

  const onClickGoBack = (e) => {
    e.preventDefault();
    if (cb) {
      cb();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="">
      <ICON_ARROW_LEFT
        className={classNames(className, "cursor-pointer")}
        onClick={onClickGoBack}
      />
      <div className={STYLE_TITLE_GOBACK}> {title}</div>
    </div>
  );
};
