import React from "react";
import THBText from "thai-baht-text";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
} from "../Pdf.Style";

const Header = ({ first, number }) => {
  return (
    <Flex between={true}>
      {first ? (
        <div className="border border-gray-700 w-40 px-2">
          เลขที่สัญญา {number}
        </div>
      ) : (
        <div></div>
      )}
      <Flex className={"flex justify-end"}>
        <Grid grid={1}>
          <Span
            className="text-end font-bold -mt-3 border border-gray-400 px-2 text-gray-600"
            span={1}
          >
            บริษัท มันนี่ ฮับ เซอร์วิส จํากัด
          </Span>
        </Grid>
        <Logo />
      </Flex>
    </Flex>
  );
};

const PdfOpenInfo = ({ data, id, hidden }) => {
  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        <Header />

        <div className="text-lg font-bold text-center py-6 pb-6">
          หนังสือให้ความยินยอมในการเปิดเผยข้อมูล
        </div>
        <div className="flex justify-end">
          ทำที่ ........................................................................
        </div>
        <div className="flex justify-end">
        วันที่ .............. เดือน ..................................... พ.ศ. .............
        </div>

        <div className="pt-6 indent-8 text-justify">
          <div>
            <div className="font-bold flex items-center">
              <div className="mr-2">บุคคลธรรมดา</div>
              <CheckBox />
            </div>
            <div className="">
              <div>
                ข้าพเจ้า {data?.article} {data?.name}&nbsp;
                นามสกุล&nbsp;&nbsp;
                {data?.lastname}
              </div>
              <div>
                วัน/เดือน/ปีพ.ศ.เกิด&nbsp;&nbsp;
                {data?.birthdateTh}&nbsp;
                หมายเลขโทรศัพท์&nbsp;&nbsp;
                {data?.phone_number}
              </div>
              <div>
                บัตรประจำตัวประชาชนเลขที่&nbsp;&nbsp;
                {data?.citizenid}
              </div>
            </div>
          </div>

          <div className="pt-2">
            <div className="font-bold flex items-center">
              <div className="mr-2">นิติบุคคล</div>
              <CheckBox />
            </div>
            <div className="">
              <div>
                ข้าพเจ้า (นิติบุคคลชื่อ)
                ...............................................................................................
                ประเภทนิติบุคคล
                ..........................................................................................................
              </div>
              <div>
                ทะเบียนนิติบุคคลเลขที่/เลขที่อ้างอิงอื่น เลขที่
                ...................................................................................................................................................................................................
              </div>
              <div>
                วันที่จดทะเบียน
                .....................................................................................................
                หมายเลขโทรศัพท์
                .............................................................................................................
              </div>
              <div>
                โดยมี
                ........................................................................................................................
                และ
                .................................................................................................................................
              </div>
              <div>
                เป็นผู้มีอำนาจกระทำการแทนตามหนังสือรับรอง/ใบสำคัญการจดทะเบียน​
                ซึ่งออกโดย
                ...................................................................................................................................
              </div>
              <div className="indent-8">
                <div>
                  ข้าพเจ้าตกลงยินยอมให้ บริษัท ข้อมูลเครดิตแห่งชาติ จำกัด
                  (บริษัท) เปิดเผยหรือให้ข้อมูลของข้าพเจ้าแก่บริษัท
                  มันนี่ฮับเซอร์วิส จำกัด
                  ซึ่งเป็นสมาชิกหรือผู้ใช้บริการของบริษัท
                  เพื่อประโยชน์ในการวิเคราะห์สินเชื่อ การออกบัตรเครดิต
                  ตามคำขอสินเชื่อ/ขอออกบัตรเครดิตของข้าพเจ้าที่ให้ไว้กับธนาคาร/บริษัทดังกล่าวข้างต้น
                  รวมทั้งเพื่อประโยชน์ในการทบทวนสินเชื่อ
                  ต่ออายุสัญญาสินเชื่อ/บัตรเครดิต
                  การบริหารและป้องกันความเสี่ยงตามข้อกำหนดของธนาคารแห่งประเทศไทย
                  และให้ถือว่าคู่ฉบับ และบรรดาสำเนา ภาพถ่าย ข้อมูลอิเล็กทรอนิกส์
                  หรือโทรสาร ที่ทำสำเนาขึ้นจากหนังสือให้ความยินยอมฉบับบนี้
                  โดยการถ่ายสำเนา ถ่ายภาพหรือบันทึกไว้ไม่ว่าในรูปแบบใดๆ
                  เป็นหลักฐานในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน
                </div>
              </div>
            </div>

            <div className="pt-6 flex items-center justify-center flex-col">
              <div>
                .........................................................................
              </div>
              <div>
                ( {data?.article} {data?.fullname} )
              </div>
              <div>
                ผู้ให้ความยินยอม/ผู้มีอำนาจกระทำการแทนนิติบุคคลผู้ให้ความยินยอม
                โปรดระบุชื่อ-สกุลตัวบรรจง
              </div>
            </div>

            <div className="flex justify-between items-center pt-7">
              <div>
                <div>
                  .........................................................................พยาน
                </div>
                <div>
                  (
                  .............................................................................
                  )
                </div>
                <div className="text-center">โปรดระบุชื่อ-สกุลตัวบรรจง</div>
              </div>

              <div>
                <div>
                  .........................................................................พยาน
                </div>
                <div>
                  (
                  .............................................................................
                  )
                </div>
                <div className="text-center">โปรดระบุชื่อ-สกุลตัวบรรจง</div>
              </div>
            </div>

            <div className="pt-6">
              <div className="border border-black px-3 pb-3">
                <div className="-ml-1">
                  หมายเหตุ :
                  ข้อมูลที่บริษัทเปิดเผยให้แก่สมาชิกหรือผู้ใช้บริการเป็นองค์ประกอบหนึ่งในการพิจารณาสินเชื่อของสถาบันการเงิน
                  แต่การเปิดเผยข้อมูลดังกล่าวเป็นสิทธิของเจ้าของข้อมูลที่จะให้ความยินยอมหรือไม่ก็ได้
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfOpenInfo;
