import { API, Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import useAsync from "./useAsync";

function useQuery(
  pathName,
  init = {},
  dependencies = [],
  options = {
    fetchOthers: [],
  }
) {
  const [data, setData] = useState(null);
  const [dataOther, setDataOther] = useState(null);
  const [dataMore, setDataMore] = useState(null);

  const {
    loading,
    setLoading,
    error,
    setError,
    onAsync: fetchData,
  } = useAsync(
    async (init = {}) => {
      const resData = await API.get("MoneyHubAdmin", pathName, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        ...init,
      });
      if (options?.fetchOthers?.length > 0) {
        for (const fetchOther of options?.fetchOthers) {
          const resDataOther = await API.get(
            "MoneyHubAdmin",
            fetchOther.pathName,
            {
              headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              },
              ...fetchOther.init,
            }
          );
          setDataOther((prev) => ({ ...prev, ...resDataOther }));
        }
      }
      setData(resData);
    },
    (e) => {},
    () => {},
    { loading: true }
  );

  const {
    loading: loadingMore,
    setLoading: setLoadingMore,
    error: errorMore,
    setError: setErrorMore,
    onAsync: fetchMoreData,
  } = useAsync(
    async (init = {}) => {
      const resData = await API.get("MoneyHubAdmin", pathName, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        ...init,
      });
      setDataMore(resData);
    },
    (e) => {},
    () => {},
    { loading: false }
  );

  useEffect(() => {
    fetchData(init);
  }, [...dependencies]);

  return {
    dataOther,
    dataMore,
    loadingMore,
    fetchMoreData,
    loading,
    setLoading,
    data,
    setData,
    error,
    refetch: fetchData,
  };
}

export default useQuery;
