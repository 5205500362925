import {
  STYLE_STATUS_REPORT_APPOINT,
  STYLE_STATUS_REPORT_COMPLETE,
  STYLE_STATUS_REPORT_CONFIRM,
  STYLE_STATUS_REPORT_DISAPPOINT,
  STYLE_STATUS_REPORT_DONE,
  STYLE_STATUS_REPORT_NOT_COMPLETE,
  STYLE_STATUS_REPORT_REJECT,
} from "./useStyles";

export const convertCarType = (carType) => {
  if (carType === "CAR") {
    return "รถยนต์";
  }
  return "รถจักรยานยนต์";
};

export const getNumberText = (value, concat = "") => {
  if (value % 10 >= 9) return "เก้า" + concat;
  if (value % 10 >= 8) return "แปด" + concat;
  if (value % 10 >= 7) return "เจ็ด" + concat;
  if (value % 10 >= 6) return "หก" + concat;
  if (value % 10 >= 5) return "ห้า" + concat;
  if (value % 10 >= 4) return "สี่" + concat;
  if (value % 10 >= 3) return "สาม" + concat;
  if (value % 10 >= 2) return "สอง" + concat;
  if (value % 10 >= 1) return "หนึ่ง" + concat;
  return "";
};

export const getThaiAmount = (value = 1819) => {
  var grand = value / 10000;
  var thounsand = value / 1000;
  var hundred = value / 100;
  var deci = value / 10;
  var last = value % 10;
  var lastText = getNumberText(last);
  var deciText = getNumberText(deci, "สิบ");
  if (deci >= 1 && deci < 2) deciText = "สิบ";
  if (deci == 2) deciText = "ยี่สิบ";
  var hundredText = getNumberText(hundred, "ร้อย");
  var thounsandText = getNumberText(thounsand, "พัน");
  var grandText = getNumberText(grand, "หมื่น");
  if (last == 1) lastText = "เอ็ด";
  return (
    grandText + thounsandText + hundredText + deciText + lastText + "บาทถ้วน"
  );
};

export const leapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const isLeapyear = leapYear(new Date().getFullYear());
export const FABRUARY_DAYS = isLeapyear ? 29 : 28;

export const getCurrentLocalDateTime = (
  myDate = new Date(),
  timeZone = "Asia/Bangkok"
) => {
  const options = { timeZone };
  const current = new Date(myDate);
  const currentDate = current.toLocaleDateString("th-TH", options);
  const currentTime = current.toLocaleTimeString("th-TH", options);
  const splitDate = currentDate.split("/");
  const splitTime = currentTime.split(":");
  const date = splitDate[0];
  const month = splitDate[1];
  const year = +splitDate[2] - 543;
  const hours = splitTime[0];
  const minutes = splitTime[1];
  const seconds = splitTime[2];
  const day = new Date(
    year,
    +month - 1,
    +date,
    +hours,
    +minutes,
    +seconds
  ).getDay();
  const timestamp = new Date(
    year,
    +month - 1,
    +date,
    +hours,
    +minutes,
    +seconds
  ).getTime();
  const currentFormatLocalDate =
    pad(year) +
    "-" +
    pad(month) +
    "-" +
    pad(date) +
    "T" +
    pad(hours) +
    ":" +
    pad(minutes) +
    ":" +
    pad(seconds) +
    "+07:00";
  const dateTime =
    pad(year) +
    "-" +
    pad(month) +
    "-" +
    pad(date) +
    "T" +
    pad(hours) +
    ":" +
    pad(minutes) +
    ":" +
    pad(seconds);
  const dateTimeLocal =
    pad(year) +
    "-" +
    pad(month) +
    "-" +
    pad(date) +
    "T" +
    pad(hours) +
    ":" +
    pad(minutes);
  let fullDate = `${year}-${pad(+month)}-${pad(+date)}`;
  return {
    fullDate,
    day,
    date,
    month,
    monthIndex: month - 1,
    year,
    hours,
    minutes,
    seconds,
    timestamp,
    isoDateTime: currentFormatLocalDate,
    dateTimeLocal: dateTimeLocal,
    dateTime: dateTime,
  };
};

export const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

export const months = [
  { month: "January", days: 31, index: 0, monthTh: "มกราคม" },
  { month: "February", days: FABRUARY_DAYS, index: 1, monthTh: "กุมภาพันธ์" },
  { month: "March", days: 31, index: 2, monthTh: "มีนาคม" },
  { month: "April", days: 30, index: 3, monthTh: "เมษายน" },
  { month: "May", days: 31, index: 4, monthTh: "พฤษภาคม" },
  { month: "June", days: 30, index: 5, monthTh: "มิถุนายน" },
  { month: "July", days: 31, index: 6, monthTh: "กรกฎาคม" },
  { month: "August", days: 31, index: 7, monthTh: "สิงหาคม" },
  { month: "September", days: 30, index: 8, monthTh: "กันยายน" },
  { month: "October", days: 31, index: 9, monthTh: "ตุลาคม" },
  { month: "November", days: 30, index: 10, monthTh: "พฤศจิกายน" },
  { month: "December", days: 31, index: 11, monthTh: "ธันวาคม" },
];

const getHour = (hour) => {
  if (hour > 23) {
    return pad(0);
  }
  return pad(hour);
};

export const onClickRouteMapSearch = (search) => (e) => {
  e?.stopPropagation();
  window.open(`https://www.google.co.th/maps/search/${search}`, "_blank");
};

export const onScrollToTop = (id) => {
  const doc = document.getElementById(id);
  if (doc) {
    setTimeout(() => {
      doc.scrollIntoView({ behavior: "auto" });
    }, 300);
  }
};

export const getInterest = (data) => {
  return data?.interest;
  // return data?.category === "TF" ? data.interestReduction : data?.interest;
};

export const getAfterFee = (initCapital, loanFee, receiveAmount) => {
  return initCapital - loanFee * 100 - receiveAmount;
};

export const getBuyType = (data) => {
  return data?.type === "MOTORBIKE" ? "รถจักรยานยนต์" : "รถยนต์";
};

export const getEndTime = (startTime, duration) => {
  let [startHour, startMinute] = startTime.split(":");
  startMinute = Number(startMinute) + Number(duration);
  const modMinute = startMinute % 60;

  if (modMinute === 0) {
    return `${getHour(Number(startHour) + 1)}:00`;
  }

  let plusHour = 0;
  if (startMinute >= 60) {
    plusHour += 1;
  }
  return `${getHour(Number(startHour) + plusHour)}:${pad(modMinute)}`;
};

export const getLastItem = (lastItem) => {
  let lastItemStr = "";
  if (lastItem) {
    const lastItemKeys = Object.keys(lastItem);
    lastItemKeys.forEach((key) => {
      lastItemStr += `${key}=${lastItem[key]}&`;
    });
  }
  return lastItemStr;
};

export const getStatusStyle = (loan_status) => {
  return {
    [STYLE_STATUS_REPORT_COMPLETE]: loan_status === "สมบูรณ์",
    [STYLE_STATUS_REPORT_NOT_COMPLETE]: loan_status === "ไม่สมบูรณ์",
    [STYLE_STATUS_REPORT_DONE]: loan_status === "งานสำเร็จแล้ว",
    [STYLE_STATUS_REPORT_CONFIRM]: loan_status === "ยืนยัน",
    [STYLE_STATUS_REPORT_REJECT]:
      loan_status === "ไม่ผ่าน" || loan_status === "ยกเลิกนัดหมาย",
    [STYLE_STATUS_REPORT_DISAPPOINT]:
      loan_status === "รับคำร้อง" || loan_status === "รอนัดหมาย",
    [STYLE_STATUS_REPORT_APPOINT]: loan_status === "นัดหมายแล้ว",
    ["text-orange-600"]: loan_status === "ยกเลิก",
    ["text-emerald-500"]: loan_status === "รอตรวจ",
  };
};

export const getPageWithInstallment = (installments) => {
  if (installments?.length > 28) {
    return 2;
  }
  return 1;
}
