import classNames from "classnames";
import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfCondtionAddress = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className="">
            <h2 className="font-bold text-lg">แบบฟอร์มตรวจสอบที่อยู่อาศัย</h2>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="pt-10">
          <div>
            ผู้เช่าซื้อชื่อ {data?.article} {data?.name} {data?.lastname} เลขที่สัญญา{" "}
            {data?.request_id}&nbsp;&nbsp;
          </div>
          <div>
            วันที่ตรวจสอบ…………………………………………………………………………………………………....…...เวลา
            …………………………………………………………………………………………………………...……...……...……...........
          </div>
          <div className="font-bold">ผลการตรวจสอบ</div>
          <div className="font-bold">ขณะลงพื้นที่ตรวจสอบ</div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">พบผู้เช่าซื้อ</div>
            <CheckBox />
            <div className="ml-1 mr-1">ไม่พบผู้เช่าซื้อ</div>
          </div>
          <div className="font-bold mt-1">ลักษณะที่อยู่อาศัย</div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">บ้านเดี่ยว</div>
            <CheckBox />
            <div className="ml-1 mr-1">ทาวน์เฮ้าส์/ทาวน์โฮม</div>
            <CheckBox />
            <div className="ml-1 mr-1">คอนโด/อพาร์ทเม้นท์</div>
            <CheckBox />
            <div className="ml-1 mr-1">ห้องเช่า</div>
          </div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">
              อื่นๆ …………………………………………………………………………………
            </div>
          </div>

          <div className="font-bold mt-1">ลักษณะความเป็นเจ้าของ</div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">เป็นเจ้าของเอง</div>
            <CheckBox />
            <div className="ml-1 mr-1">ของบุคคลในครอบครัว</div>
            <CheckBox />
            <div className="ml-1 mr-1">เช่าอาศัย</div>
          </div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">
              อื่นๆ …………………………………………………………………………………
            </div>
          </div>

          <div className="font-bold mt-1">สภาพที่อยู่อาศัยโดยรวม</div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">ดีมาก</div>
            <CheckBox />
            <div className="ml-1 mr-1">ดี</div>
            <CheckBox />
            <div className="ml-1 mr-1">พอใช้</div>
            <CheckBox />
            <div className="ml-1 mr-1">แย่มาก</div>
          </div>

          <div className="font-bold mt-1">ตรวจสอบจากบุคคลในพื้นที่</div>
          <div>
            แหล่งข้อมูลจาก
            ........................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ข้อมูลที่ได้
            .................................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>

          <div>ความคิดเห็นของเจ้าหน้าที่ผู้ตรวจสอบ</div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>
          <div>
            ..................................................................................................................................................................................................................................................................................................................
          </div>

          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">ไม่ควรให้สินเชื่อ</div>
          </div>
          <div className="flex items-center">
            <CheckBox />
            <div className="ml-1 mr-1">ควรให้สินเชื่อ</div>
          </div>
        </div>

        <div className="pt-4 flex items-center justify-end">
        ลงชื่อผู้ตรวจสอบ …………………………………………………………
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfCondtionAddress;
