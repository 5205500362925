import classNames from "classnames";
import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

const PdfAddress = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    if (!data[name])
      return {
        subDistirct: "",
        distirct: "",
        province: "",
        postcode: "",
      };
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        {/* Header */}
        <div className="flex justify-between mt-4">
          <div className="">
            <h2 className="font-bold text-lg mt-5">
              แผนที่ที่อยู่ปัจจุบันและที่ทำงาน
            </h2>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center justify-end">
              <div className="flex flex-col items-end">
                <div>มันนี่ ฮับ ลีสซิ่ง</div>
                <div>MONEY HUB LEASING</div>
              </div>
              <div>
                <Logo />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="-mb-1">
                บริษัท มันนี่ ฮับ เซอร์วิส จำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น
                12
              </div>
              <div className="-mb-1">
                ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
              </div>
              <div>
                โทร. 02-8216710 Fax. 02-5139060 E-mail :
                support@moneyhubservice.com
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="pt-10">
          <div>
            ชื่อผู้กู้/ผู้ค้ำ {data?.article} {data?.fullname} โทรศัพท์มือถือ{" "}
            {data?.phone_number}
          </div>
          <div className="font-bold">ที่อยู่ตามทะเบียนบ้าน</div>
          <div>
            เลขที่……….............หมู่ที่...............หมู่บ้าน/อาคาร.................................................................ตรอก/ซอย.........................................................ถนน..................................................................................
          </div>
          <div>
            <div>
              แขวง/ตำบล {getAddress("address")?.subDistirct}&nbsp;เขต/อำเภอ{" "}
              {getAddress("address")?.distirct} จังหวัด{" "}
              {getAddress("address")?.province} รหัสไปรษณีย์{" "}
              {getAddress("address").postcode}
            </div>
          </div>
          <div className="font-bold">สถานที่ทำงาน</div>
          <div>
            ชื่อบริษัท/กิจการ..........................................................................................................................................โทรศัพท์………………………………………………………………………………………………..ต่อ……………
          </div>
          <div>
            เลขที่……….............หมู่ที่...............หมู่บ้าน/อาคาร.................................................................ตรอก/ซอย.........................................................ถนน..................................................................................
          </div>
          <div>
            แขวง/ตำบล…………………………………………………………..เขต/อำเภอ.................................................................จังหวัด.............................................................รหัสไปรษณีย์............................................
          </div>
          <div className="font-bold">
            แผนที่อยู่ตามทะเบียนบ้าน/ที่อยู่ปัจจุบัน
          </div>
          <div>ละติจูด (Latitude) : ……………………………………………….........</div>
          <div>ลองจิจูด (Longitude) : ………………………………………………....</div>
          <div className="font-bold">คำอธิบายเพิ่มเติม</div>
          <div>1………………………………………………………………………..............</div>
          <div>2………………………………………………………………………..............</div>
          <div>3………………………………………………………………………..............</div>
          <div>4………………………………………………………………………..............</div>
          <div className="font-bold">แผนที่ที่ทำงาน</div>
          <div>ละติจูด (Latitude) : ………………………………………………........</div>
          <div>ลองจิจูด (Longitude) : ………………………………………………...</div>
          <div className="font-bold">คำอธิบายเพิ่มเติม</div>
          <div>1………………………………………………………………………..............</div>
          <div>2………………………………………………………………………..............</div>
          <div>3………………………………………………………………………..............</div>
          <div>4………………………………………………………………………..............</div>
        </div>
        <div className="ml-auto w-1/3 pt-20">
          <div className="">
            ลงชื่อ………………………………………….......………………..ผู้ตรวจสอบ
          </div>
          <div className="text-center">
            (
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            )
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfAddress;
