export const OPTION_JOB = [
  {
    label: "ทั้งหมด",
    value: "all_job",
  },
  {
    label: "นัดหมายแล้ว",
    value: "นัดหมายแล้ว",
  },
  {
    label: "รอนัดหมาย",
    value: "รอนัดหมาย",
  },
  {
    label: "ยกเลิกนัดหมาย",
    value: "ยกเลิกนัดหมาย",
  },
  {
    label: "รอตรวจ",
    value: "รอตรวจ",
  },
];
export const OPTION_REPORT = [
  {
    label: "ทั้งหมด",
    value: "all_fcr",
  },
  {
    label: "งานสำเร็จแล้ว",
    value: "งานสำเร็จแล้ว",
  },
  {
    label: "ยืนยัน",
    value: "ยืนยัน",
  },
  {
    label: "ไม่ผ่าน",
    value: "ไม่ผ่าน",
  },
  {
    label: "สมบูรณ์",
    value: "สมบูรณ์",
  },
  {
    label: "ไม่สมบูรณ์",
    value: "ไม่สมบูรณ์",
  },
];

export const OPTION_LIMIT = [
  {
    label: 50,
    value: 50,
  },
  { label: 100, value: 100 },
  { label: 150, value: 150 },
  { label: 200, value: 200 },
  { label: 250, value: 250 },
  { label: 300, value: 300 },
];

export const OPTION_APPOINT_DURATION = new Array(6).fill(0).map((_, index) => {
  const duration = (index + 1) * 10;
  return {
    label: `${duration}`,
    value: duration,
  };
});
