import classNames from "classnames";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import React from "react";
import UploadImage from "../../../components/uploadImage/uploadImage";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import { onScrollToTop } from "../../../utils/util.main";

function StepFour({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork,
}) {
  return (
    <div>
      <div
        className={`${STYLE_WHITE_CARD} my-10 p-10 grid grid-cols-2 gap-x-10 space-y-0 `}
      >
        <Controllers
          fields={[
            {
              label: "สำเนา + บัตรประชาชนตัวจริง (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_personal_id_card_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_id_card_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "สำเนา + บัตรประชาชนตัวจริง (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_personal_id_card_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_id_card_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "สำเนา + ทะเบียนบ้านตัวจริง (ถ้ามี) (1) ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_personal_home_register_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_home_register_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "สำเนา + ทะเบียนบ้านตัวจริง (ถ้ามี) (2) ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 `,
              type: "customs",
              name: "fcr_personal_home_register_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_home_register_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "เอกสารอื่นๆ (ถ้ามี)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} `,
              subLabel: "ทะเบียนสมรส, ใบเปลี่ยนชื่อ-สกุล, สูติบัตรบุตร",
              classNameSubLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_personal_other_imgs",
              classNameCore: classNames("col-span-2 mb-4", {
                ["w-1/2"]: !form.fcr_personal_other_imgs?.filter((item) => item)
                  .length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_other_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
            {
              label: "แผนที่ตั้งที่อยู่ปัจจุบัน",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_personal_current_address_imgs",
              classNameCore: " col-span-2 pb-4 pt-10 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_current_address_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายบ้านตามที่อยู่ทะเบียนบ้าน (ให้เห็นบ้านเลขที่)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4`,
              type: "customs",
              name: "fcr_personal_home_address_imgs",
              classNameInput: `${STYLE_UPLOAD_IMAGE}`,
              classNameCore: " col-span-2 pb-4 pt-10 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_personal_home_address_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepFour;
