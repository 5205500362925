import {
  STLYE_CLEAR_BUTTON,
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_LIGHTBLUE,
  STYLE_DATE_PICKER,
  STYLE_HEADER_PADDING,
  STYLE_HEAD_TITLE,
  STYLE_LABEL_DATE_PICKER,
  STYLE_PARENT_WHITECARD_DATE_PICKER,
  STYLE_WHITE_CARD,
  STYLE_WRAPPER_JOB,
} from "../../../utils/useStyles";
import SearchBar from "../../../components/searchbar/searchbar";
import { OPTION_REPORT } from "../../../constrant/option.constrant";
import { Controllers } from "radium-omnibus";

const ReportHeader = ({ query, setQuery, defaultQuery }) => {
  return (
    <div>
      <div className={STYLE_HEADER_PADDING}>
        <div className={STYLE_HEAD_TITLE}> REPORT </div>
        <div className=" w-2/3">
          <SearchBar query={query} setQuery={setQuery} type="REPORT" />
        </div>
      </div>
      <div
        className={`${STYLE_WHITE_CARD} ${STYLE_PARENT_WHITECARD_DATE_PICKER} `}
      >
        <Controllers
          fields={[
            {
              type: "date",
              name: "appoint_min",
              classNameInput: STYLE_DATE_PICKER,
            },
            {
              type: "date",
              name: "appoint_max",
              label: "To",
              classNameWrapper: " flex gap-2 items-center ",
              classNameInput: STYLE_DATE_PICKER,
              classNameLabel: `${STYLE_LABEL_DATE_PICKER} mt-4`,
              classNameCore: " col-span-2",
            },
          ]}
          onSubmit={(form) => {
            setQuery((prev) => {
              return {
                ...prev,
                ...form,
              };
            });
          }}
          firstButton="submit"
          classNameSubmit={`${STYLE_BUTTON_LIGHTBLUE} w-36`}
          onCancel={(props) => {
            props?.FunctionReset();
            setQuery(defaultQuery);
          }}
          classNameCancel={STLYE_CLEAR_BUTTON}
          labelCancel="Clear"
          classNameWrapper=" flex gap-4 justify-end col-span-2  "
        />
      </div>
    </div>
  );
};
export default ReportHeader;
