import React from "react";
import THBText from "thai-baht-text";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";

const Header = ({ first, number }) => {
  return (
    <Flex between={true}>
      {first ? (
        <div className="border border-gray-700 w-40 px-2">
          เลขที่สัญญา {number}
        </div>
      ) : (
        <div></div>
      )}
      <div className={"flex flex-col"}>
        <Flex className={"flex justify-end"}>
          <Grid grid={1}>
            <Span className="text-end font-bold -mt-2" span={1}>
              มันนี่ ฮับ ลีสซิ่ง
            </Span>
            <Span className="font-bold mb-2" span={1}>
              MONEY HUB LEASING
            </Span>
          </Grid>
          <Logo />
        </Flex>
        <div className="text-right">
          บริษัท มันนี่ ฮับ เซอร์วิส จํากัด เลขที่ 1010 อาคารชนิ วัตร 3 ชั้น 12
        </div>
        <div className="text-right">
          ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
        </div>
        <div className="text-right">
          โทร. 02-8216710 Fax. 02-5139060 E-mail : support@moneyhubservice.com
        </div>
      </div>
    </Flex>
  );
};

const PdfCredit = ({ data, id, hidden }) => {
  return (
    <React.Fragment>
      <Paper id={id} hidden={hidden} pageCount={1} page={1} showPage={false}>
        <Header />

        <div className="text-lg font-bold text-center py-6 pb-6">
          ความยินยอมในการใช้ข้อมูลเพื่อจัดทำแบบจำลองด้านเครดิต
        </div>
        <div className="flex justify-end">
          วันที่ .............. เดือน ..................................... พ.ศ. .............
        </div>

        <div className="pt-6 indent-8 text-justify">
          ข้าพเจ้าเข้าใจถึงการนำข้อมูลไปใช้จัดทำแบบจำลองด้านเครดิตและยินยอมให้บริษัท
          มันนี่ ฮับ เซอร์วิส จำกัด
          ซึ่งเป็นสมาชิกของบริษัทข้อมูลเครดิตนำข้อมูลของข้าพเจ้าที่ได้รับจากบริษัทข้อมูลเครดิตเฉพาะส่วนที่ไม่สามารถระบุตัวตน
          เช่น ชื่อ นามสกุล เลขประจำตัวประชาชน รวมถึง
          ข้อมูลอื่นใดที่สามารถระบุว่าเป็นข้าพเจ้าได้
          ไปใช้เป็นปัจจัยหนึ่งในการจัดทำแบบจำลองด้านเครดิต
          ตามกฎหมายว่าด้วยการประกอบธุรกิจข้อมูล เครดิตกำหนดเท่านั้น
          และให้คู่ฉบับและบรรดาสำเนา ภาพถ่าย ข้อมูลอิเล็กทรอนิกส์
          หรือโทรสารที่ได้ทำซ้ำขึ้นจากหนังสือให้ความยินยอมฉบับนี้
          โดยการถ่ายภาพสำเนา หรือถ่ายภาพ หรือบันทึกไว้ไม่ว่าในรูปแบบใดๆ
          ให้ถือเป็นหลักฐานในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน ทั้งนี้
          ข้าพเจ้ารับทราบว่าข้าพเจ้ามีสิทธิที่จะให้ความยินยอมหรือไม่ก็ได้
          และเมื่อให้ความยินยอมแล้วข้าพเจ้าจะแจ้งความประสงค์ไม่ให้ความยินยอม
          อีกต่อไปก็ได้ โดยการติดต่อฝ่ายลูกค้า
        </div>

        <div className="flex items-center justify-end pt-4">
          ไม่มีผลต่อการพิจารณาอนุมัติผลิตภัณฑ์
        </div>
        <div className="pt-8 flex items-center justify-center">
          <div className="border border-black rounded-lg w-60 h-32 py-1 px-3">
            <div className="mb-4 text-center">
              ข้าพเจ้าจึงได้ลงลายมือชื่อไว้เป็นสำคัญ
            </div>
            <div className="pt-4 text-center text-black">
            ..............................................................................................
            </div>
            <div className="pt-2 text-center">
              ( {data?.article}{data?.fullname} )
            </div>
            <div className="pt-2 text-center">
              ผู้ให้ความยินยอม / ผู้กู้ลงนาม
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default PdfCredit;
