import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

// AWS
import { API, Auth } from "aws-amplify";

export const initializeApp = createAsyncThunk(
  "initializeApp/initializeStore",
  async (isLoadAdmin) => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const { email } = user.attributes;

      if (isLoadAdmin) {
        const admin_profile = await API.get(
          "MoneyHubAdmin",
          `/leasing/adminProfile/${email}`
        );
        return {
          user: {
            isLoadAdmin,
            ...admin_profile?.admin_profile,
            username: user?.username,
            email,
            groups:
              user?.signInUserSession.accessToken.payload["cognito:groups"],
          },
        };
      }

      return {
        user: {
          isLoadAdmin,
          username: user?.username,
          email,
          groups: user?.signInUserSession.accessToken.payload["cognito:groups"],
        },
      };
    } catch (error) {
      return {
        user: null,
      };
    }
  }
);

const initializeAppStore = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {},
  extraReducers: {
    [initializeApp.pending]: (state, action) => {
      state.status = "loading";
    },
    [initializeApp.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.user;
    },
  },
});

export default initializeAppStore.reducer;
