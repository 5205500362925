import {
  FunctionGetBase64FromUrl,
  FunctionGetUrlFromFile,
} from "radium-omnibus";
import React from "react";
import {
  STYLE_BUTTON_BLUE,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_UPLOAD_IMAGE,
} from "../../utils/useStyles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import classNames from "classnames";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useContext } from "react";
import { WorkContext } from "../../pages/work/work.main";

function UploadImage({
  setTurnOnCamera,
  form,
  setForm,
  name,
  onChangeFile,
  isWork = false,
  isMultiple = false,
  maxFile = 1,
  className = "",
  classNameUpload = "text-center w-full",
  position = 0,
}) {
  const { leasing } = useContext(WorkContext);
  const handleApprove = useFullScreenHandle();

  const onClickTurnOnCamera = () => {
    if (isWork) {
      return;
    }
    setTurnOnCamera(`${name}-${position}`);
  };

  const onClickRemove = (index) => {
    try {
      setForm((prev) => {
        const temp = { ...prev };
        temp[name] = [...temp[name]];
        if (isMultiple) {
          temp[name]?.splice(index, 1);
          if (temp[name]?.length === 0) {
            temp[name] = [""];
          }
        } else {
          temp[name][position] = "";
        }
        return { ...temp };
      });
    } catch (error) {
      console.log("remove error", error);
      alert("remove error");
    }
  };

  const getStartHttp = (formItem = "") => {
    return String(formItem).startsWith("https");
  };

  return (
    <div className="" id={`camera_${name}`}>
      <div
        className={classNames(STYLE_UPLOAD_IMAGE, {
          ["grid grid-cols-1 space-y-0 w-1/2"]:
            isMultiple && form[name]?.filter((item) => item)?.length === 1,
          ["grid grid-cols-2 space-y-0"]:
            isMultiple && form[name]?.filter((item) => item)?.length > 1,
          ["mb-10"]: isWork,
        })}
      >
        {form[name]?.length > 0 &&
          form[name]
            ?.slice(
              isMultiple ? 0 : position,
              isMultiple ? form[name]?.length : position + 1
            )
            .map((formItem, index) => {
              if (!formItem) {
                return (
                  <div className="relative" key={index}>
                    <div>
                      <img
                        src="/icon/add_photo.png"
                        className={STYLE_ICON_UPLOAD}
                      />
                      {/* <div className={STYLE_SUBLABEL_UPLOAD}>
                        Drag and drop files to upload
                      </div> */}
                    </div>
                  </div>
                );
              }

              if (isWork) {
                return (
                  <div className="mx-2 mt-2" key={index}>
                    <div>
                      <FullScreen
                        // className="w-full h-full"
                        className="flex items-center justify-center"
                        handle={handleApprove}
                      >
                        <LazyLoadImage
                          effect="blur"
                          className=""
                          src={formItem}
                          onClick={handleApprove?.enter}
                        />
                      </FullScreen>
                    </div>
                  </div>
                );
              }

              return (
                <div className="" key={index}>
                  <div className="mb-4 mx-2 mt-2 relative">
                    {" "}
                    <FullScreen
                      // className="w-full h-full"
                      className="flex items-center justify-center"
                      handle={handleApprove}
                    >
                      <img
                        // className="w-full h-full"
                        src={
                          getStartHttp(formItem)
                            ? formItem
                            : FunctionGetUrlFromFile(formItem)
                        }
                        onClick={handleApprove.enter}
                      />
                    </FullScreen>
                    <button
                      onClick={() => onClickRemove(index)}
                      className="px-2 py-2 bg-red-500 text-white rounded mx-auto absolute right-0 top-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              );
            })}
      </div>
      {!isWork && (
        <div
          className={classNames(
            STYLE_OR_UPLOAD,
            {
              ["w-1/2"]:
                isMultiple && form[name]?.filter((item) => item)?.length === 1,
            },
            classNameUpload
          )}
        >
          {/* <input
            id={name}
            type="file"
            hidden
            name={name}
            onChange={onChangeFile(isMultiple)}
            multiple={isMultiple}
          />
          <button
            onClick={() => {
              document.getElementById(name).click();
            }}
            className={`${STYLE_BUTTON_BLUE} w-32 mx-auto mt-2 mb-8 !text-sm`}
          >
            อัพโหลดรูปภาพ
          </button> */}
          <button
            onClick={onClickTurnOnCamera}
            className={`${STYLE_BUTTON_BLUE} w-32 mx-auto mt-2 mb-8`}
          >
            ถ่ายรูปภาพ
          </button>
        </div>
      )}
    </div>
  );
}

export default UploadImage;
