import React from "react";
import Lottie from "lottie-react";
import Loading_car from "./Loading_car.json";

const LoadingPage = () => {
  return (
    <div className="">
      <Lottie animationData={Loading_car} className=" w-1/2 mx-auto " />
    </div>
  );
};
export default LoadingPage;
