export const formFileDto = {
  // เอกสารทางการเงินของลูกค้า
  // สลิปเงินเดือน/หนังสือรับรองเงินเดือน
  fcr_slip_imgs: [""],
  // รายการเดินบัญชีที่รับเงินเดือน
  fcr_account_statement_imgs: [""],
  // ภาพถ่ายสมุดบัญชีธนาคารที่ต้องการรับเงิน
  fcr_bank_book_imgs: [""],

  // ภาพถ่ายยืนยันตัวตน FCR และลูกค้า
  fcr_comfirm_customer_imgs: [""],
  fcr_comfirm_sign_imgs: [""],

  fcr_car_front_imgs: [""],
  fcr_car_back_imgs: [""],
  fcr_car_left_imgs: [""],
  fcr_car_right_imgs: [""],
  fcr_car_hood_imgs: [""],
  fcr_car_back_skirt_imgs: [""],
  // ภาพถ่ายรถ ภายใน
  fcr_car_console_imgs: [""],
  fcr_car_mile_imgs: [""],
  fcr_car_back_seat_imgs: [""],
  fcr_car_tank_number_imgs: ["", "", ""],
  // รูปเล่มทะเบียนรถตัวจริง
  fcr_car_book_imgs: ["", ""],
  // รูปถ่ายหน้าทะเบียนหน้าปัจจุบัน
  fcr_car_front_book_imgs: ["", ""],
  // รูปถ่ายหน้าทะเบียนหน้า 16
  fcr_car_front_book_sixteen_imgs: ["", ""],
  // รูปถ่ายหน้าทะเบียนหน้า 18
  fcr_car_front_book_eighteen_imgs: ["", ""],
  // หน้ากรมธรรม์ประกันภัย
  fcr_car_insurance_policy_imgs: ["", ""],
  // รูปถ่ายป้ายวงกลม (ภาษีประจำปี)
  fcr_car_tax_imgs: ["", ""],
  // อื่นๆ (ถ้ามี)
  fcr_car_other_imgs: [""],
  // ใบรายงารการตรวจสภาพรถ
  fcr_car_inspection_report_imgs: [""],

  // ภาพถ่ายใบสมัครและชุดสัญญา
  // แบบคำขอสินเชื่อทะเบียนรถ
  fcr_contact_request_loan_imgs: [""],
  // หนังสือยินยอมฯ ให้จำลองด้านเครดิต ทำ pdf
  fcr_contact_consent_book_imgs: [""],
  // หนังสือให้ความยินยอมเปิดเผยข้อมูลส่วนบุคคล (PDPA) ทำ pdf
  fcr_contact_consent_book_pdpa_imgs: [""],
  // แบบคำขอโอนและรับโอน (โอนลอย)
  fcr_contact_transfer_imgs: [""],
  // หนังสือมอบอำนาจ (ถ้ามี)
  fcr_contact_power_of_attorney_imgs: [""],
  // ใบโอนสิทธิประกันภัย (ถ้ามี)
  fcr_contact_insurance_transfer_imgs: [""],
  // สัญญากู้ยืมเงินสินเชื่อทะเบียนรถ (ลงนามและพยาน)
  fcr_contact_car_registration_loan_imgs: [""],
  // บันทึกแนบท้าย
  fcr_contact_save_end_imgs: [""],
  // อื่นๆ (ถ้ามี)
  fcr_contact_other_imgs: [""],

  // ภาพถ่ายเอกสารส่วนตัว
  // สำเนาบัตรประชาชน
  fcr_personal_id_card_imgs: ["", ""],
  // สำเนา+ทะเบียนบ้านตัวจริง (ถ้ามี)
  fcr_personal_home_register_imgs: ["", ""],
  // เอกสารอื่น (ถ้ามี) เช่น ทะเบียนสมรส,ใบเปลี่ยนชื่อ-สกุล, สูติบัตรบุตร
  fcr_personal_other_imgs: [""],
  // แผนที่ตั้งที่อยู่ปัจจุบัน
  fcr_personal_current_address_imgs: [""],
  // รูปถ่ายบ้านตามที่อยู่ทะเบียนบ้าน (ให้เห็นบ้านเลขที่)
  fcr_personal_home_address_imgs: [""],

  // ภาพถ่ายเอกสารผู้ค้ำประกัน (ถ้ามี)
  // สำเนาบัตรประชาชนผู้ค้ำ
  fcr_guarantor_id_card_imgs: [""],
  // สำเนาทะเบียนบ้านผู้ค้ำ
  fcr_guarantor_home_imgs: [""],
  // ภาพภ่ายบัตรประชาชนตัวจริงคู่สำเนาผู้ค้ำ
  fcr_guarantor_id_card_real_imgs: [""],

  // ภาพถ่าย Checklist
  fcr_personal_checklist_imgs: [""],
};
