import classNames from "classnames";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import React from "react";
import UploadImage from "../../../components/uploadImage/uploadImage";
import {
  STYLE_BUTTON_BLUE,
  STYLE_BUTTON_BLUE_NEXT,
  STYLE_HEAD_TITLE_WORK,
  STYLE_ICON_UPLOAD,
  STYLE_OR_UPLOAD,
  STYLE_SUBLABEL_UPLOAD,
  STYLE_TITLE_STEPPERS,
  STYLE_UPLOAD_IMAGE,
  STYLE_WHITE_CARD,
} from "../../../utils/useStyles";
import { onScrollToTop } from "../../../utils/util.main";

function StepTwoMain({
  setStep,
  step,
  form,
  setForm,
  setTurnOnCamera,
  onChangeFile,
  isWork,
}) {
  return (
    <div>
      <div
        className={`${STYLE_WHITE_CARD} my-10 p-10 grid grid-cols-2 gap-x-10 space-y-0 `}
      >
        <Controllers
          fields={[
            {
              type: "none",
              name: "title",
              label:
                " รูปรถ 6 รูปภายนอก (หน้าตรง,เปิดกระโปรงหน้า,หลังตรง,เปิดกระโปรงหลัง,ซ้ายตรง,ขวาตรง)",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
              classNameCore: "col-span-2 mb-4",
            },
            {
              label: "หน้าตรง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: " เปิดกระโปรงหน้า",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_hood_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_hood_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "หลังตรง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_back_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_back_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "เปิดกระโปรงหลัง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_back_skirt_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_back_skirt_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "ซ้ายตรง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_left_imgs",
              classNameInput: STYLE_UPLOAD_IMAGE,
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_left_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "ขวาตรง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_right_imgs",
              classNameInput: STYLE_UPLOAD_IMAGE,
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_right_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            //ภายในรถ
            {
              type: "none",
              name: "title",
              label:
                " รูปรถ 4 รูปภายใน (คอนโซลหน้า, เบาะหลัง , เลขไมล์, เลขตัวถัง)",
              classNameLabel: STYLE_HEAD_TITLE_WORK,
              classNameCore: "col-span-2 mb-4 pt-10 text-center",
            },
            {
              label: "คอนโซลหน้า",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center mt-6`,
              type: "customs",
              name: "fcr_car_console_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_console_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "เบาะหลัง",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center mt-6`,
              type: "customs",
              name: "fcr_car_back_seat_imgs",
              classNameInput: STYLE_UPLOAD_IMAGE,
              classNameCore: ` `,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_back_seat_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "เลขไมล์",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_mile_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_mile_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "เลขตัวถัง (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_tank_number_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_tank_number_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={0}
                  />
                );
              },
            },
            {
              label: "เลขตัวถัง (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_tank_number_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_tank_number_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "เลขตัวถัง (3)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_tank_number_imgs",
              classNameCore: `mb-4`,
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_tank_number_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={2}
                  />
                );
              },
            },
            {
              label: "รูปเล่มทะเบียนรถตัวจริง (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_book_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปเล่มทะเบียนรถตัวจริง (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_book_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนปัจจุบัน (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนปัจจุบัน (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนหน้า 16 (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_sixteen_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_sixteen_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนหน้า 16 (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_sixteen_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_sixteen_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนหน้า 18 (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_eighteen_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_eighteen_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายหน้าทะเบียนหน้า 18 (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_front_book_eighteen_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_front_book_eighteen_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "หน้ากรมธรรม์ประกันภัย (1)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_insurance_policy_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_insurance_policy_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "หน้ากรมธรรม์ประกันภัย (2)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_insurance_policy_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_insurance_policy_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายป้ายวงกลม (1)",
              subLabel: "(ภาษีประจำปี)",
              classNameSubLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} text-center`,
              type: "customs",
              name: "fcr_car_tax_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_tax_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
            {
              label: "รูปถ่ายป้ายวงกลม (2)",
              subLabel: "(ภาษีประจำปี)",
              classNameSubLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} text-center`,
              type: "customs",
              name: "fcr_car_tax_imgs",
              classNameCore: "mb-4",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_tax_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    position={1}
                  />
                );
              },
            },
            {
              label: "อื่นๆ (ถ้ามี)",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_other_imgs",
              classNameInput: STYLE_UPLOAD_IMAGE,
              classNameCore: classNames(`mb-4 col-span-2`, {
                ["w-1/2"]: !form.fcr_car_other_imgs?.filter(item => item).length,
              }),
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_other_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                    isMultiple
                  />
                );
              },
            },
            {
              label: "ใบรายงานตรวจสภาพรถ",
              classNameLabel: `${STYLE_HEAD_TITLE_WORK} mb-4 text-center`,
              type: "customs",
              name: "fcr_car_inspection_report_imgs",
              classNameInput: STYLE_UPLOAD_IMAGE,
              classNameCore: " col-span-2 mb-4 w-1/2",
              fieldCustoms: () => {
                return (
                  <UploadImage
                    setTurnOnCamera={setTurnOnCamera}
                    form={form}
                    setForm={setForm}
                    name="fcr_car_inspection_report_imgs"
                    onChangeFile={onChangeFile}
                    isWork={isWork}
                  />
                );
              },
            },
          ]}
          onSubmit={(form) => {
            onScrollToTop("work_main")
            setStep((prev) => prev + 1);
          }}
          classNameSubmit="hidden"
        />
      </div>
      <ButtonSubmitController
        classNameSubmit={STYLE_BUTTON_BLUE_NEXT}
        labelSubmit="Next"
      />
    </div>
  );
}

export default StepTwoMain;
