import classNames from "classnames";
import { format } from "date-fns";
import numeral from "numeral";
import React from "react";
import {
  getBuyType,
  getInterest,
  getPageWithInstallment,
} from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
  CheckBox,
  LineBottom,
} from "../Pdf.Style";

export const Table = ({
  items = [],
  classNameWrapper,
  classNameBorder,
  cols = 6,
}) => {
  return (
    <div
      className={classNames(`grid ${classNameWrapper} `, {
        "grid-cols-6": cols === 6,
        "grid-cols-5": cols === 5,
      })}
    >
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={`col-span-1 border-t border-l border-gray-500 text-center relative h-5 w-full ${classNameBorder} ${
              index + 1 === items.length ? "border-r" : ""
            }`}
          >
            <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-full ">
              {item}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PdfInstallment = ({ data, others, hidden, id }) => {
  let count = 0;
  const getAddress = (name) => {
    if (!data[name])
      return {
        subDistirct: "",
        distirct: "",
        province: "",
        postcode: "",
      };
    const [subDistirct, distirct, province, postcode] = data[name]?.split(",");
    return {
      subDistirct,
      distirct,
      province,
      postcode,
    };
  };

  const pageCount = getPageWithInstallment(others?.installments);

  return (
    <React.Fragment>
      <Paper
        id={id}
        hidden={hidden}
        pageCount={pageCount}
        page={1}
        showPage={true}
      >
        {/* Content */}
        <div className="pt-0">
          <div className="font-bold text-center text-xl">
            รายละเอียดการผ่อนชำระ
          </div>
          <div className="flex items-center">
            <div className="mt-2">
              ชื่อผู้ทำสัญญา {data?.article} {data?.fullname}
            </div>
            <div className="mt-2 ml-1">รหัสบัตรประชาชน {data?.citizenid}</div>
          </div>
          <div className="flex">เลขที่สัญญา {data?.request_id}</div>
          <div>
            ประเภทรถ {getBuyType(data)}{" "}
            ยี่ห้อ………………………………………..............................…..ทะเบียนรถ………………………………………...………หมายเลขตัวถัง……..………………………………….........……………...............…....
          </div>
          <div className="flex items-center">
            <div className="mr-1">
              วงเงินสินเชื่อ {numeral(data?.amount).format("0,000")}
            </div>
            <div>ดอกเบี้ย {data?.interest}%</div>
          </div>
          <div className="flex items-center">
            ระยะเวลาผ่อนชำระ {data?.period} งวด
          </div>
          <div>
            วันที่เริ่มสัญญา………………………………………………………………………………………………………..…วันที่สิ้นสุดสัญญา………………………………………….……………………………………….………………………………....….
          </div>
          <div className="mt-3">
            <Table
              items={[
                "งวดที่",
                "เงินต้น",
                "ดอกเบี้ย",
                "รวมค่างวด",
                // "เงินต้นคงเหลือ",
                "วันครบกำหนดชำระ",
              ]}
              classNameWrapper="text-center font-bold"
              cols={5}
            />
            {others?.installments?.slice(0, 28).map((item, index) => {
              return (
                <Table
                  classNameBorder={index + 1 <= 28 ? "border-b" : ""}
                  items={[
                    index + 1,
                    numeral(item?.principle).format("0,000.00"),
                    numeral(item?.interest).format("0,000.00"),
                    numeral(item?.principle + item?.interest).format(
                      "0,000.00"
                    ),
                    // numeral(item?.capitalRemain).format("0,000.00"),
                    format(new Date(item?.dueDate), "dd-MM-yyyy"),
                  ]}
                  classNameWrapper="text-center font-bold"
                  cols={5}
                />
              );
            })}
          </div>
        </div>
      </Paper>
      {pageCount > 1 && (
        <Paper
          id={id}
          hidden={hidden}
          pageCount={pageCount}
          page={2}
          showPage={true}
        >
          {/* Content */}
          <div className="pt-10">
            <div className="mt-3">
              <Table
                items={[
                  "งวดที่",
                  "เงินต้น",
                  "ดอกเบี้ย",
                  "รวมค่างวด",
                  // "เงินต้นคงเหลือ",
                  "วันครบกำหนดชำระ",
                ]}
                classNameWrapper="text-center font-bold"
                cols={5}
              />
              {others?.installments
                ?.slice(28, others?.installments?.length)
                .map((item, index) => {
                  return (
                    <Table
                      classNameBorder={
                        28 + index + 1 === others?.installments?.length
                          ? "border-b"
                          : ""
                      }
                      items={[
                        28 + index + 1,
                        numeral(item?.principle).format("0,000.00"),
                        numeral(item?.interest).format("0,000.00"),
                        numeral(item?.principle + item?.interest).format(
                          "0,000.00"
                        ),
                        // numeral(item?.capitalRemain).format("0,000.00"),
                        format(new Date(item?.dueDate), "dd-MM-yyyy"),
                      ]}
                      classNameWrapper="text-center font-bold"
                      cols={5}
                    />
                  );
                })}
            </div>
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
};
export default PdfInstallment;
