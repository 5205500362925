// import Webcam from "react-webcam";
import React, { useState, useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Resizer from "react-image-file-resizer";
import { FunctionGetFileFromBase64 } from "radium-omnibus";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-html5-camera-photo";

const Camera = ({ callback, onClose, valueTypeCamera, setTempFile }) => {
  const webcamRef = React.useRef(null);
  const [deviceId, setDeviceId] = React.useState({});

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    // if (isMobile) {
    //   captureInput();
    // }
  }, []);

  const capture = React.useCallback(async () => {
    // const file = FunctionGetFileFromBase64(webcamRef.current.getScreenshot());
    callback(webcamRef.current.getScreenshot({ width: 700, height: 700 }));
    onClose();
  }, [webcamRef]);

  const getVideoConstraints = () => {
    let videoConstraints = {
      // deviceId: deviceId,
      // height: 200,
      // width: 700,
      // height: 700,
      facingMode: "user",
    };
    if (valueTypeCamera === "rear") {
      // videoConstraints.facingMode = { exact: "environment" };
      videoConstraints.facingMode = "environment";
    }
    return videoConstraints;
  };

  const getIdeaFacingMode = () => {
    let ideaFacingMode = "user";
    if (valueTypeCamera === "rear") {
      ideaFacingMode = "environment";
    }
    return ideaFacingMode;
  }

  // const toBase64 = (file) => {
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  //   // new Promise((resolve, reject) => {
  //   //   const reader = new FileReader();
  //   //   reader.readAsDataURL(file);
  //   //   // reader.onload = async (event) => {
  //   //   //   resolve(reader.result);
  //   //   //   // Resizer.imageFileResizer(
  //   //   //   //   file,
  //   //   //   //   1,
  //   //   //   //   1,
  //   //   //   //   "PNG",
  //   //   //   //   1,
  //   //   //   //   0,
  //   //   //   //   (uri) => {
  //   //   //   //     resolve(uri);
  //   //   //   //   },
  //   //   //   //   "base64"
  //   //   //   // );
  //   //   // };
  //   //   reader.onload = () => resolve(reader.result);
  //   //   reader.onerror = (error) => reject(error);
  //   // });
  // };

  const toBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    // return new Promise((resolve) => {
    //   Resizer.imageFileResizer(
    //     file,
    //     400,
    //     400,
    //     "PNG",
    //     100,
    //     0,
    //     (uri) => {
    //       resolve(uri);
    //     },
    //     "base64"
    //   );
    // });
  };

  // new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = (error) => reject(error);
  // });

  const captureInput = () => {
    const docInputId = document.getElementById("camera_input");
    docInputId.click();
  };

  const onChangeFile = async (e) => {
    try {
      const file = e?.target?.files[0];
      callback(await toBase64(file));
      onClose();
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <div>
      {/* {!isMobile ? ( */}
      <React.Fragment>
        <Webcam
          onTakePhoto={(dataUri) => {
            callback(dataUri);
            onClose();
          }}
          idealFacingMode={getIdeaFacingMode()}
          imageCompression={1}
          idealResolution={{
            width: 1920,
            height: 1080,
          }}
          isMaxResolution
          // isMaxResolution
        />
        {/* <Webcam
          audio={false}
          // mirrored={true}
          screenshotQuality={1}
          ref={webcamRef}
          // forceScreenshotSourceSize
          // video={{ width: 1280, height: 720 }}
          screenshotFormat="image/jpeg"
          width={"100%"}
          height={"100%"}
          videoConstraints={{
            ...getVideoConstraints(),
            // width,
            // height,
            // frameRate: { ideal: 30, max: 60 },
          }}
          imageSmoothing={false}
        /> */}
        {/* <button
          className="p-4 bg-blue-500 text-white w-full text-lg"
          onClick={capture}
        >
          Capture photo
        </button> */}
        <button
          className="p-4 border border-blue-500 text-blue-500 w-full text-lg"
          onClick={onClose}
        >
          Close
        </button>
      </React.Fragment>
      {/* ) : (
        <input
          id="camera_input"
          type="file"
          // accept="image/*"
          accept="image/png, image/jpeg"
          onChange={onChangeFile}
          // capture={valueTypeCamera === "rear" ? "environment" : "user"}
          capture={"user"}
          hidden
        />
      )} */}
    </div>
  );
};

export default Camera;
