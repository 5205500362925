import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ICON_APPROVE, ICON_LOCATION } from "../../utils/useIcons";
import {
  STLE_TITLE_TYPE_CARD,
  STYLE_BG_CARD,
  STYLE_BORDER_CARD,
  STYLE_CODE_CARD,
  STYLE_DATE_REPORT,
  STYLE_HEAD_CARD,
  STYLE_ICON_LOCATION_CARD,
  STYLE_LOCATION_CARD,
  STYLE_LOCATION_WHITE_CARD,
  STYLE_PARENT_JOB_LIST,
  STYLE_PARENT_WHITE_CARD,
  STYLE_STATUS_REPORT_APPOINT,
  STYLE_STATUS_REPORT_DISAPPOINT,
  STYLE_TITLE,
  STYLE_TITLE_ADDRESS,
  STYLE_TITLE_HEAD_CARD,
  STYLE_TYPE_CARD,
  STYLE_VALUE_WHITE_CARD,
  STYLE_WHITE_CARD,
} from "../../utils/useStyles";
import { convertCarType, getStatusStyle, onClickRouteMapSearch } from "../../utils/util.main";

function JobCard({ row, onClick }) {
  const navigate = useNavigate();

  const onClickRoute = () => {
    navigate(`/job/${row.request_id}`);
  };

  return (
    <div onClick={onClickRoute}>
      <div onClick={onClick} className=" cursor-pointer">
        <div className={STYLE_HEAD_CARD}>
          <div className={STYLE_TITLE_HEAD_CARD}>
            <div className=" mx-1">
              {" "}
              {row?.name} {row?.lastname}
            </div>{" "}
            |{" "}
            <div className=" mx-1">
              {" "}
              {row?.purpose}
            </div>{" "}
            |{" "}
            <div>
              {row?.approve ? (
                <ICON_APPROVE className=" w-10 text-mhs-green my-auto " />
              ) : row?.loan_status === "รับคำร้อง" ? (
                <div className={classNames(getStatusStyle(row.loan_status))}>
                  ยังไม่ได้นัดหมาย
                </div>
              ) : (
                <div className={classNames(getStatusStyle(row.loan_status))}>
                  {row?.loan_status}
                </div>
              )}
            </div>
            {row?.appointBy && <div>| {row?.appoint_admin_name}</div>}
          </div>
          <div className={STYLE_CODE_CARD}> รหัส : {row?.request_id}</div>
        </div>
        <div className={STYLE_BG_CARD}>
          <div className={STYLE_TITLE_ADDRESS}> ที่อยู่ตามทะเบียนบ้าน</div>
          <div className={STYLE_LOCATION_CARD}>
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
            <div
              onClick={onClickRouteMapSearch(
                row?.addressCode + " " + row?.addressDesc + " " + row?.address
              )}
              className="inline cursor-pointer"
            >
              {" "}
              {row?.addressCode + " " + row?.addressDesc + " " + row?.address}
            </div>
          </div>
          <div className={STYLE_TITLE_ADDRESS}> ที่อยู่ปัจจุบัน</div>
          <div className={STYLE_LOCATION_CARD}>
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
            <div
              onClick={onClickRouteMapSearch(
                row?.officialAddressCode +
                  " " +
                  row.officialAddressDesc +
                  " " +
                  row.currentAddress
              )}
              className="inline cursor-pointer"
            >
              {row?.officialAddressCode +
                " " +
                row.officialAddressDesc +
                " " +
                row.currentAddress}
            </div>
          </div>
          <div className={STYLE_TITLE_ADDRESS}> ที่อยู่ที่ลูกค้าขอยื่น</div>
          <div className={STYLE_LOCATION_CARD}>
            <ICON_LOCATION className={STYLE_ICON_LOCATION_CARD} />
            <div
              onClick={onClickRouteMapSearch(
                row?.latitude + "," + row?.longitude
              )}
              className="inline cursor-pointer"
            >
              {" "}
              {row?.latitude + " " + row?.longitude}
            </div>
          </div>
          <div className={STYLE_TYPE_CARD}>
            {" "}
            ประเภทรถ ({convertCarType(row?.type)})
          </div>
          <div className={STLE_TITLE_TYPE_CARD}>
            {" "}
            {row?.brand} {row?.model} {row?.year}
          </div>
          <div className={STLE_TITLE_TYPE_CARD}> {row?.subModel}</div>
          <div className={STYLE_BORDER_CARD}> </div>
        </div>
      </div>
    </div>
  );
}

export default JobCard;
