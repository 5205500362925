import { Auth } from "aws-amplify";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  NavLink,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { ICON_LOGOUT, ICON_USER } from "../../utils/useIcons";
import {
  STYLE_SELECTED_MENU,
  STYLE_UNSELECT_MENU,
} from "../../utils/useStyles";
import { useDispatch } from "react-redux";
import { initializeApp } from "../../redux/initializeAppSlice";

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className=" font-Inter text-sm text-white cursor-pointer flex gap-6 items-center ml-14 ">
      <div
        className={classNames({
          [STYLE_SELECTED_MENU]:
            location.pathname.startsWith("/job") || location.pathname === "/",
          [STYLE_UNSELECT_MENU]:
            !location.pathname.startsWith("/job") && location.pathname !== "/",
        })}
        onClick={() => {
          navigate("/");
        }}
      >
        JOB
      </div>
      <div
        className={classNames({
          [STYLE_SELECTED_MENU]: location?.pathname === "/report",
          [STYLE_UNSELECT_MENU]: location?.pathname !== "/report",
        })}
        onClick={() => {
          navigate("/report");
        }}
      >
        REPORT
      </div>
      <div
        className={classNames({
          [STYLE_SELECTED_MENU]: location?.pathname === "/form",
          [STYLE_UNSELECT_MENU]: location?.pathname !== "/form",
        })}
        onClick={() => {
          navigate("/form");
        }}
      >
        FORM
      </div>
    </div>
  );
};
const User = () => {
  const { user } = useSelector((state) => state.initializeApp);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onClickLogOut = async () => {
    await Auth.signOut();
    dispatch(initializeApp());
  };

  return (
    <div className=" flex gap-4 items-center  ">
      <div onClick={() => navigate("/profile")} className="cursor-pointer flex items-center text-sm text-mhs-buttonblue-100 font-bold mr-1">
        <ICON_USER className=" w-5 " />
        <div className="ml-1">{user?.username}</div>
      </div>
      <ICON_LOGOUT
        onClick={onClickLogOut}
        className=" w-6 text-white cursor-pointer "
      />
    </div>
  );
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/login") {
    return null;
  }

  return (
    <div className="bg-mhs-blue-300 w-full h-16 px-12 py-4 flex justify-between items-center ">
      <div className="cursor-pointer" onClick={() => navigate("/")}>
        <img src="/images/logo_mhs.png" className=" w-8" />
      </div>
      <Menu />
      <User />
    </div>
  );
};
export default Navbar;
