import React from "react";
import { getInterest } from "../../../utils/util.main";
import {
  Flex,
  Grid,
  Paper,
  Paragraph,
  Span,
  Logo,
  Pdf,
  Title,
  Signature,
} from "../Pdf.Style";

const Header = () => {
  return (
    <React.Fragment>
      <div className="flex justify-end">
        <div>
          <div className=" font-bold text-end -mt-2">มันนี่ ฮับ ลีสซิ่ง</div>
          <div className="font-bold text-end mb-2">MONEY HUB LEASING</div>
        </div>
        <Logo />
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        บริษัท มันนี่ ฮับ เซอร์วิสจำกัด เลขที่ 1010 อาคารชินวัตร 3 ชั้น 12
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        ถนนวิภาวดี-รังสิต แขวงจตุจักร เขตจตุจักร จังหวัดกรุงเทพฯ 10900
      </div>
      <div
        style={{
          fontSize: "9px",
        }}
        className="text-end"
      >
        โทร. 02-8216710 Fax. 02-5139060 E-mail: support@moneyhubservice.com
      </div>
    </React.Fragment>
  );
};

const PdfHireEndPurchaseContract = ({ data, others, hidden, id }) => {
  return (
    <React.Fragment>
      <Paper
        id={id}
        hidden={hidden}
        positionPage="right"
        pageCount={5}
        page={1}
      >
        <Header />
        <div className="text-center font-bold">
          สัญญาต่อท้ายสัญญาเช่าซื้อ (บุคคลธรรมดา)
        </div>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 1. </span>{" "}
          การเช่าซื้อทรัพย์ที่เช่าซื้อของผู้เช่าซื้อตามสัญญาเช่าซื้อและสัญญาต่อท้ายสัญญาเช่าซื้อฉบับนี้
          เป็นการเช่าซื้อทรัพย์ที่เช่าซื้อเพื่อใช้เป็นการส่วนตัวเท่านั้น
          โดยผู้เช่าไม่ได้นำไปใช้ทำการขนส่ง เพื่อการค้า หรือเพื่อสินจ้าง
          เว้นแต่จะได้รับอนุญาตจากบริษัทเป็นลายลักษณ์อักษรให้ดำเนินการดังกล่าวได้
          และผู้เช่าซื้อตกลงและยินยอมรับมอบทรัพย์ที่เช่าซื้อไปจากบริษัท
          ในสภาพที่ได้ตรวจสอบจนเป็นที่พอใจแล้ว
          โดยเป็นการเช่าซื้อทรัพย์ที่เช่าซื้อตามสภาพขณะทำสัญญานี้ นอกจากนี้
          สำหรับวิธีการและสถานที่ส่งมอบให้เป็นไปตามที่บริษัทและผู้เช่าซื้อจะได้ตกลงกัน
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 2. </span> การชำระค่าเช่าซื้อ
          ผู้เช่าซื้อตกลงชำระค่าเช่าซื้อของทรัพย์ที่เช่าซื้อให้แก่บริษัทตามเงื่อนไขการชำระค่าเช่าซื้อ
          ที่กำหนดไว้ในข้อ 1.2 ของสัญญาเช่าซื้อข้างต้น
          และผู้เช่าซื้อตกลงรับภาระใน (1) ภาษีมูลค่าเพิ่ม สำหรับเงินจำนวนใดๆ
          ตามสัญญานี้ตามอัตราที่กำหนดไว้ในสัญญาเช่าซื้อ
          หรือที่จะได้มีการแก้ไขเปลี่ยนแปลงต่อไปในภายหน้าให้แก่บริษัท และ (2)
          ค่าอากรแสตมป์ทั้งหมดตามสัญญานี้ด้วย
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 3. </span>
          กรณีที่ผู้เช่าซื้อมีความประสงค์จะขอชำระค่าเช่าซื้อทั้งหมดในคราวเดียว
          เพื่อปิดบัญชีค่าเช่าซื้อ
          โดยไม่ผ่อนชำระค่าเช่าซื้อเป็นรายงวดตามสัญญาเช่าซื้อ
          ผู้ให้เช่าซื้อจะให้ส่วนลดแก่ผู้เช่าซื้อโดยให้คิดคำนวณตามมาตรฐานการบัญชีว่าด้วยเรื่องสัญญาเช่าที่คณะกรรมการกำหนดมาตรฐานในการจัดทำบัญชีตามกฏหมายว่าด้วยการบัญชีและกฏหมายอื่น
          ดังนี้
          <div className="indent-8 text-left">
            1.&nbsp;กรณีชำระค่างวดมาแล้วไม่เกินหนึ่งในสามของค่างวดเช่าซื้อที่ระบุไว้ในสัญญาให้ได้รับส่วนลดในอัตราไม่น้อยกว่าร้อยละหกสิบของดอกเบี้ยเช่าซื้อที่ยังไม่ถึงกำหนดชำระ
          </div>
          <div className="indent-8 text-left">
            2.&nbsp;กรณีชำระค่างวดมาแล้วไม่น้อยกว่าหนึ่งในสามแต่ไม่เกินสองในสามของค่างวดเช่าซื้อที่ระบุไว้ในสัญญาให้ได้รับส่วนลดในอัตราไม่น้อยกว่าร้อยละเจ็ดสิบของดอกเบี้ยเช่าซื้อที่ยังไม่ถึงกำหนดชำระ
          </div>
          <div className="indent-8 text-left">
            3.&nbsp;กรณีชำระค่างวดมาแล้วเกินกว่าสองในสามของค่างวดเช่าซื้อที่ระบุไว้ในสัญญาให้ได้รับส่วนลดทั้งหมดของดอกเบี้ยเช่าซื้อที่ยังไม่ถึงกำหนดชำระ
          </div>
        </Paragraph>
        <Paragraph>
          ผู้เช่าซื้อขอรับรองว่า
          ผู้เช่าซื้อได้รับทราบและเข้าใจข้อมูลทั้งหมดที่อยู่ในตารางแสดงภาระหนี้
          ตามสัญญาเช่าซื้อที่ปรากฎรายละเอียดในเอกสารแล้ว
          รวมทั้งได้รับมอบตารางแสดงภาระหนี้ตามสัญญาเช่าซื้อจากบริษัทเรียบร้อยแล้ว
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 4. </span> ค่าเบี้ยปรับ
          และค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ ในการทวงถามหนี้
          <Paragraph>
            4.1 กรณีที่ผู้เช่าซื้อผิดนัดไม่ชำระค่าเช่าซื้อตามกำหนด
            ผู้เช่าซื้อตกลงชำระเบี้ยปรับของค่าเช่าซื้อที่ผิดนัด ให้แก่บริษัท
            ในอัตราเบี้ยปรับนับตั้งแต่วันถึงกำหนดชำระค่าเช่าซื้องวดนั้นๆ
            จนถึงวันชำระค่าเช่าซื้องวดดังกล่าวครบถ้วน
            <div className="indent-16 pt-2">
              “อัตราเบี้ยปรับ” ตามสัญญานี้หมายความถึง
              อัตราเบี้ยปรับที่จะใช้ในการคิดคำนวณตามข้อกำหนดและเงื่อนไขของสัญญานี้
              ซึ่งมีอัตราเท่ากับอัตราดอกเบี้ยที่แท้จริง (Effective Interest
              Rate) ตามที่กำหนดไว้ในข้อ 1.3 ของสัญญาเช่าซื้อข้างต้นบวกด้วยร้อยละ
              5% ต่อปี โดยคำนวณจากยอดที่ผิดนัดชำระหนี้
            </div>
          </Paragraph>
        </Paragraph>
        <Paragraph>
          4.2 ผู้เช่าซื้อตกลงจะชำระค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
          ในการทวงถามหนี้ค่าเช่าซื้อให้แก่บริษัท
          ตามเงื่อนไขและรายละเอียดที่กำหนดไว้ในสัญญานี้ด้วย
          โดยผู้เช่าซื้อได้รับทราบและเข้าใจข้อมูลทั้งหมดเกี่ยวกับการเรียกเก็บ
          และวิธีการคิดคำนวณค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
          ในการทวงถามหนี้ค่าเช่าซื้อโดยถูกต้องทุกประการแล้ว
        </Paragraph>
        <div className="indent-16 pt-2">
          “ค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ ในการทวงถามหนี้ค่าเช่าซื้อ”
          ตามสัญญานี้หมายความถึง
          เงินที่บริษัทเรียกเก็บจากผู้เช่าซื้อเพื่อเป็นค่าบริการหรือค่าใช้จ่ายในการติดตามทวงถามหนี้เงินค่างวดเช่าซื้อรถยนต์ตามกฎหมายว่าด้วยการทวงหนี้
          (โดยไม่รวมถึงค่าติดตามเอารถกลับคืนของบริษัท)
        </div>
        <Paragraph>
          4.3 ผู้เช่าซื้อตกลงให้บริษัทเรียกเก็บค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
          ในการทวงถามหนี้ค่าเช่าซื้อตามสัญญานี้ได้เพียงเท่าที่บริษัทได้ใช้จ่ายไปจริง
          โดยประหยัด ตามความจำเป็น
          และมีเหตุผลอันสมควรโดยให้อ้างอิงหลักเกณฑ์ตามอัตราและรายละเอียดที่กำหนดอยู่ในประกาศของบริษัทในเรื่องดังกล่าว
          โดยมีรายละเอียดตามเอกสารแนบท้ายสัญญาต่าท้ายสัญญาเช่าซื้อนี้
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 5.</span>{" "}
          การได้มาซึ่งกรรมสิทธิ์ในทรัพย์ที่เช่าซื้อของผู้เช่าซื้อ
          เมื่อผู้เช่าซื้อได้ชำระเงินค่าเช่าซื้อและค่าใช้จ่ายที่บริษัทเรียกเก็บได้ครบถ้วน
          รวมทั้งได้ปฎิบัติตามเงื่อนไขและรายละเอียดที่กำหนดไว้ในสัญญานี้ครบถ้วนถูกต้องทุกประการแล้ว
          ให้กรรมสิทธิ์ทรัพย์ที่เช่าซื้อตกเป็นของผู้เช่าซื้อทันที
          โดยผู้เช่าซื้อจะต้องส่งมอบเอกสารที่จำเป็นสำหรับการจดทะเบียนทรัพย์ที่เช่าซื้อให้เป็นชื่อของผู้เช่าซื้อให้แก่บริษัท
          ภายใน 7 วัน
          นับแต่วันที่ผู้เช่าซื้อได้ชำระเงินค่าเช่าซื้อและค่าใช้จ่ายที่บริษัทเรียกเก็บได้ตามสัญญานี้ให้แก่บริษัทครบถ้วนถูกต้องแล้ว
          รวมทั้งจะต้องเป็นผู้ออกค่าธรรมเนียมการโอนทะเบียนรถยนต์
          ตลอดจนค่าใช้จ่ายและเงินจำนวนใดๆ
          ที่เกี่ยวข้องตามที่กรมการขนส่งทางบกเรียกเก็บ
          และบริษัทจะจดทะเบียนทรัพย์ที่เช่าซื้อให้เป็นของผู้เช่าซื้อภายใน 30 วัน
          นับแต่วันที่บริษัทได้รับเอกสารที่จำเป็นสำหรับการจดทะเบียนดังกล่าว
          จากผู้เช่าซื้อครบถ้วน
          เว้นแต่กรณีที่มีเหตุขัดข้องที่ไม่สามารถทำการจดทะเบียนทรัพย์ที่เช่าซื้อให้เป็นชื่อของผู้เช่าซื้อได้โดยมิใช่เป็นความผิดของบริษัท
        </Paragraph>
      </Paper>
      <Paper
        id={id}
        hidden={hidden}
        positionPage="right"
        pageCount={5}
        page={2}
      >
        <div className="indent-16 pt-2">
          “ค่าใช้จ่ายที่บริษัทเรียกเก็บได้” ตามสัญญานี้หมายความถึง
          ค่าภาษีมูลค่าเพิ่ม ค่าภาษีรถประจำปี ค่าธรรมเนียมเปลี่ยนเครื่องยนต์
          สีรถ ชนิดเชื้อเพลิง ค่าธรรมเนียมการโอนทะเบียนรถยนต์
          ค่าธรรมเนียมแผ่นป้ายทะเบียนรถ
          ค่าธรรมเนียมใบแทนเครื่องหมายแสดงการเสียภาษีรถ
          และค่าเบี้ยประกันภัยตามกฎหมายว่าด้วยการคุ้มครองผู้ประสบภัยจากรถ
          รวมทั้งค่าใช้จ่ายหรือค่าธรรมเนียมอื่นใดที่ทางกรมการขนส่งทางบกเรียกเก็บจากการดำเนินการตามความประสงค์ของผู้เช่าซื้อ
        </div>
        <Paragraph>
          6. เงื่อนไขในการใช้ทรัพย์ที่เช่าซื้อ
          เนื่องจากบริษัทมิได้เป็นผู้จำหน่ายทรัพย์ที่เช่าซื้อ
          และผู้เช่าซื้อขอใช้บริการทางการเงินจากบริษัท ในลักษณะเช่าซื้อ ดังนี้
        </Paragraph>
        <Paragraph>
          6.1 ผู้เช่าซื้อตกลงให้ทรัพย์ที่เช่าซื้อ
          ตกเป็นกรรมสิทธิ์ของบริษัทแต่เพียงผู้เดียว
          หากสัญญานี้เป็นอันสิ้นสุดลงตามเงื่อนไขที่ระบุไว้ในสัญญานี้หรือโดยผลของกฎหมาย
          ผู้เช่าซื้อจะไม่เรียกร้องเงินดังกล่าวคืน หรือเรียกค่าเสียหายใดๆ
          จากบริษัท รวมทั้งตกลงยอมรับว่าการชำระค่าธรรมเนียม ค่าภาษีอากร
          ค่าใช้จ่าย หรือเงินจำนวนใดๆ เกี่ยวกับทรัพย์ที่เช่าซื้อให้แก่ผู้จำหน่าย
          กรมการขนส่งทางบก บุคคลหรือหน่วยงานใดๆ ก่อนที่จะเข้าทำสัญญานี้
          ไม่ได้ถือเป็นหนี้ตามสัญญานี้ที่บริษัทได้ผลักภาระให้แก่ผู้เช่าซื้อแต่อย่างใด
        </Paragraph>
        <Paragraph>
          6.2 ผู้เช่าซื้อตกลงว่าเป็นการเช่าซื้อทรัพย์ที่เช่าซื้อตามสภาพ
          และจะไม่ยกเอาปัญหาเรื่องสภาพทรัพย์ที่เช่าซื้อ
          มาเป็นข้ออ้างในการไม่ปฎิบัติตามข้อตกลงและเงื่อนไขของสัญญานี้ ทั้งนี้
          การรับประกัน การให้บริการ
          การรับรองคุณภาพทรัพย์ที่เช่าซื้อจากผู้จำหน่ายหรือผู้ผลิต
          ไม่ว่ามีหรือไม่เพียงใด
          ผู้เช่าซื้อได้รับทราบเงื่อนไขและสิทธิที่จะได้รับทั้งหมดแล้ว
          โดยบริษัทตกลงจัดให้ผู้เช่าซื้อสามารถใช้สิทธิ์ในการเรียกร้องให้มีการปฎิบัติตามเงื่อนไขการรับประกันของทรัพย์ที่เช่าซื้อจากผู้จำหน่ายหรือผู้ผลิตได้โดยตรง
        </Paragraph>
        <Paragraph>
          6.3
          ในกรณีที่มีเหตุขัดข้องที่ทำให้ไม่สามารถทำการจดทะเบียนทรัพย์ที่เช่าซื้อได้โดยมิใช่เป็นความผิดของบริษัท
          ผู้เช่าซื้อตกลงจะไม่เรียกร้องให้บริษัทจะต้องรับผิดชอบใดๆ
        </Paragraph>
        <Paragraph>
          6.4 ผู้เช่าซื้อจะนำทรัพย์ที่เช่าซื้อไปใช้เป็นการส่วนตัวเท่านั้น
          โดยจะไม่นำไปใช้ทำการขนส่งเพื่อการค้าหรือธุรกิจของตนเอง
          หรือเพื่อสินจ้าง และจะไม่ยินยอมให้บุคคลใดเช่าช่วงทรัพย์ที่เช่าซื้อ
          หรือใช้ทรัพย์ที่เช่าซื้อเพื่อทำการขนส่ง
          เพื่อการค้าหรือธุรกิจของผู้เช่าซื้อหรือของบุคคลดังกล่าว
          หรือเพื่อสินจ้าง
          เว้นแต่จะได้รับการผ่อนผันจากบริษัทให้ดำเนินการดังกล่าวข้างต้นได้
          รวมทั้งผู้เช่าซื้อจะไม่ใช้หรือไม่ยินยอมให้บุคคลใดใช้ทรัพย์ที่เช่าซื้อในลักษณะที่ไม่เหมาะสม
          ผิดประเภทจากการออกแบบหรือจดทะเบียน
          หรือใช้ทรัพย์ที่เช่าซื้อในลักษณะที่ก่อความเดือดร้อนรำคาญแก่ผู้อื่น
          และไม่ยินยอมให้ผู้อื่นที่ไม่ชำนาญ
          หรือไม่มีใบอนุญาตขับขี่หรือใบอนุญาตที่จะต้องมีตามกฎหมายในขณะที่ใช้ทรัพย์ที่เช่าซื้อขับขี่ทรัพย์ที่เช่าซื้อ
          และผู้เช่าซื้อจะไม่กระทำหรือยินยอมให้บุคคลใด ลบ ขูดขีด
          ทำลายซึ่งหมายเลขเครื่องยนต์ หมายเลขตัวถังหรือแชสซีส์ เครื่องหมาย
          หรือหมายเลขกำกับทรัพย์ที่เช่าซื้อ
          หากผู้เช่าซื้อกระทำการดังกล่าวให้ถือว่าผู้เช่าซื้อกระทำผิดสัญญา
          และบริษัทมีสิทธิบอกเลิกสัญญานี้ได้ทันที
        </Paragraph>
        <Paragraph>
          6.5 ผู้เช่าซื้อสัญญาว่า
          นับตั้งแต่วันทำสัญญานี้และตลอดไปจนกว่ากรรมสิทธิ์ในทรัพย์ที่เช่าซื้อ
          จะตกเป็นของผู้เช่าซื้อตามสัญญานี้
          ผู้เช่าซื้อจะทำประกันภัยตามกฎหมายว่าด้วยการคุ้มครองผู้ประสบภัยจากรถ
          และประกันทรัพย์ที่เช่าซื้อประเภทที่หนึ่งครอบคลุมภัยทุกชนิด
          (เว้นแต่บริษัทจะพิจารณาผ่อนผันเป็นประการอื่น) กับผู้รับประกันภัย
          ซึ่งบริษัทและผู้เช่าซื้อเห็นชอบร่วมกัน
          โดยผู้เช่าซื้อตกลงรับผิดชอบค่าเบื้ยประกันภัย
          ให้แก่ผู้รับประกันภัยตลอดระยะเวลาดังกล่าวข้างต้น
          โดยให้มีวงเงินประกันภัยเป็นจำนวนไม่น้อยกว่าจำนวนเงินเช่าซื้อทั้งหมดรวมภาษีมูลค่าเพิ่มที่ผู้เช่าซื้อต้องชำระให้แก่บริษัท
          และไม่มีข้อจำกัดสิทธิใดๆ
          รวมทั้งจะต้องระบุให้บริษัทเป็นผู้รับผลประโยชน์และเป็นผู้เก็บรักษากรมธรรม์ประกันภัย
          ทั้งนี้
          ผู้เช่าซื้อในฐานะผู้เอาประกันภัยต้องปฎิบัติตามเงื่อนไขที่ระบุไว้ในกรมธรรม์ประกันภัยโดยเคร่งครัด
        </Paragraph>
        <Paragraph>
          6.6 ในกรณีทรัพย์ที่เช่าซื้อเสียหายไม่ว่าจะเกิดขึ้นจากเหตุใด
          หรืออยู่ในความครอบครองของบุคคลใด
          และไม่ว่าจะมีบุคคลใดรับผิดชอบซ่อมแซมหรือชดใช้ค่าเสียหายให้เพียงใด
          ผู้เช่าซื้อยังคงต้องรับผิดชอบต่อบริษัทตามจำนวนหนี้คงค้างชำระตามสัญญานี้
          นอกจากนี้ในกรณีทรัพย์ที่เช่าซื้อสูญหายหรือถูกทำลายโดยสิ้นเชิง
          โดยเกิดจากความผิดของผู้เช่าซื้อแล้ว
          ผู้เช่าซื้อจะต้องรับผิดต่อบริษัทตามจำนวนหนี้คงค้างชำระตามสัญญานี้
          แต่หากบริษัทได้รับประโยชน์ตามกรมธรรม์ประกันภัยเพื่อชำระหนี้เท่ากับจำนวนหนี้คงค้างชำระตามสัญญานี้ของทรัพย์ที่เช่าซื้อซึ่งได้หักส่วนลดตามอัตราและวิธีคิดคำนวณส่วนลดตามสัญญานี้เรียบร้อยแล้ว
          หากมีเงินเหลืออยู่เท่าใดให้ตกแก่ผู้เช่าซื้อ
        </Paragraph>
        <Paragraph>
          6.7 หากผู้เช่าซื้อไม่ทำประกันภัย หรือไม่ต่ออายุกรมธรรม์ประกันภัย
          หรือไม่ชำระค่าเบี้ยประกันภัยให้ถูกต้องตามเงื่อนไขที่กำหนดไว้ข้างต้น
          ไม่ว่าจะเป็นครั้งคราวใด หรือทำการยกเลิกเพิกถอนกรมธรรม์ประกันภัย
          จนเป็นเหตุให้กรมธรรม์ประกันภัยขาดความคุ้มครอง
          ให้ถือว่าผู้เช่าซื้อผิดสัญญา และบริษัทมีสิทธิบอกเลิกสัญญานี้ได้ทันที
        </Paragraph>
        <Paragraph>
          6.8
          ผู้เช่าซื้อจะต้องรับผิดชอบต่อบริษัทหรือบุคคลใดในความสุญหายหรือเสียหายอันเกิดกับทรัพย์ที่เช่าซื้อหรือบุคคลอื่นใดหรือกับทรัพย์สินของบุคคลอื่นใดอันเนื่องมาจากทรัพย์ที่เช่าซื้อหรือเพราะการใช้ทรัพย์ที่เช่าซื้อในทุกกรณี
          แม้ในเหตุอันเกิดจากความชำรุดบกพร่องของทรัพย์ที่เช่าซื้อ
          หรือในเหตุสุดวิสัย
          และเมื่อเกิดเหตุดังกล่าวผู้เช่าซื้อจะต้องแจ้งให้บริษัททราบทันทีและจะต้องรับผิดชอบให้แก่บุคคลใดๆ
          นั้น
          และซ่อมแซมทรัพย์ที่เช่าซื้อให้เรียบร้อยดีดังเดิมด้วยค่าใช้จ่ายของผู้เช่าซื้อเอง
          ทั้งนี้ หากบริษัทได้ทดรองจ่ายค่าเสียหาย
          หรือค่าใช้จ่ายดังกล่าวแทนผู้เช่าซื้อ
          ผู้เช่าซื้อตกลงชดใช้คืนให้แก่บริษัททันที
          ไม่ว่าความเสียหายนั้นเกิดจากความผิดของผู้เช่าซื้อหรือบุคคลที่เช่าซื้ออนุญาตให้ใช้หรือรู้เห็นเป็นใจให้ใช้ทรัพย์ที่เช่าซื้อด้วยหรือไม่ก็ตาม
        </Paragraph>
      </Paper>
      <Paper
        id={id}
        hidden={hidden}
        positionPage="right"
        pageCount={5}
        page={3}
      >
        <Paragraph>
          6.9 ในกรณีทรัพย์ที่เช่าซื้อสูญหาย
          หรือถูกทำลายจนไม่สามารถซ่อมแซมให้ดีดังเดิมได้
          หรือในกรณีทรัพย์ที่เช่าซื้อถูกยึด หรือถูกอายัด หรือถูกริบ
          ให้ถือว่าสัญญานี้สิ้นสุดลงทันที
          รวมทั้งผู้เช่าซื้อต้องรับผิดชอบเบี้ยปรับตามสัญญาเช่าซื้อ
          ค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ ในการทวงถามหนี้ค่าเช่าซื้อ
          และค่าภาษีมูลค่าเพิ่มที่คงเหลือตามสัญญานี้
          เพียงเท่าที่บริษัทได้ใช้จ่ายไปจริง โดยประหยัด
          ตามความจำเป็นและมีเหตุผลอันสมควร นอกจากนี้
          หากการที่ทรัพย์ที่เช่าซื้อสูญหาย ถูกทำลาย ถูกยึด ถูกอายัด
          หรือถูกริบเกิดจากความผิดของผู้เช่าซื้อด้วยแล้ว
          ผู้เช่าซื้อจะต้องรับผิดต่อบริษัทตามจำนวนหนี้คงค้างชำระตามสัญญานี้
          รวมทั้งจะต้องรับผิดชอบชดใช้ค่าเสียหายและค่าใช้จ่ายอื่นใดทั้งหมดที่เกิดขึ้นทั้งหมดให้แก่บริษัทอีกด้วย
        </Paragraph>
        <Paragraph>
          6.10 หากทรัพย์ที่เช่าซื้อถูกใช้เป็นยานพาหนะ ในการกระทำความผิด
          หรือใช้ทรัพย์ที่เช่าซื้อ ในลักษณะที่ผิดกฎหมาย ระเบียบ
          คำสั่งข้อบังคับของทางราชการ หรือใช้ทรัพย์ที่เช่าซื้อโดยประการอื่น
          เป็นเหตุให้ทรัพย์ที่เช่าซื้อถูกยึด อายัด ริบ หรือตกเป็นของรัฐ
          หรือทรัพย์ที่เช่าซื้อถูกนำออก หรือพยายามนำออกนอกราชอาณาจักรไทย
          ให้ถือว่าสัญญานี้สิ้นสุดลงทันที
          นอกจากนี้ในกรณีที่ผู้เช่าซื้อนำทรัพย์ที่เช่าซื้อไปขาย โอน ให้ยืม จำนำ
          จำนอง ให้เช่าช่วง
          หรือก่อภาระผูกพันหรือตกไปอยู่ในความครอบครองของบุคคลภายนอกหรือบุคคลอื่นมีสิทธิเหนือทรัพย์ที่เช่าซื้อให้บริษัทมีสิทธิบอกเลิกสัญญานี้ได้ทันที
        </Paragraph>
        <Paragraph>
          6.11
          ผู้เช่าซื้อจะต้องใช้ทรัพย์ที่เช่าซื้อให้อยู่ในสภาพที่เรียบร้อยใช้การได้ดีเสมือนหนึ่งเจ้าของทรัพย์สินดูแลทรัพย์สินของตน
          ด้วยค่าใช้จ่ายของผู้เช่าซื้อเอง
        </Paragraph>
        <Paragraph>
          6.12
          ผู้เช่าซื้อยินยอมให้บริษัทหรือตัวแทนของบริษัทตรวจสอบทรัพย์ที่เช่าซื้อได้โดยการตรวจดังกล่าวจะไม่เป็นอุปสรรคต่อการดำเนินงานปกติประจำวันของผู้เช่าซื้อ
          ทั้งนี้
          บริษัทจะต้องแจ้งให้ผู้เช่าซื้อทราบล่วงหน้าก่อนที่จะไปตรวจทุกครั้ง
          เว้นแต่เมื่อบริษัทพิจารณาแล้วเห็นว่ามีความจำเป็นเร่งด่วนซึ่งไม่สามารถดำเนินการดังกล่าวได้
          ผู้เช่าซื้อตกลงยินยอมให้บริษัทเข้าไปตรวจดูทรัพย์ที่เช่าซื้อได้ทันทีโดยไม่ต้องแจ้งให้ผู้เช่าซื้อทราบล่วงหน้าแต่อย่างใด
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 7.</span> เหตุแห่งการบอกเลิกสัญญา
          นอกจากเหตุแห่งการบอกเลิกสัญญาข้ออื่นๆ ที่กำหนดไว้ในสัญญานี้แล้ว
          คู่สัญญาทั้งสองฝ่าย ตกลงให้บริษัทมีสิทธิบอกเลิกสัญญานี้ได้ทันที
          เมื่อมีกรณีใดกรณีหนึ่งดังต่อไปนี้เกิดขึ้น
        </Paragraph>
        <Paragraph>
          7.1 เมื่อผู้เช่าซื้อไม่ปฎิบัติหรือปฎิบัติผิดข้อตกลง ข้อรับรอง
          หรือเงื่อนไขที่ระบุไว้ในสัญญานี้
          หรือเอกสารอื่นใดที่เกี่ยวเนื่องกับสัญญานี้
        </Paragraph>
        <Paragraph>
          7.2 เมื่อผู้เช่าซื้อผิดนัดไม่ชำระค่าเช่าซื้อของทรัพย์ที่เช่าซื้อ 3
          งวดติดๆกัน
          และบริษัทมีหนังสือบอกกล่าวผู้เช่าซื้อให้ชำระค่าเช่าซื้อที่ค้างชำระ
          พร้อมภาษีมูลค่าเพิ่ม และเบี้ยปรับที่เกี่ยวข้องภายในเวลาอย่างน้อย 30
          วัน
          นับแต่วันที่ผู้เช่าซื้อได้รับหนังสือดังกล่าวและผู้เช่าซื้อละเลยไม่ปฎิบัติตามหนังสือบอกกล่าวนั้น
        </Paragraph>
        <Paragraph>
          7.3 เมื่อผู้เช่าซื้อผิดนัดไม่ชำระค่าใช้จ่ายที่บริษัทเรียกเก็บได้
          หรือค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ ในการทวงถามหนี้ค่าเช่าซื้อ
          หรือเงินอื่นใดที่ผู้เช่าซื้อมีหน้าที่ที่ต้องชำระตามเงื่อนไขสัญญานี้
        </Paragraph>
        <Paragraph>
          7.4 เมื่อผู้เช่าซื้อมีหนี้สินล้นพ้นตัว หรือถูกศาลสั่งพิทักษ์ทรัพย์
          หรือถูกเจ้าพนักงานยึดหรืออายัดทรัพย์ หรือมีการร้องขอให้ฟื้นฟูกิจการ
          หรือมีเหตุให้ถูกฟ้องล้มละลาย หรือถูกร้องขอให้ล้มละลาย
          หรือถูกร้องขอให้ฟื้นฟูกิจการ หรือยื่นคำร้องให้ตนเองล้มละลาย
          หรือกระทำการประนอมหนี้
        </Paragraph>
        <Paragraph>
          7.5 เมื่อผู้ค้ำประกันถึงแก่กรรม หรือถูกศาลสั่งพิทักษ์ทรัพย์เด็ดขาด
          หรือเป็นบุคคลล้มละลาย
          หรือถูกศาลสั่งให้เป็นบุคคลไร้ความสามารถหรือเสมือนไร้ความสามารถ
          และผู้เช่าซื้อไม่จัดหาบุคคลที่บริษัทเห็นชอบมาค้ำประกันแทนภายใน 30
          วันนับแต่วันที่มีเหตุดังกล่าว
        </Paragraph>
        <Paragraph>
          7.6 เมื่อมีการดัดแปลง ต่อเติม หรือกระทำการใดๆ
          เป็นเหตุให้ทรัพย์ที่เช่าซื้อเสียหาย เสื่อมสภาพ
          หรือเสื่อมราคาลงเกินกว่าการใช้สอยตามปกติ
        </Paragraph>
        <Paragraph>
          7.7 เมื่อปรากฎว่าหลักฐาน หนังสือ ข้อรับรอง คำยืนยัน หรือเอกสารใดๆ
          รวมถึงข้อมูลในการขอเช่าซื้อที่ผู้เช่าซื้อมอบให้แก่บริษัทมีข้อความเท็จ
          หรือเป็นเอกสารปลอม หรือไม่มีผลบังคับตามกฎหมาย
          ไม่ว่าทั้งฉบับหรือส่วนใดส่วนหนึ่ง
          หรือผู้เช่าซื้อทำการเช่าซื้อโดยเจตนาทุจริต
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold"> ข้อ 8.</span> ผลของการบอกเลิกสัญญา
          เมื่อบริษัทใช้สิทธิบอกเลิกสัญญาโดยแจ้งเป็นหนังสือไปยังผู้เช่าซื้อแล้ว
          ให้สัญญานี้สิ้นสุดลงทันทีหรือเมื่อสัญญานี้สิ้นสุดลงไม่ว่าเพราะเหตุใดก็ตาม
          คู่สัญญาทั้งสองฝ่ายตกลงดังนี้
        </Paragraph>
        <Paragraph>
          8.1
          ผู้เช่าซื้อจะต้องส่งมอบทรัพย์ที่เช่าซื้อคืนให้แก่บริษัทในสภาพเรียบร้อยและใช้การได้ดีทันที
          หากผู้เช่าซื้อไม่ส่งมอบทรัพย์ที่เช่าซื้อคืนให้บริษัทหรือผู้มีอำนาจกระทำการแทน
          บริษัทมีสิทธิติดตาม ยึด กลับเข้าครอบครองทรัพย์ที่เช่าซื้อได้ทันที
          โดยผู้เช่าซื้อยินยอมให้บริษัทหรือตัวแทนของบริษัทมีสิทธิเข้าไปในสถานที่ใดๆ
          ซึ่งผู้เช่าซื้ออาศัยครอบครองหรือเป็นเจ้าของหรือสถานที่ใดๆ
          ซึ่งทรัพย์ที่เช่าซื้ออาจอยู่ในที่นั้นได้
          และผู้เช่าซื้อยินยอมชำระค่าใช้ประโยชน์ในทรัพย์ที่เช่าซื้อแก่บริษัทในอัตราวันละ{" "}
          {others?.RETURN_CAR} บาท
          เป็นต้นไปจนกว่าผู้เช่าซื้อจะส่งมอบทรัพย์ที่เช่าซื้อให้แก่บริษัทในสภาพเรียบร้อยใช้การได้ดีตามปกติทั่วไปของทรัพย์ที่เช่าซื้อ
          หรือจนกว่าบริษัทจะได้กลับเข้าครอบครองทรัพย์ที่เช่าซื้อโดยสมบูรณ์
        </Paragraph>
      </Paper>
      <Paper
        id={id}
        hidden={hidden}
        positionPage="right"
        pageCount={5}
        page={4}
      >
        <Paragraph>
          8.2
          ในกรณีที่บริษัทบอกเลิกสัญญาและผู้เช่าซื้อได้ส่งมอบทรัพย์ที่เช่าซื้อคืนให้แก่บริษัทเรียบร้อยแล้ว
          หากมีเบี้ยปรับ ค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
          ในการทวงถามหนี้ค่าเช่าซื้อหรือค่าภาษีมูลค่าเพิ่มที่คงเหลือตามสัญญานี้
          ผู้เช่าซื้อตกลงจะเป็นฝ่ายรับผิดชอบชดใช้ให้แก่บริษัทเพียงเท่าที่บริษัทได้ใช้จ่ายไปจริง
          โดยประหยัด ตามความจำเป็น และมีเหตุอันสมควร
        </Paragraph>
        <Paragraph>
          8.3
          ในกรณีที่สัญญานี้สิ้นสุดลงตามเงื่อนไขที่ระบุไว้ในสัญญานี้หรือโดยผลของกฎหมายและบริษัทได้กลับเข้าครอบครองทรัพย์ที่เช่าซื้อเรียบร้อยแล้ว
          ก่อนที่บริษัทจะนำทรัพย์ที่เช่าซื้อออกขายโดยวิธีประมูลหรือวิธีขายทอดตลาด
          บริษัทจะมีหนังสือแจ้งให้ผู้เช่าซื้อทราบเพื่อให้ผู้เช่าซื้อใช้สิทธิซื้อทรัพย์ที่เช่าซื้อในราคาเท่ากับจำนวนหนี้คงค้างชำระตามสัญญานี้หรือทรัพย์ที่เช่าซื้อซึ่งได้หักส่วนลดตามอัตราและวิธีการคำนวณส่วนลดตามสัญญานี้เรียบร้อยแล้ว
          โดยให้เวลาไม่น้อยกว่า 7
          วันนับแต่วันที่ผู้เช่าซื้อได้รับหนังสือแจ้งจากทางบริษัท
          และในกรณีที่ผู้เช่าซื้อไม่ได้ใช้สิทธิดังกล่าว
          บริษัทจะแจ้งไปยังผู้ค้ำประกันเพื่อให้สิทธิผู้ค้ำประกันซื้อทรัพย์ที่เช่าซื้อในราคาตามที่กำหนด
          โดยให้เวลาไม่น้อยกว่า 15
          วันนับแต่วันที่สิ้นสุดระยะเวลาในการใช้สิทธิของผู้เช่าซื้อ
        </Paragraph>
        <Paragraph>
          8.4 ในกรณีที่บริษัทนำทรัพย์ที่เช่าซื้อออกขายให้แก่บุคคลอื่น
          และได้ราคาเกินกว่าจำนวนหนี้คงค้างชำระตามสัญญานี้
          บริษัทจะคืนเงินส่วนเกินนั้นให้แก่ผู้เช่าซื้อ
          แต่หากได้ราคาน้อยกว่าจำนวนหนี้คงค้างชำระตามสัญญานี้
          ผู้เช่าซื้อตกลงรับผิดในส่วนที่ขาดนั้นทั้งสิ้น
        </Paragraph>
        <Paragraph>
          8.5
          การสิ้นสุดของสัญญาดังกล่าวไม่มีผลยกเลิกหรือลบล้างสิทธิเรียกร้องของบริษัทและความรับผิดของ
          ผู้เช่าซื้อในบรรดาค่าเสียหาย ค่าใช้ทรัพย์
          ค่าขาดประโยชน์ที่บริษัทควรได้รับ
          ภาษีมูลค่าเพิ่มตามจำนวนที่บริษัทได้ชำระไป เบี้ยปรับ ค่าใช้จ่าย
          เงินจำนวนใดๆ ตามกำหนดในสัญญานี้ หรือที่ผู้เช่าซื้อต้องชำระตามกฎหมาย
          เพียงเท่าที่บริษัทได้ใช้ จ่ายไปจริง โดยประหยัด
          ตามความจำเป็นและมีเหตุผลอันสมควร
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 9. </span>
          บริษัทจะนำเงินค่างวดของผู้เช่าซื้อที่ได้ชำระแล้วในงวดต่อมาเพื่อหักชำระเป็นค่าใช้จ่ายที่บริษัทเรียกเก็บได้
          ซึ่งผู้เช่าต้องชำระในแต่ละงวด หรือค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
          ในการทวงถามหนี้ค่าเช่าซื้อ หรือค่าเบี้ยปรับชำระค่างวดล่าช้า
          หากผู้เช่าซื้อไม่ได้ชำระเงินในส่วนที่ขาดให้แก่บริษัทให้ครบถ้วนถูกต้องภายในระยะเวลาดังกล่าว
          ให้ถือว่า
          ผู้เช่าซื้อผิดนัดเฉพาะเงินค่างวดเช่าซื้อบางส่วนตามจำนวนที่ยังไม่ได้ชำระให้ครบถ้วนถูกต้อง
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 10. </span>
          ผู้เช่าซื้อตกลงให้สัญญาว่าผู้เช่าซื้อจะไม่โอนสิทธิและหน้าที่ตามสัญญานี้ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บุคคลหรือนิติบุคคลอื่นใด
          เว้นแต่จะได้รับความยินยอมเป็นหนังสือจากบริษัทก่อน
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold">ข้อ 11. </span>
          ข้อตกลงตามสัญญานี้เป็นไปโดยมีวัตถุประสงค์เพื่อให้สอดคล้องกับหลักเกณฑ์ของกฎหมาย
          คำสั่ง กฎกระทรวง ระเบียบของทางราชการ หรือ หน่วยงานที่มีอำนาจ
          หรือธนาคารแห่งประเทศไทยที่ใช้บังคับอยู่ในขณะนี้ ดังนั้น
          หากต่อไปในภายหน้ามีการประกาศใช้หรือแก้ไขเปลี่ยนแปลงหลักเกณฑ์ใดซึ่งบริษัทพิจารณาแล้วเห็นว่าข้อตกลงตามสัญญานี้ไม่เป็นไปตามหลักเกณฑ์ที่ใช้บังคับอยู่ในขณะนั้น
          ผู้เช่าซื้อตกลงยินยอมให้บริษัทมีสิทธิเปลี่ยนแปลงข้อตกลงตามสัญญานี้ได้ใหม่
          เพื่อให้ข้อตกลงเป็นไปโดยถูกต้อง เหมาะสม และมีผลใช้บังคับได้
          และให้การแก้ไขดังกล่าวมีผลบังคับใช้ได้
          ซึ่งบริษัทจะแจ้งให้ผู้เช่าซื้อทราบล่วงหน้าเท่านั้น
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold"> ข้อ 12. </span>
          หากข้อสัญญาใดเป็นโมฆะหรือไม่สมบูรณ์
          ให้ถือว่าข้อสัญญาส่วนอื่นแยกออกจากส่วนที่เป็นโมฆะหรือไม่สมบูรณ์นั้น
          และยังคงมีผลใช้บังคับได้ต่อไป
        </Paragraph>
        <Paragraph indent={false}>
          <span className="font-bold"> ข้อ 13. </span>
          กรณีสัญญาเช่าซื้อกำหนดให้ผู้เช่าซื้อต้องจัดหาผู้ค้ำประกันการเช่าซื้อ
          คู่สัญญาทั้งสองฝ่ายตกลงว่าจะจัดทำสัญญาค้ำประกันซึ่งมีคำเตือนสำหรับผู้ค้ำประกันไว้หน้าสัญญาค้ำประกันนั้น
          ตามหลักเกณฑ์และรายละเอียดที่กำหนดไว้ในประกาศคณะกรรมการว่าด้วยสัญญา
          เรื่องธุรกิจให้เช่าซื้อรถยนต์เป็นธุรกิจที่ควบคุมสัญญาที่มีอยู่ในขณะนี้
          หรือที่จะมีการแก้ไขเปลี่ยนแปลงในภายหน้า
        </Paragraph>
      </Paper>
      <Paper
        id={id}
        hidden={hidden}
        positionPage="right"
        pageCount={5}
        page={5}
      >
        <Paragraph className="font-bold">
          สัญญาต่อท้ายสัญญาเช่าซื้อฉบับนี้ทำขึ้นเป็นสองฉบับ
          มีข้อความถูกต้องตรงกัน คู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อตกลง
          เงื่อนไขของสัญญาดังกล่าวโดยตลอดแล้วเห็นว่าตรงกับความประสงค์
          จึงได้ลงลายมือชื่อไว้ต่อหน้าพยาน ณ วัน เดือน ปี ที่กล่าวข้างต้น
          และยึดถือไว้ฝ่ายละฉบับ
        </Paragraph>
        <Signature
          fixedNameLeft={`${data?.article} ${data?.fullname}`}
          left={`ผู้เช่าซื้อ`}
          right={`พยาน`}
        />
        <Signature left={`คู่สมรส/ผู้ปกครอง`} right={`พยาน`} />
        <div className="text-center  pt-12">
          บริษัท มันนี่ ฮับ เซอร์วิส จำกัด “บริษัท”
        </div>
        <div className="text-center  pt-12">
          โดย ................................................................
          ผู้มีอำนาจกระทำการแทน
        </div>
        <div className="text-center  pt-2"> ( นายชยุตม์ ปิยะวงศ์ไพบูลย์ )</div>
      </Paper>
    </React.Fragment>
  );
};
export default PdfHireEndPurchaseContract;
