import React, { useEffect, useState } from "react";
import { ButtonBlue } from "../../../components/button/Button";
import { ICON_EYE_CLOSE, ICON_EYE_OPEN } from "../../../utils/useIcons";
import {
  STYLE_BG_INPUT,
  STYLE_FORGET_PASSWORD,
  STYLE_INPUT,
  STYLE_INPUT_PASSWORD,
  SYLE_ICON_EYES_LOGIN,
} from "../../../utils/useStyles";
import Amplify, { Auth, Storage } from "aws-amplify";
import useAsync from "../../../hooks/useAsync";
import { useDispatch } from "react-redux";
import { initializeApp } from "../../../redux/initializeAppSlice";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { EmitterStatus } from "../../../event/EmitterStatus";

const UserName = ({ setForm, form, onChangeForm }) => {
  return (
    <div className="flex flex-col">
      <div className={STYLE_BG_INPUT}>
        <input
          onChange={onChangeForm}
          value={form?.username?.value}
          className={STYLE_INPUT}
          placeholder="Username"
          name="username"
        />
      </div>
      {form?.username?.error && (
        <div className="text-red-400 ml-3 text-xs mt-1">
          Please input your username
        </div>
      )}
    </div>
  );
};

const Password = ({ setForm, form, onChangeForm }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col">
      <div className={STYLE_BG_INPUT}>
        <div className="flex justify-between  ">
          <input
            className={STYLE_INPUT_PASSWORD}
            placeholder="Password"
            type={!showPassword ? "password" : "text"}
            name="password"
            onChange={onChangeForm}
            value={form?.password?.value}
          />
          {showPassword ? (
            <ICON_EYE_OPEN
              className={SYLE_ICON_EYES_LOGIN}
              onClick={togglePassword}
            />
          ) : (
            <ICON_EYE_CLOSE
              className={SYLE_ICON_EYES_LOGIN}
              onClick={togglePassword}
            />
          )}
        </div>
      </div>
      {form?.password?.error && (
        <div className="text-mhs-red ml-3 text-xs mt-1">
          Please input your password
        </div>
      )}
    </div>
  );
};

const ForgetPassword = () => {
  return <div className={STYLE_FORGET_PASSWORD}>Forget Password</div>;
};

const LoginUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initUser = {
    username: { value: "", error: false },
    password: { value: "", error: false },
  };
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm(initUser);
  }, []);

  const {
    onAsync: onClickLogin,
    loading,
    error,
  } = useAsync(
    async (e) => {
      e.preventDefault();

      let checkError = false;
      let formError = { ...form };
      if (form.username.value === "") {
        formError = {
          ...formError,
          username: { ...formError.username, error: true },
        };
        checkError = true;
      }

      if (form.password.value === "") {
        formError = {
          ...formError,
          password: { ...formError.password, error: true },
        };
        checkError = true;
      }

      if (checkError) {
        setForm(formError);
        return;
      }

      const userSignIn = await Auth.signIn({
        username: form.username.value,
        password: form.password.value,
      });
      if (userSignIn?.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(userSignIn, form.password);
      }
      dispatch(initializeApp());
      navigate("/");
    },
    (error) => {
      setForm({ ...form, password: { ...form.password, value: "" } });
      EmitterStatus("ERROR", { text: error });
    },
    () => {}
  );

  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: { value, error: false },
      };
    });
  };

  return (
    <div className="my-10 space-y-5 mx-auto">
      <UserName onChangeForm={onChangeForm} setForm={setForm} form={form} />
      <Password onChangeForm={onChangeForm} setForm={setForm} form={form} />
      <ForgetPassword />
      <div className="pt-14">
        <ButtonBlue loading={loading} onClick={onClickLogin} text="Login" />
      </div>
    </div>
  );
};
export default LoginUser;
