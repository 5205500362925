//JOB
export const mock_data_job = [
  {
    first_name: "พิชาน ",
    last_name: "แซ่คู",

    status: " | ยังไม่ได้นัดหมาย",
    code: "รหัส : 2000000001",
    title_address: "ที่อยู่ปัจจุบัน",
    icon_1: true,
    address_1: "4/398 ซอยเสรีไทย 57 แขวงคลองทุ่ม เขตบึงกุ่ม ...",
    title_address_2: "ที่อยู่ที่ลูกค้าขอยื่น",
    icon_2: true,
    address_2: "อาคารวิชั่น บิสิเนส พาร์ค ชั้นที่ 4 เลขที่ 3 ซอยรามอินทรา ...",
    type: "ประเภทรถ",
    type_car_1: "Toyota Vios 1.5E SEDAN",
    type_car_2: "FWD 75P CVP 2018 Auto",
  },
  {
    first_name: "ปวิชญา  ",
    last_name: "ศักดิ์ดี ",
    status: "| ยังไม่ได้นัดหมาย",
    code: "รหัส : 2000000002",
    title_address: "ที่อยู่ปัจจุบัน",
    icon_1: true,
    address_1: "54/13 ซอยสาคร อ.เมือง ต.ในเมือง ...",
    title_address_2: "ที่อยู่ที่ลูกค้าขอยื่น",
    icon_2: true,
    address_2: "อาคารเสนานิคม พหลโยธิน32 เขตจตุจักร ...",
    type: "ประเภทรถ",
    type_car_1: "MINI Inspired by Goodwood",
  },
  {
    first_name: "เบญจมาศ ",
    last_name: "แก้วตุ่น",
    approve: "true",
    code: "รหัส : 2000000003",
    title_address: "ที่อยู่ปัจจุบัน",
    icon_1: true,
    address_1: "54/13 ซอยสาคร อ.เมือง ต.ในเมือง ...",
    title_address_2: "ที่อยู่ที่ลูกค้าขอยื่น",
    icon_2: true,
    address_2: "อาคารเสนานิคม พหลโยธิน32 เขตจตุจักร ...",
    type: "ประเภทรถ",
    type_car_1: "R53 Facelift (Minor Change)",
  },
  {
    first_name: "พัชชา  ",
    last_name: "พุทธศร",
    approve: "true",

    code: "รหัส : 2000000004",
    title_address: "ที่อยู่ปัจจุบัน",
    icon_1: true,
    address_1: "12 ซอยรังสิต-ปทุมธานี 12 ซอย 9 ต.ประชาธิปัตย์ ...",
    title_address_2: "ที่อยู่ที่ลูกค้าขอยื่น",
    icon_2: true,
    address_2: "อาคารเสนานิคม พหลโยธิน32 เขตจตุจักร ...",
    type: "ประเภทรถ",
    type_car_1: "Tata Super Ace Mint",
  },
  {
    first_name: "ภัทราพร  ",
    last_name: "พงศ์มหาวณิช",
    approve: "true",

    code: "รหัส : 2000000005",
    title_address: "ที่อยู่ปัจจุบัน",
    icon_1: true,
    address_1: "56/66 พระยาสุเรนทร์ เขตคลองสามวา แขวงบางชัน ...",
    title_address_2: "ที่อยู่ที่ลูกค้าขอยื่น",
    icon_2: true,
    address_2: "อาคารวิชั่น บิสิเนส พาร์ค ชั้นที่ 4 เลขที่ 3 ซอยรามอินทรา ...",
    type: "ประเภทรถ",
    type_car_1: "Wuling Mini EV",
  },
];
export const mock_data_job_info = [
  {
    name: "พิชาน แซ่คู",
    tel: "0875003282",
    lineid: "taoinfrared",
    address_local: "11 ม.1 ต.บางกร่าง อ.บางใหญ่ จ.นนทบุรี 11000",
    address_1:
      "4/398 ซอยเสรี่ไทย 57 แขวงคลองทุ่ม เขตบึงกุ่ม กรุงเทพมหานคร 10240",
    address_2:
      "อาคารวิชั่น บิสิเนส พาร์ค ชั้นที่ 4 เลขที่ 3 ซอยรามอินทรา 55/8 ถนนรามอินทรา แขวงท่าแร้ง บางเขน กรุงเทพ 10220",
    type_car_1: "Toyota Vios 1.5E SEDAN ",
    type_car_2: "FWD 75P CVP 2018 Auto",
    price: "386,100 บาท",
  },
];

export const mock_data_report = [
  {
    date: " 4 กรกฎาคม 2565",
    code: "รหัส : 2000000001",
    type: "ประเภทรถ",
    type_car_1: "Toyota Vios 1.5E SEDAN",
    type_car_2: "FWD 75P CVP 2018 Auto",
  },
];

//FORM
export const mock_data_form = [
  {
    title: "แบบคำขอสินเชื่อทะเบียนรถ (จำนำและเช่าซื้อ)",
    url: "https://drive.google.com/file/d/1UXJsDUjvDc_RgaQk2x5KNAlqo44HFVY2/view?usp=sharing",
  },
  {
    title: "คำเตือนสำหรับผู้ค้ำประกัน",
    url: "https://drive.google.com/file/d/1MQ8N2o_Rba5BOcjOUXrfJoDkFp4sGSB1/view?usp=sharing",
  },
  {
    title: "สัญญาค้ำประกัน",
    url: "https://drive.google.com/file/d/1cP3sOL6Hx40esfsO-EKs7OBsaFJx38wQ/view?usp=sharing",
  },
  {
    title: "ใบแผนที่อยู่ปัจจุบันและที่ทำงาน",
    url: "https://drive.google.com/file/d/1q9dU_6bm1cipT8ZxqaZbWIrnbhyggdgL/view?usp=sharing",
  },
  {
    title: "แบบฟอร์มตรวจสภาพรถ",
    url: "https://drive.google.com/file/d/110OegYgJFu6RECxzEN-PcTqdHn5dKtVK/view?usp=sharing",
  },
  {
    title: "แบบคำขอโอนและรับโอน",
    url: "https://drive.google.com/file/d/1ARzksTuE85bFKtRr6iQ0r0aY1mcFybhw/view?usp=sharing",
  },
  {
    title: "หนังสือมอบอำนาจ",
    url: "https://drive.google.com/file/d/1QiML-6NYr_2ZTlvwmbIn5ifCNkQlTSTF/view?usp=sharing",
  },
  {
    title: "แบบฟอร์มตรวจสอบเอกสารพิจารณาสินเชื่อ",
    url: "https://drive.google.com/file/d/170Sy9HqRYtiTp-vAltMypge2I2eAnSWR/view?usp=sharing",
  },
];
